import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, CircularProgress, Grid, Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';

import AppointmentLog from '../../Commons/AppointmentLog';
import OutlinedSelect from '../../Commons/OutlinedSelect';
import OrganizationHeader from '../UserOrganizationHeader';
import CalendarComponent from '../../Commons/Calendar';

import { appointmentsource, status } from '../../../api/api';

import {
	returnClientOptions,
	returnResourcePersonOptions,
	returnServiceOptions,
	sortByStartTime,
} from '../../../constants/functions';

import { getDecodedToken } from '../../../libs/token';

import {
	addDays,
	addSeconds,
	getStartOfDay,
	getEndOfDay,
	findAppointmentsByStartTime,
	filterOperationTimesByWeekDay,
} from '../../../utils';

import { style } from './style';

const toLocaleStringOptions = {
	year: 'numeric',
	month: 'numeric',
	day: 'numeric',
	hour: 'numeric',
	minute: 'numeric',
};

const UserEditAppointment = inject(
	'appointments',
	'appointmentlogs',
	'userappointment',
	'services',
	'serviceusers',
	'clients',
	'uistore',
)(
	observer(
		class EditAppointment extends React.Component {
			dispose;
			decodedToken = {};

			state = {
				statuses: [],
				appointment_sources: [],
				appointments: [],
				loading: false,
			};

			async componentDidMount() {
				const { history } = this.props;

				this.decodedToken = getDecodedToken();

				if (this.decodedToken) {
					if (Object.keys(this.props.appointments.selected).length === 0) {
						this.props.history.push('/user/appointments');
					} else {
						const {
							clients,
							appointments,
							userappointment,
							uistore,
							services,
							serviceusers,
						} = this.props;
						const { setParam } = userappointment;
						const { selectedSite } = uistore;

						try {
							await clients.fetchClientList();
						} catch (e) {
							console.log(e);
						}

						setParam(
							'selectedClient',
							clients.getClientById(appointments.selected.client_id),
						);
						this.forceUpdate();

						try {
							const appointment_source_res = await appointmentsource.fetch();

							if (
								appointment_source_res &&
								appointment_source_res.appointmentsources
							) {
								this.setState({
									appointment_sources: appointment_source_res.appointmentsources,
								});

								if (appointment_source_res.appointmentsources.length > 0)
									userappointment.setParam(
										'selectedAppointmentSource',
										appointment_source_res.appointmentsources[0].id,
									);
							}
						} catch (e) {
							console.log(e);
						}

						let service, service_user;

						try {
							await services.fetch(selectedSite.id);
						} catch (e) {
							console.log(e);
						}

						service = services.getByID(appointments.selected.service_id);

						try {
							await serviceusers.fetch(service.id);
						} catch (e) {
							console.log(e);
						}

						setParam('selectedService', service);

						if (appointments.selected.user_id) {
							service_user = serviceusers.getByUserID(appointments.selected.user_id);
							setParam('selectedServiceUser', service_user);
						} else {
							service_user = { id: 0 };
							setParam('selectedServiceUser', service_user);
						}

						setParam('selectedStatus', appointments.selected.status_id);
						setParam('selectedDate', new Date(appointments.selected.start_time));

						this.checkAppointmentsAndPushIntervals(
							selectedSite.id,
							appointments.selected.start_time,
						);
					}
				} else {
					history.replace('/');
				}
			}

			componentWillUnmount() {
				if (this.dispose) this.dispose();

				const {
					appointments,
					appointmentlogs,
					userappointment,
					services,
					serviceusers,
					clients,
					uistore,
				} = this.props;

				appointments.masterReset();
				appointmentlogs.masterReset();
				userappointment.masterReset();
				services.masterReset();
				serviceusers.masterReset();
				clients.masterReset();
				uistore.masterReset();
			}

			assignInitialUser = (service) => {
				const { serviceusers, userappointment } = this.props;
				const { selectedSite, setParam } = userappointment;

				serviceusers.fetch(service.id).then((serviceusers_res) => {
					if (serviceusers_res.length > 0)
						setParam('selectedServiceUser', serviceusers_res[0]);

					this.checkAppointmentsAndPushIntervals(selectedSite.id);
				});
			};

			checkAppointmentsAndPushIntervals = async (site_id) => {
				this.setState({
					loading: true,
				});

				const { serviceusers } = this.props;
				const { appointments, userappointment } = this.props;
				const { setParam, selectedService, selectedServiceUser } = userappointment;
				const { Operationtime } = appointments.selected.user_id
					? selectedServiceUser
					: selectedService;
				const { selectedDate } = userappointment;

				try {
					const status_res = await status.fetch(site_id);

					if (status_res && status_res.statuses)
						this.setState({
							statuses: status_res.statuses,
						});
				} catch (e) {
					console.log(e);
				}

				try {
					await serviceusers.fetch(selectedService.id);
				} catch (e) {
					console.log(e);
				}

				let optime;

				if (
					selectedDate.toLocaleDateString() ===
					new Date(appointments.selected.start_time).toLocaleDateString()
				) {
					optime = Operationtime.filter(
						(item) =>
							item.week_day === new Date(appointments.selected.start_time).getDay(),
					);
				} else {
					optime = Operationtime.filter(
						(item) => item.week_day === new Date(selectedDate).getDay(),
					);
				}

				setParam('selectedOperationTimeObj', optime);

				try {
					const appointment_res =
						selectedServiceUser.id === 0
							? await appointments.fetchUserlessAppointmentsByServiceId(
									selectedService.id,
									getStartOfDay(new Date(selectedDate)).toISOString(),
									getEndOfDay(new Date(selectedDate)).toISOString(),
							  )
							: await appointments.fetchByUserId(
									selectedServiceUser.user_id,
									getStartOfDay(new Date(selectedDate)).toISOString(),
									getEndOfDay(new Date(selectedDate)).toISOString(),
							  );

					if (appointment_res && appointment_res.appointments) {
						this.setState({
							appointments: appointment_res.appointments,
						});

						if (
							selectedDate.toLocaleDateString() ===
							this.props.appointments.selected.start_time
						) {
							let date = new Date();

							if (optime.length === 0) {
								let iterations = 0;
								while (optime.length === 0) {
									if (iterations >= 7) break;

									date = addDays(date, 1);

									optime = filterOperationTimesByWeekDay(Operationtime, date);
									optime.sort(sortByStartTime);

									iterations++;
								}
							}

							if (optime.length !== 0) {
								setParam('selectedDate', date);
								this.parseIntervalsAndPush(
									optime,
									selectedService.service_period,
									appointment_res.appointments,
								);
							} else
								this.setState({
									loading: false,
								});
						} else
							this.parseIntervalsAndPush(
								optime,
								selectedService.service_period,
								appointment_res.appointments,
							);
					}
				} catch (e) {
					console.log(e);
				}
			};

			checkIfUnavailable = (start_time, available) => {
				const { classes, appointments } = this.props;

				const availableClassnames = classNames(classes.opTime, classes.available);
				const unavailableClassnames = classNames(classes.opTime, classes.unavailable);

				if (
					new Date(appointments.selected.start_time).toLocaleString(
						'en-US',
						toLocaleStringOptions,
					) === start_time.toLocaleString('en-US', toLocaleStringOptions)
				)
					return availableClassnames;
				else if (available) {
					return availableClassnames;
				} else {
					return unavailableClassnames;
				}
			};

			parseIntervalsAndPush = (optimeArr, duration, appointments) => {
				const { userappointment } = this.props;
				const { selectedDate, pushToIntervals, resetIntervals, setParam } = userappointment;

				resetIntervals();

				typeof optimeArr !== 'undefined' && optimeArr.length > 0
					? optimeArr.forEach(({ start_time, end_time }) => {
							let start = new Date(start_time);
							let end = new Date(end_time);

							start.setFullYear(selectedDate.getFullYear());
							start.setMonth(selectedDate.getMonth());
							start.setDate(selectedDate.getDate());

							end.setFullYear(selectedDate.getFullYear());
							end.setMonth(selectedDate.getMonth());
							end.setDate(selectedDate.getDate());

							if (
								selectedDate.toLocaleDateString() ===
								new Date(
									this.props.appointments.selected.start_time,
								).toLocaleDateString()
							) {
								if (
									this.props.appointments.selected &&
									typeof this.props.appointments.selected !== 'undefined'
								)
									setParam('selectedInterval', {
										start_time: new Date(
											this.props.appointments.selected.start_time,
										),
										end_time: addSeconds(
											new Date(this.props.appointments.selected.start_time),
											duration,
										),
										available: true,
									});
							}

							while (start < end) {
								if (
									appointments &&
									appointments.length > 0 &&
									typeof appointments !== 'undefined'
								) {
									let existingAppointment = findAppointmentsByStartTime(
										appointments,
										start,
									);

									if (typeof existingAppointment === 'undefined') {
										pushToIntervals({
											start_time: start,
											end_time: addSeconds(start, duration),
											available: true,
										});
									} else {
										pushToIntervals({
											start_time: start,
											end_time: addSeconds(start, duration),
											available:
												selectedDate.toLocaleDateString() ===
												new Date(
													this.props.appointments.selected.start_time,
												).toLocaleDateString()
													? new Date(
															this.props.appointments.selected.start_time,
													  ).toLocaleString(
															'en-US',
															toLocaleStringOptions,
													  ) ===
													  new Date(start).toLocaleString(
															'en-US',
															toLocaleStringOptions,
													  )
													: false,
										});
									}
								} else {
									pushToIntervals({
										start_time: start,
										end_time: addSeconds(start, duration),
										available: true,
									});
								}

								start = addSeconds(start, duration);
							}

							this.setState({
								loading: false,
							});
					  })
					: this.setState({
							loading: false,
					  });
			};

			handleSelectedDateChange = (date) => {
				const dateClone = new Date(date);
				const { userappointment, uistore, appointments } = this.props;
				const {
					setParam,
					selectedServiceUser,
					changeIntervalsToSelectedDate,
					selectedService,
				} = userappointment;
				const { selectedSite } = uistore;

				setParam('selectedInterval', {
					start_time: new Date(appointments.selected.start_time),
					end_time: addSeconds(
						new Date(appointments.selected.start_time),
						selectedService.service_period,
					),
					available: true,
				});
				changeIntervalsToSelectedDate(this.state.appointments);

				setParam('selectedDate', dateClone);
				const { Operationtime } = selectedServiceUser;
				const OperationTimeObj = Operationtime.filter(
					(item) => item.week_day === dateClone.getDay(),
				);

				setParam('selectedOperationTimeObj', OperationTimeObj);
				this.checkAppointmentsAndPushIntervals(selectedSite.id);
			};

			shouldDisableDate = (date) => {
				const { Operationtime } =
					this.props.userappointment.selectedServiceUser.id === 0
						? this.props.userappointment.selectedService
						: this.props.userappointment.selectedServiceUser;
				const dateClone = new Date(date);
				let operationTimeObj = Operationtime.filter(
					(item) => item.week_day === dateClone.getDay(),
				);
				return !operationTimeObj.length > 0;
			};

			handleServiceChange = (e) => {
				const { userappointment, services } = this.props;
				const { setParam } = userappointment;
				const selectedService = services.getByID(e.target.value);

				setParam('selectedService', selectedService);
				this.assignInitialUser(selectedService);
			};

			handleServiceUserChange = (e) => {
				const { serviceusers, userappointment, uistore } = this.props;
				const { selectedSite } = uistore;
				const { setParam } = userappointment;
				const selectedServiceUser = serviceusers.getByID(e.target.value);

				setParam('selectedServiceUser', selectedServiceUser);
				this.checkAppointmentsAndPushIntervals(selectedSite.id);
			};

			returnGender = (gender) => {
				switch (gender) {
					case 0:
						return 'Male';
					case 1:
						return 'Female';
					default:
						return 'Other';
				}
			};

			returnStatusOptions = () => {
				return this.state.statuses.map((item, index) => (
					<option value={item.id} key={`Status${index}`}>
						{item.description}
					</option>
				));
			};

			returnAppointmentSourceOptions = () => {
				return this.state.appointment_sources.map((item, index) => (
					<option value={item.id} key={`AppointmentSource-${index}`}>
						{item.domain}
					</option>
				));
			};

			returnOperationTimes = (intervals, selectedInterval) => {
				const { userappointment, classes } = this.props;
				const { setParam } = userappointment;
				const selectedClassnames = classNames(classes.opTime, classes.selected);

				return intervals.map((item, index) => {
					const { start_time, end_time, available } = item;
					let itemIsSelected;

					if (Object.keys(selectedInterval).length > 0)
						itemIsSelected =
							selectedInterval.start_time.toLocaleString(
								'en-US',
								toLocaleStringOptions,
							) === start_time.toLocaleString('en-US', toLocaleStringOptions) &&
							selectedInterval.end_time.toLocaleString(
								'en-US',
								toLocaleStringOptions,
							) === end_time.toLocaleString('en-US', toLocaleStringOptions);
					else itemIsSelected = false;

					return (
						<Grid
							key={`optime-${index}`}
							onClick={() => {
								if (available) setParam('selectedInterval', item);
							}}
							item
							md={3}
							xs={12}
							className={
								itemIsSelected
									? selectedClassnames
									: this.checkIfUnavailable(start_time, available)
							}>
							<Typography variant={'body2'} style={{ color: 'inherit' }}>
								{start_time.toLocaleTimeString([], {
									hour: '2-digit',
									minute: '2-digit',
								})}{' '}
								-{' '}
								{end_time.toLocaleTimeString([], {
									hour: '2-digit',
									minute: '2-digit',
								})}
							</Typography>
						</Grid>
					);
				});
			};

			formSubmit = async () => {
				const { userappointment, appointments, history, uistore } = this.props;
				const { selected } = appointments;
				const { selectedSite } = uistore;
				const {
					selectedInterval,
					selectedService,
					selectedServiceUser,
					selectedStatus,
					selectedClient,
					selectedAppointmentSource,
				} = userappointment;
				const { start_time, end_time } = selectedInterval;

				const payload = {
					start_time: start_time.toISOString(),
					end_time: end_time.toISOString(),
					service_id: selectedService.id,
					user_id: selectedServiceUser.id === 0 ? null : selectedServiceUser.user_id,
					status_id: selectedStatus,
					appointment_source_id: selectedAppointmentSource,
					client_id: selectedClient.id,
					site_id: selectedSite.id,
				};

				this.setState({
					loading: true,
				});

				try {
					const res = await appointments.update(payload, selected.id);
					this.setState({
						loading: false,
					});

					if (res.status && !res.status.error) {
						history.push('/user/appointments');
					} else {
						console.log(res);
						alert('An error occurred!');
					}
				} catch (e) {
					if (e.message) {
						alert(e.message);
					} else {
						alert('Something went wrong. Please try again!');
					}

					console.log(e);

					this.setState({
						loading: false,
					});
				}
			};

			render() {
				const {
					classes,
					history,
					appointments,
					services,
					serviceusers,
					userappointment,
					clients,
				} = this.props;
				const {
					selectedAppointmentSource,
					selectedClient,
					selectedService,
					selectedServiceUser,
					selectedDate,
					selectedStatus,
					selectedInterval,
					intervals,
				} = userappointment;
				const { selected } = appointments;
				const { loading } = this.state;

				if (Object.keys(selected).length === 0) {
					return <div>Redirecting...</div>;
				} else if (loading)
					return (
						<Grid className={classes.mainContainer} container>
							<Grid item xs={12} className={classes.loadingContainer}>
								<Typography variant={'h4'}>Processing . . .</Typography>
								<CircularProgress />
							</Grid>
						</Grid>
					);
				else
					return (
						<Grid container alignItems={'center'} justify={'center'}>
							<Grid item xs={12}>
								<OrganizationHeader notEditable />
								<Grid container className={classes.row}>
									<Grid item md={6} xs={12}>
										<Grid
											container
											alignItems={'center'}
											className={classes.dataRow}>
											<Grid item xs={6} className={classes.detailContainer}>
												Email Address:
											</Grid>
											<Grid item xs={6} className={classes.detailContainer}>
												{selectedClient.email}
											</Grid>
										</Grid>
									</Grid>
									<Grid item md={6} xs={12}>
										<Grid
											container
											alignItems={'center'}
											className={classes.dataRow}>
											<Grid item xs={6} className={classes.detailContainer}>
												Mobile No:
											</Grid>
											<Grid item xs={6} className={classes.detailContainer}>
												{selectedClient.mobile}
											</Grid>
										</Grid>
									</Grid>
									<Grid item md={6} xs={12}>
										<Grid
											container
											alignItems={'center'}
											className={classes.dataRow}>
											<Grid item xs={6} className={classes.detailContainer}>
												First Name:
											</Grid>
											<Grid item xs={6} className={classes.detailContainer}>
												{selectedClient.first_name}
											</Grid>
										</Grid>
									</Grid>
									{selectedClient.second_name &&
										typeof selectedClient.second_name !== 'undefined' && (
											<Grid item md={6} xs={12}>
												<Grid
													container
													alignItems={'center'}
													className={classes.dataRow}>
													<Grid
														item
														xs={6}
														className={classes.detailContainer}>
														Second Name:
													</Grid>
													<Grid
														item
														xs={6}
														className={classes.detailContainer}>
														{selectedClient.second_name}
													</Grid>
												</Grid>
											</Grid>
										)}
									<Grid item md={6} xs={12}>
										<Grid
											container
											alignItems={'center'}
											className={classes.dataRow}>
											<Grid item xs={6} className={classes.detailContainer}>
												Last Name:
											</Grid>
											<Grid item xs={6} className={classes.detailContainer}>
												{selectedClient.last_name}
											</Grid>
										</Grid>
									</Grid>
									<Grid item md={6} xs={12}>
										<Grid
											container
											alignItems={'center'}
											className={classes.dataRow}>
											<Grid item xs={6} className={classes.detailContainer}>
												Gender:
											</Grid>
											<Grid item xs={6} className={classes.detailContainer}>
												{this.returnGender(selectedClient.gender)}
											</Grid>
										</Grid>
									</Grid>
									<Grid item md={6} xs={12}>
										<Grid
											container
											alignItems={'center'}
											className={classes.dataRow}>
											<Grid item xs={6} className={classes.detailContainer}>
												Age:
											</Grid>
											<Grid item xs={6} className={classes.detailContainer}>
												{selectedClient.age}
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid container className={classes.row} justify={'center'}>
									<Grid item md={6} xs={12} className={classes.inputContainer}>
										<OutlinedSelect
											handleChange={this.handleServiceChange}
											value={selectedService.id}
											name={'service'}
											returnOptions={() =>
												returnServiceOptions(services.data)
											}
											labelText={'Service'}
										/>
									</Grid>
									<Grid item md={6} xs={12} className={classes.inputContainer}>
										<OutlinedSelect
											notNative
											handleChange={this.handleServiceUserChange}
											value={selectedServiceUser.id}
											name={'resource_person'}
											returnOptions={() =>
												returnResourcePersonOptions(serviceusers.data)
											}
											labelText={'Resource Person'}
										/>
									</Grid>
									<Grid item md={6} xs={12} className={classes.inputContainer}>
										<OutlinedSelect
											handleChange={(e) => {
												const { setParam } = this.props.userappointment;
												setParam('selectedStatus', e.target.value);
											}}
											name={'resource_person'}
											value={selectedStatus}
											returnOptions={() => this.returnStatusOptions()}
											labelText={'Status'}
										/>
									</Grid>
									<Grid item md={6} xs={12} className={classes.inputContainer}>
										<OutlinedSelect
											handleChange={(e) => {
												const { setParam } = this.props.userappointment;
												setParam(
													'selectedClient',
													clients.getClientById(e.target.value),
												);
											}}
											name={'client_id'}
											value={selectedClient.id}
											returnOptions={() =>
												returnClientOptions(clients.clientList)
											}
											labelText={'Client'}
										/>
									</Grid>
									<Grid item xs={12} md={6} className={classes.inputContainer}>
										<OutlinedSelect
											handleChange={(e) => {
												const { setParam } = this.props.userappointment;
												setParam(
													'selectedAppointmentSource',
													+e.target.value,
												);
											}}
											name={'appointment_source'}
											value={selectedAppointmentSource}
											returnOptions={() =>
												this.returnAppointmentSourceOptions()
											}
											labelText={'Appointment Source'}
										/>
									</Grid>
								</Grid>
								{this.state.loading ? (
									<Grid container justify={'center'}>
										<Grid item xs={12} className={classes.row}>
											<CircularProgress />
											<Typography variant={'body1'}>Loading . . .</Typography>
										</Grid>
									</Grid>
								) : intervals.length === 0 ? (
									<Grid container justify={'center'}>
										<Grid item xs={4} className={classes.row}>
											<Typography>
												{selectedServiceUser &&
												selectedServiceUser.user &&
												selectedServiceUser.user.first_name
													? `${selectedServiceUser.user.first_name}'s working hours have not been configured!`
													: 'No Working Hours!'}
											</Typography>
										</Grid>
									</Grid>
								) : (
									<Grid container justify={'center'}>
										<Grid item md={'auto'} xs={12} className={classes.row}>
											<Grid item sm={'auto'}>
												<CalendarComponent
													value={selectedDate}
													disablePast={false}
													shouldDisableDate={this.shouldDisableDate}
													handleDateChange={this.handleSelectedDateChange}
												/>
											</Grid>
										</Grid>
										<Grid item xs={12} className={classes.row}>
											<Grid container>
												{this.returnOperationTimes(
													intervals,
													selectedInterval,
												)}
											</Grid>
										</Grid>
									</Grid>
								)}
								<Grid container justify={'center'}>
									<Grid item xs={12} className={classes.creatorContainer}>
										<Typography variant={'body1'}>
											Created By: {selected.createdby.email}
										</Typography>
									</Grid>
								</Grid>
								<Grid
									container
									alignItems={'center'}
									justify={'center'}
									className={classes.row}>
									<Grid item md={3} xs={12}>
										<Button
											color="primary"
											onClick={this.formSubmit}
											variant={'contained'}
											className={classes.button}>
											Save
										</Button>
									</Grid>
									<Grid item md={3} xs={12}>
										<Button
											onClick={() => history.push('/user/appointments')}
											color="secondary"
											variant={'contained'}
											className={classes.button}>
											Cancel
										</Button>
									</Grid>
								</Grid>

								<AppointmentLog
									selected={selected}
									user_id={this.decodedToken._id}
									email={this.decodedToken._email}
								/>
							</Grid>
						</Grid>
					);
			}
		},
	),
);

export default withStyles(style)(UserEditAppointment);
