import React from 'react';
import { MenuItem } from '@material-ui/core';

import { ORIGIN } from './config';

export const submitOnEnterPress = (e, submit) => {
	if (e.keyCode === 13) {
		submit();
	}
};

export const returnServiceUser = (user) => {
	if (user.title !== null && typeof user.title !== 'undefined') {
		return `${user.title} ${user.first_name} ${
			user.second_name !== null && typeof user.second_name !== 'undefined'
				? user.second_name
				: ''
		} ${user.last_name}`;
	} else {
		return `${user.first_name} ${
			user.second_name !== null && typeof user.second_name !== 'undefined'
				? user.second_name
				: ''
		} ${user.last_name}`;
	}
};

export const returnServiceOptions = (data) => {
	return data.map((item, index) => (
		<option value={item.id} key={`Service${index}`}>
			{item.service_name}
		</option>
	));
};

export const returnResourcePersonOptions = (data) => {
	return [
		<MenuItem value={0} key={`service_user_none`}>
			--None--
		</MenuItem>,
		...data.map((item, index) => (
			<MenuItem
				value={item.id}
				key={`ServiceUser${index}`}
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					height: '75px',
					alignItems: 'center',
				}}>
				{item.user && item.user.filename && item.user.filename !== 'null' ? (
					<img
						style={{
							height: '75px',
							width: '75px',
							display: 'inline',
							objectFit: 'cover',
							borderRadius: '50%',
							border: 'thin solid grey',
						}}
						src={`${ORIGIN}/uploads/user/${item.user.filename}`}
					/>
				) : null}
				{returnServiceUser(item.user)}
				{item.user.price && item.user.price !== '' && item.user.price !== '0' ? (
					<span style={{ color: 'limegreen', fontWeight: 'bold', marginLeft: '10px' }}>
						&#8360;. {item.user.price}
					</span>
				) : null}
			</MenuItem>
		)),
	];
};

export const returnClientOptions = (list) => {
	return list.map((item, index) => (
		<option key={`client-${index}`} value={item.id}>{`${item.first_name} ${
			item.second_name ? item.second_name : ''
		} ${item.last_name}`}</option>
	));
};

export const returnOrganizationOptions = (list) => {
	return list.map((item, index) => (
		<option key={`org-${index}`} value={item.id}>{`${item.name}`}</option>
	));
};

export const returnSiteOptions = (list) => {
	return list.map((item, index) => (
		<option key={`site-${index}`} value={item.id}>{`${item.site_name}`}</option>
	));
};

export const returnStatusOptions = (statuses) => {
	return statuses.map((item, index) => (
		<option value={item.id} key={`Status${index}`}>
			{item.description}
		</option>
	));
};

export const returnAppointmentSourceOptions = (appointment_sources) => {
	return appointment_sources.map((item, index) => (
		<option value={item.id} key={`AppointmentSource-${index}`}>
			{item.domain}
		</option>
	));
};

export const sortByStartTime = (a, b) => {
	if (a.start_time < b.start_time) return -1;
	else if (a.start_time > b.start_time) return 1;
	else return 0;
};
