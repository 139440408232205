export const GOOGLE_CLIENT_ID =
	'290531488924-ud7aop23voo60c6h60hel9uettfcson5.apps.googleusercontent.com';

export const FACEBOOK_APP_ID = '368750287318592';
// export const FACEBOOK_APP_ID = '1347492252280372'; // FACEBOOK APPOINTMENT SYSTEM TEST APP ID

// export const ORIGIN = 'http://localhost:3005';
export const ORIGIN = window.location.origin;
// export const ORIGIN = "http://192.168.0.140:3005";
// export const ORIGIN = "http://192.168.0.228:3005";
// export const ORIGIN = "https://appointment.easy-q.online";
// export const ORIGIN = "http://beta-appointment.easy-q.online";

export const BASE_URL = `${ORIGIN}/api/web/v1`;

export const esewaBASE_URL = 'https://uat.esewa.com.np';

export const KhaltiPublicKey = 'test_public_key_dc74e0fd57cb46cd93832aee0a507256';
export const KhaltiPaymentPreferences = [
	'MOBILE_BANKING',
	'KHALTI',
	'EBANKING',
	'CONNECT_IPS',
	'SCT',
];
