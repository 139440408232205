import { action, decorate, observable } from 'mobx';

class UserAppointmentStore {
	selectedStatus = 0;
	selectedService = { id: 0 };
	selectedServiceUser = { id: 0 };
	selectedOperationTimeObj = {};
	selectedDate = new Date();
	intervals = [];
	selectedInterval = {};
	selectedClient = { id: 0 };
	selectedAppointmentSource = 0;

	setParam = (parameterName, value) => {
		this[parameterName] = value;
	};

	changeIntervalsToSelectedDate = (appointments) => {
		this.intervals.forEach((item) => {
			let { selectedDate } = this;

			item.start_time.setFullYear(selectedDate.getFullYear());
			item.start_time.setMonth(selectedDate.getMonth());
			item.start_time.setDate(selectedDate.getDate());

			let existingAppointment = appointments.filter(
				(appointment) =>
					new Date(appointment.start_time).toLocaleString() ===
					item.start_time.toLocaleString(),
			)[0];

			if (typeof existingAppointment === 'undefined') {
				item.available = true;
			} else {
				item.available = false;
			}

			item.end_time.setFullYear(selectedDate.getFullYear());
			item.end_time.setMonth(selectedDate.getMonth());
			item.end_time.setDate(selectedDate.getDate());
		});
	};

	pushToIntervals = (obj) => {
		this.intervals = [...this.intervals, obj];
	};

	resetIntervals = () => {
		this.intervals = [];
	};

	resetVariables = () => {
		this.selectedStatus = 0;
		this.selectedService = { id: 0 };
		this.selectedServiceUser = { id: 0, Operationtime: [{ week_day: new Date().getDay() }] };
		this.selectedOperationTimeObj = {};
		this.selectedDate = new Date();
		this.intervals = [];
		this.selectedInterval = {};
	};

	resetStore = () => {
		this.selectedStatus = 0;
		this.selectedService = { id: 0 };
		this.selectedServiceUser = { id: 0, Operationtime: [{ week_day: new Date().getDay() }] };
		this.selectedOperationTimeObj = {};
		this.selectedDate = new Date();
		this.intervals = [];
		this.selectedInterval = {};
	};

	masterReset = () => {
		this.selectedStatus = 0;
		this.selectedService = { id: 0 };
		this.selectedServiceUser = { id: 0, Operationtime: [{ week_day: new Date().getDay() }] };
		this.selectedOperationTimeObj = {};
		this.selectedDate = new Date();
		this.intervals = [];
		this.selectedInterval = {};
		this.selectedClient = { id: 0 };
		this.selectedAppointmentSource = 0;
	};
}

export default decorate(UserAppointmentStore, {
	intervals: observable,
	selectedAppointmentSource: observable,
	selectedDate: observable,
	selectedClient: observable,
	selectedStatus: observable,
	selectedService: observable,
	selectedInterval: observable,
	selectedServiceUser: observable,
	selectedOperationTimeObj: observable,
	setParam: action,
	resetIntervals: action,
	resetStore: action,
	resetVariables: action,
	pushToIntervals: action,
	changeIntervalsToSelectedDate: action,
	masterReset: action,
});
