import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { autorun } from 'mobx';
import moment from 'moment';
import { CircularProgress, Grid, Typography, withStyles } from '@material-ui/core';

import OutlinedSelect from '../../Commons/OutlinedSelect';
import CalendarComponent from '../../Commons/Calendar';
import OrganizationHeader from '../UserOrganizationHeader';
import OperationTime from '../../Commons/OperationTime';

import {
	returnResourcePersonOptions,
	returnServiceOptions,
	returnStatusOptions,
	returnAppointmentSourceOptions,
	returnServiceUser,
} from '../../../constants/functions';

import { getDecodedToken } from '../../../libs/token';

import { appointmentsource, site, status, pub } from '../../../api/api';

import { style } from './style';

import {
	addDays,
	addSeconds,
	filterOperationTimesByWeekDay,
	getEndOfDay,
	getStartOfDay,
} from '../../../utils';

const UserAppointmentBooking = inject(
	'services',
	'serviceusers',
	'userappointment',
	'appointments',
	'uistore',
)(
	observer(
		class UserAppointmentBooking extends Component {
			decodedToken;
			dispose;
			state = {
				statuses: [],
				appointment_sources: [],
				appointments: [],
				loading: true,
				siteHolidays: [],
				userHolidays: [],
			};

			async componentDidMount() {
				const {
					services,
					serviceusers,
					appointments,
					uistore,
					userappointment,
					history,
				} = this.props;

				services.masterReset();
				serviceusers.masterReset();
				appointments.masterReset();
				uistore.masterReset();
				userappointment.masterReset();

				this.decodedToken = getDecodedToken();
				if (this.decodedToken) {
					this.setState({
						loading: true,
					});

					const { _sites, _orgs } = this.decodedToken;

					let availableOrganizations = [];
					let availableSites = [];

					if (typeof _sites !== 'undefined' && _sites.length > 0) {
						_sites.forEach((site) => {
							const existingAvailableOrganization = availableOrganizations.filter(
								(item) => +item.id === +site.organization_id,
							)[0];

							if (typeof existingAvailableOrganization === 'undefined')
								availableOrganizations.push(site.organization);

							availableSites.push(site);
						});
					}

					if (typeof _orgs !== 'undefined' && _orgs.length > 0) {
						availableOrganizations = [...availableOrganizations, ..._orgs];
						for (const organization of _orgs) {
							try {
								const res = await site.fetchByOrganizationId(organization.id);

								if (res && res.sites)
									availableSites = [...availableSites, ...res.sites];
							} catch (e) {
								this.setState({
									loading: false,
								});

								console.log(e);
							}
						}
					}

					try {
						const res = await appointmentsource.fetch();

						if (res && res.appointmentsources) {
							this.setState({
								appointment_sources: res.appointmentsources,
							});

							if (res.appointmentsources.length > 0 && res.appointmentsources)
								userappointment.setParam(
									'selectedAppointmentSource',
									res.appointmentsources[0].id,
								);
						}
					} catch (e) {
						this.setState({
							loading: false,
						});

						console.log(e);
					}

					uistore.setSites(availableSites);
					uistore.setOrganizations(availableOrganizations);

					if (availableSites.length > 0) {
						uistore.setSelectedSite(availableSites[0]);
						uistore.setSelectedOrganization(availableSites[0].organization);
						// this.makeRequestsWithSiteId(availableSites[0].id);
					}

					this.dispose = autorun(() => {
						userappointment.resetStore();

						if (+uistore.selectedSite.id !== 0)
							this.makeRequestsWithSiteId(uistore.selectedSite.id);
						else
							this.setState({
								loading: false,
							});
					});
				} else {
					history.replace('/');
				}
			}

			componentWillUnmount() {
				if (this.dispose) this.dispose();
			}

			makeRequestsWithSiteId = async (site_id) => {
				const { userappointment, services, serviceusers } = this.props;
				const { setParam } = userappointment;

				try {
					const site_holiday_res = await pub.fetchCalendarBySiteId(site_id);
					if (site_holiday_res && site_holiday_res.calendar) {
						this.setState({
							siteHolidays: site_holiday_res.calendar,
						});
					}
				} catch (e) {
					this.setState({
						loading: false,
					});

					console.log(e);
				}

				try {
					const status_res = await status.fetch(site_id);
					if (status_res && status_res.statuses) {
						this.setState({
							statuses: status_res.statuses,
						});

						if (status_res.statuses.length > 0)
							setParam('selectedStatus', status_res.statuses[0].id);
					}
				} catch (e) {
					this.setState({
						loading: false,
					});

					console.log(e);
				}

				try {
					const service_res = await services.fetch(site_id);
					if (service_res && service_res.services) {
						if (service_res.services.length > 0) {
							const firstService = service_res.services[0];
							this.assignFirstServiceUser(firstService);
						} else {
							serviceusers.resetData();
						}
					}
				} catch (e) {
					this.setState({
						loading: false,
					});

					console.log(e);
				}
			};

			assignFirstServiceUser = async (firstService) => {
				const { serviceusers } = this.props;

				try {
					const serviceusers_res = await serviceusers.fetch(firstService.id);

					if (serviceusers_res && serviceusers_res.serviceuser) {
						if (serviceusers_res.serviceuser.length > 0) {
							pub.fetchCalendarByUserId(serviceusers_res.serviceuser[0].user_id).then(
								(res) => {
									if (res && res.status === 200 && res.calendar) {
										this.setState(
											{
												userHolidays: res.calendar,
											},
											() => {
												this.checkAppointmentsAndPushIntervals(
													firstService,
													serviceusers_res.serviceuser[0],
													new Date(),
												);
											},
										);
									}
								},
							);
						} else {
							this.setState(
								{
									userHolidays: [],
								},
								() => {
									this.checkAppointmentsAndPushIntervals(
										firstService,
										{ id: 0 },
										new Date(),
									);
								},
							);

							this.setState({
								loading: false,
							});
						}
					}
				} catch (e) {
					this.setState({
						loading: false,
					});

					console.log(e);
				}
			};

			returnExistingAppointment = (appointments, start, end) => {
				let appointment;

				const startInMins = this.returnTimeInMinutes(start);
				const endInMins = this.returnTimeInMinutes(end);

				appointments.forEach((item) => {
					const appointmentStartInMins = this.returnTimeInMinutes(
						new Date(item.start_time),
					);
					const appointmentEndInMins = this.returnTimeInMinutes(new Date(item.end_time));

					if (
						appointmentStartInMins <= startInMins &&
						appointmentEndInMins >= endInMins
					) {
						appointment = item;
					}
				});

				return appointment;
			};

			returnTimeInMinutes = (date) => {
				return date.getHours() * 60 + date.getMinutes();
			};

			parseIntervalsAndPush = (opTimeArr, duration, appointments = []) => {
				const { userappointment } = this.props;
				const { selectedDate, pushToIntervals, resetIntervals } = userappointment;

				resetIntervals();

				if (typeof opTimeArr !== 'undefined')
					for (const { start_time, end_time } of opTimeArr) {
						let start = new Date(start_time);
						let end = new Date(end_time);
						let actualEnd;

						start.setFullYear(selectedDate.getFullYear());
						start.setMonth(selectedDate.getMonth());
						start.setDate(selectedDate.getDate());

						end.setFullYear(selectedDate.getFullYear());
						end.setMonth(selectedDate.getMonth());
						end.setDate(selectedDate.getDate());

						let selectedDateAppointments = appointments.filter(
							(item) =>
								new Date(item.start_time).toLocaleDateString() ===
								selectedDate.toLocaleDateString(),
						);

						while (start < end) {
							const newStart = addSeconds(start, duration);

							if (newStart < end) {
								actualEnd = newStart;
							} else actualEnd = end;

							const isSiteHoliday = !!this.state.siteHolidays.find(
								(holiday) =>
									moment(start).isBetween(holiday.start_time, holiday.end_time) ||
									moment(actualEnd).isBetween(
										holiday.start_time,
										holiday.end_time,
									),
							);

							const isUserHoliday = !!this.state.userHolidays.find(
								(holiday) =>
									moment(start).isBetween(holiday.start_time, holiday.end_time) ||
									moment(actualEnd).isBetween(
										holiday.start_time,
										holiday.end_time,
									),
							);

							if (
								selectedDateAppointments.length > 0 &&
								typeof selectedDateAppointments !== 'undefined'
							) {
								let existingAppointment = this.returnExistingAppointment(
									selectedDateAppointments,
									start,
									actualEnd,
								);

								if (typeof existingAppointment === 'undefined') {
									pushToIntervals({
										start_time: start,
										end_time: actualEnd,
										available: !isSiteHoliday && !isUserHoliday,
									});
								} else {
									const differenceInSeconds =
										new Date(existingAppointment.end_time).getTime() -
										new Date(existingAppointment.start_time).getTime();
									if (+differenceInSeconds !== +duration) {
										actualEnd = new Date(existingAppointment.end_time);
									}

									pushToIntervals({
										start_time: start,
										end_time: actualEnd,
										available: false,
									});
								}
							} else {
								pushToIntervals({
									start_time: start,
									end_time: actualEnd,
									available: !isSiteHoliday && !isUserHoliday,
								});
							}
							start = actualEnd;
						}

						this.setState({
							loading: false,
						});
					}
				else {
					this.setState({
						loading: false,
					});
				}
			};

			handleSelectedDateChange = (date) => {
				this.setState({
					loading: true,
				});

				const dateClone = new Date(date);
				const { userappointment } = this.props;
				const {
					setParam,
					selectedService,
					selectedServiceUser,
					changeIntervalsToSelectedDate,
				} = userappointment;

				const { Operationtime } =
					selectedServiceUser.id === 0 ? selectedService : selectedServiceUser;

				const OperationTimeObj = Operationtime.filter(
					(item) => item.week_day === dateClone.getDay(),
				);

				setParam('intervals', []);
				setParam('selectedDate', dateClone);
				setParam('selectedOperationTimeObj', OperationTimeObj);
				setParam('selectedInterval', {});

				this.checkAppointmentsAndPushIntervals(
					selectedService,
					selectedServiceUser,
					dateClone,
				);
				changeIntervalsToSelectedDate(this.state.appointments);
			};

			shouldDisableDate = (date) => {
				const { selectedServiceUser, selectedService } = this.props.userappointment;
				const { Operationtime } =
					selectedServiceUser.id === 0 ? selectedService : selectedServiceUser;
				const dateClone = new Date(date);

				let operationTimeObj = Operationtime.filter(
					(item) => item.week_day === dateClone.getDay(),
				);

				return !operationTimeObj.length > 0;
			};

			checkAppointmentsAndPushIntervals = (service, service_user, date) => {
				const { appointments, userappointment } = this.props;
				const { setParam } = userappointment;

				let Operationtime =
					service_user.id !== 0 ? service_user.Operationtime : service.Operationtime;

				setParam('selectedService', service);
				setParam('selectedServiceUser', service_user);

				if (Operationtime.length === 0) {
					setParam('intervals', []);
					this.setState({
						loading: false,
					});
				} else {
					let operationTimeDate = Operationtime.filter(
						(item) => item.week_day === date.getDay(),
					);

					operationTimeDate.sort((a, b) => {
						if (a.start_time < b.start_time) return -1;
						else if (a.start_time > b.start_time) return 1;
						else return 0;
					});

					if (operationTimeDate.length === 0) {
						let iterations = 0;
						while (operationTimeDate.length === 0) {
							if (iterations >= 7) break;

							date = addDays(date, 1);

							operationTimeDate = filterOperationTimesByWeekDay(Operationtime, date);
							operationTimeDate.sort((a, b) => {
								if (a.start_time < b.start_time) return -1;
								else if (a.start_time > b.start_time) return 1;
								else return 0;
							});

							iterations++;
						}
					}

					if (service_user.id !== 0) {
						appointments
							.fetchByUserId(
								service_user.user_id,
								getStartOfDay(date).toISOString(),
								getEndOfDay(date).toISOString(),
							)
							.then((appointment_res) => {
								const { appointments } = appointment_res;

								this.setState(
									{
										appointments,
									},
									() => {
										if (operationTimeDate.length !== 0) {
											setParam('selectedDate', date);
											this.parseIntervalsAndPush(
												operationTimeDate,
												service.service_period,
												appointments,
											);
										} else
											this.setState({
												loading: false,
											});
									},
								);
							});
					} else {
						appointments
							.fetchUserlessAppointmentsByServiceId(
								service.id,
								getStartOfDay(date).toISOString(),
								getEndOfDay(date).toISOString(),
							)
							.then((appointment_res) => {
								const { appointments } = appointment_res;

								this.setState(
									{
										appointments,
									},
									() => {
										if (operationTimeDate.length !== 0) {
											setParam('selectedDate', date);
											this.parseIntervalsAndPush(
												operationTimeDate,
												service.service_period,
												appointments,
											);
										} else
											this.setState({
												loading: false,
											});
									},
								);
							});
					}
				}
			};

			handleServiceChange = (e) => {
				const { userappointment, services } = this.props;
				const { setParam } = userappointment;
				const selectedService = services.getByID(e.target.value);

				setParam('selectedService', selectedService);
				this.assignFirstServiceUser(selectedService);
			};

			handleServiceUserChange = (e) => {
				const { serviceusers, userappointment } = this.props;
				const { setParam, selectedService, selectedDate } = userappointment;
				const selectedServiceUser =
					e.target.value == 0 ? { id: 0 } : serviceusers.getByID(e.target.value);

				setParam('selectedServiceUser', selectedServiceUser);

				if (selectedServiceUser && selectedServiceUser.user_id) {
					pub.fetchCalendarByUserId(selectedServiceUser.user_id).then((res) => {
						if (res && res.status === 200 && res.calendar) {
							this.setState(
								{
									userHolidays: res.calendar,
								},
								() => {
									this.checkAppointmentsAndPushIntervals(
										selectedService,
										selectedServiceUser,
										selectedDate,
									);
								},
							);
						}
					});
				} else {
					this.setState(
						{
							userHolidays: [],
						},
						() => {
							this.checkAppointmentsAndPushIntervals(
								selectedService,
								selectedServiceUser,
								selectedDate,
							);
						},
					);
				}
			};

			formSubmit = () => {
				const { userappointment, history } = this.props;
				const {
					selectedInterval,
					selectedStatus,
					selectedAppointmentSource,
				} = userappointment;

				if (Object.keys(selectedInterval).length === 0) {
					alert('Please select a Time Interval to proceed further!');
				} else if (selectedStatus === 0) alert('Status is required!');
				else if (selectedAppointmentSource === 0) alert('Appointment Source is required!');
				else history.push('/user/client_information');
			};

			render() {
				const { classes, services, serviceusers, userappointment } = this.props;
				const {
					selectedAppointmentSource,
					selectedService,
					selectedServiceUser,
					selectedDate,
					selectedStatus,
					intervals,
					selectedInterval,
					setParam,
				} = userappointment;

				const serviceData = services.data;
				const serviceUserData = serviceusers.data;

				const siteHoliday = this.state.siteHolidays.find((h) =>
					moment(selectedDate).isBetween(h.start_time, h.end_time),
				);
				const userHoliday = this.state.userHolidays.find((h) =>
					moment(selectedDate).isBetween(h.start_time, h.end_time),
				);

				return (
					<Grid container alignItems={'center'} justify={'center'}>
						<Grid item xs={12}>
							<OrganizationHeader />
							<Grid
								container
								alignItems={'center'}
								justify={'center'}
								className={classes.row}>
								<Grid item xs={12} md={6} className={classes.inputContainer}>
									<OutlinedSelect
										handleChange={this.handleServiceChange}
										name={'service'}
										value={selectedService.id}
										returnOptions={() => returnServiceOptions(serviceData)}
										labelText={'Service'}
									/>
								</Grid>
								<Grid item xs={12} md={6} className={classes.inputContainer}>
									<OutlinedSelect
										notNative
										handleChange={this.handleServiceUserChange}
										name={'resource_person'}
										value={selectedServiceUser.id}
										returnOptions={() =>
											returnResourcePersonOptions(serviceUserData)
										}
										labelText={'Resource Person'}
									/>
								</Grid>
								<Grid item xs={12} md={6} className={classes.inputContainer}>
									<OutlinedSelect
										handleChange={(e) => {
											const { setParam } = this.props.userappointment;
											setParam('selectedAppointmentSource', +e.target.value);
										}}
										name={'appointment_source'}
										value={selectedAppointmentSource}
										returnOptions={() =>
											returnAppointmentSourceOptions(
												this.state.appointment_sources,
											)
										}
										labelText={'Appointment Source'}
									/>
								</Grid>
								<Grid item xs={12} md={6} className={classes.inputContainer}>
									<OutlinedSelect
										handleChange={(e) => {
											const { setParam } = this.props.userappointment;
											setParam('selectedStatus', +e.target.value);
										}}
										name={'resource_person'}
										value={selectedStatus}
										returnOptions={() =>
											returnStatusOptions(this.state.statuses)
										}
										labelText={'Status'}
									/>
								</Grid>
							</Grid>
							{this.state.siteHolidays.length > 0 ||
							this.state.userHolidays.length > 0 ? (
								<Grid container justify={'center'}>
									{siteHoliday ? (
										<Grid item xs={12} className={classes.row}>
											Site is closed from{' '}
											<b>
												{new Date(siteHoliday.start_time).toLocaleString()}
											</b>{' '}
											to{' '}
											<b>
												{new Date(siteHoliday.start_time).toLocaleString()}
											</b>
										</Grid>
									) : (
										''
									)}
									{userHoliday ? (
										<Grid item xs={12} className={classes.row}>
											User is on leave from{' '}
											<b>
												{new Date(userHoliday.start_time).toLocaleString()}
											</b>{' '}
											to{' '}
											<b>
												{new Date(userHoliday.start_time).toLocaleString()}
											</b>
										</Grid>
									) : (
										''
									)}
								</Grid>
							) : (
								''
							)}
							{this.state.loading ? (
								<Grid container justify={'center'}>
									<Grid item xs={12} className={classes.row}>
										<CircularProgress />
										<Typography variant={'body1'}>Loading . . .</Typography>
									</Grid>
								</Grid>
							) : intervals.length === 0 ? (
								<Grid container justify={'center'}>
									<Grid item xs={4} className={classes.row}>
										<Typography>
											{selectedServiceUser &&
											selectedServiceUser.user &&
											selectedServiceUser.user.first_name
												? `${selectedServiceUser.user.first_name}'s working hours have not been configured for ${selectedService.service_name}!`
												: 'No Working Hours!'}
										</Typography>
									</Grid>
								</Grid>
							) : (
								<Grid
									container
									alignItems={'center'}
									justify={'center'}
									className={classes.row}>
									<Grid item sm={'auto'}>
										<CalendarComponent
											value={selectedDate}
											disablePast
											shouldDisableDate={this.shouldDisableDate}
											handleDateChange={this.handleSelectedDateChange}
										/>
									</Grid>
									<Grid item xs={12}>
										<OperationTime
											setParam={setParam}
											intervals={intervals}
											selectedInterval={selectedInterval}
											loading={this.state.loading}
											formSubmit={this.formSubmit}
										/>
									</Grid>
								</Grid>
							)}
						</Grid>
					</Grid>
				);
			}
		},
	),
);

export default withStyles(style)(UserAppointmentBooking);
