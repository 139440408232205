import { action, decorate, observable } from 'mobx';

import { service } from '../api/api';

const addMonths = function (date, months) {
	const newDate = new Date(date);

	newDate.setMonth(newDate.getMonth() + months);

	return new Date(newDate);
};

class UIStore {
	selectedOrganization = {
		id: 0,
		name: 'No Organizations registered to this user!',
	};

	selectedSite = {
		id: 0,
		site_name: 'No Sites registered to this user!',
	};

	selectedService = {
		id: 0,
		site_name: 'No Services in this site!',
	};

	sites = [];
	organizations = [];
	services = [{ id: -1, service_name: 'All Services' }];
	query = '';
	firstLoad = true;

	clientEmailCheckbox = true;
	clientNameCheckbox = false;
	clientMobileCheckbox = true;
	clientAddressCheckbox = false;

	userEmailCheckbox = false;
	userNameCheckbox = false;

	searchStartDate = new Date().setHours(0, 0, 0);
	searchEndDate = new Date(addMonths(new Date(), 1)).setHours(23, 59, 59);

	toggleClientEmailCheckbox = () => (this.clientEmailCheckbox = !this.clientEmailCheckbox);
	toggleClientNameCheckbox = () => (this.clientNameCheckbox = !this.clientNameCheckbox);
	toggleClientAddressCheckbox = () => (this.clientAddressCheckbox = !this.clientAddressCheckbox);
	toggleClientMobileCheckbox = () => (this.clientMobileCheckbox = !this.clientMobileCheckbox);

	toggleUserEmailCheckbox = () => (this.userEmailCheckbox = !this.userEmailCheckbox);
	toggleUserNameCheckbox = () => (this.userNameCheckbox = !this.userNameCheckbox);

	handleStartDateChange = (date) => {
		this.searchStartDate = date;
		this.firstLoad = true;
	};

	handleEndDateChange = (date) => {
		this.searchEndDate = date;
		this.firstLoad = true;
	};

	handleQueryChange = (value) => {
		this.query = value;
		this.firstLoad = true;
	};

	setSelectedService = (service) => {
		this.selectedService = service;
	};

	setSelectedSite = (site) => {
		this.selectedSite = site;
	};

	setSelectedOrganization = (org) => {
		this.selectedOrganization = org;
	};

	setSites = (sites) => {
		this.sites = sites;
	};

	setOrganizations = (organizations) => {
		this.organizations = organizations;
	};

	setServices = (services) => {
		this.services = [{ id: -1, service_name: 'All Services' }, ...services];
	};

	setFirstLoad = (firstLoad) => {
		this.firstLoad = firstLoad;
	};

	resetOrganization = () => {
		this.selectedOrganization = {
			id: 0,
			name: 'No Organizations registered to this user!',
		};
	};

	resetSite = () => {
		this.selectedSite = {
			id: 0,
			site_name: 'No Sites registered to this user!',
		};
	};

	resetService = () => {
		this.selectedService = {
			id: 0,
			site_name: 'No Services in this site!',
		};
	};

	fetchServicesBySiteId = (site_id) => {
		return service.fetch(site_id).then((res) => {
			this.services = [{ id: -1, service_name: 'All Services' }, ...res.services];
			this.selectedService = { id: -1, service_name: 'All Services' };
		});
	};

	masterReset = () => {
		this.selectedOrganization = {
			id: 0,
			name: 'No Organizations registered to this user!',
		};

		this.selectedSite = {
			id: 0,
			site_name: 'No Sites registered to this user!',
		};

		this.selectedService = {
			id: 0,
			site_name: 'No Services in this site!',
		};

		this.sites = [];
		this.organizations = [];
		this.services = [{ id: -1, service_name: 'All Services' }];
		this.query = '';
		this.firstLoad = true;
	};
}

export default decorate(UIStore, {
	sites: observable,
	organizations: observable,
	services: observable,

	selectedOrganization: observable,
	selectedSite: observable,
	selectedService: observable,

	query: observable,
	firstLoad: observable,

	clientEmailCheckbox: observable,
	clientNameCheckbox: observable,
	clientMobileCheckbox: observable,
	clientAddressCheckbox: observable,

	userEmailCheckbox: observable,
	userNameCheckbox: observable,

	searchStartDate: observable,
	searchEndDate: observable,

	toggleClientEmailCheckbox: action,
	toggleClientNameCheckbox: action,
	toggleClientAddressCheckbox: action,
	toggleClientMobileCheckbox: action,

	toggleUserEmailCheckbox: action,
	toggleUserNameCheckbox: action,

	handleStartDateChange: action,
	handleEndDateChange: action,
	handleQueryChange: action,

	setSites: action,
	setOrganizations: action,
	setServices: action,

	setFirstLoad: action,

	setSelectedOrganization: action,
	setSelectedSite: action,
	setSelectedService: action,

	resetOrganization: action,
	resetSite: action,
	resetService: action,

	fetchServicesBySiteId: action,
	masterReset: action,
});
