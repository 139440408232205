export const styles = (theme) => ({
	button: {
		margin: theme.spacing.unit,
		width: '100%',
	},
	margin: {
		margin: theme.spacing.unit,
	},
	textField: {
		width: '100%',
		padding: '10px 0 10px 0',
	},
	leftIcon: {
		marginRight: theme.spacing.unit,
	},
	password: {
		cursor: 'pointer',
	},
	mainContainer: {
		height: 'calc(100vh - 64px)',
	},
	secondaryContainer: {
		border: '1px lightgrey solid',
		borderRadius: '10px',
		padding: '20px',
		width: '100%',
		height: 'auto',
		minHeight: '600px',
	},
	loginFormContainer: {
		height: 'auto',
	},
	externalLoginContainer: {
		height: 'auto',
		textAlign: 'center',
	},
	versionContainer: {
		height: 'auto',
		textAlign: 'right',
	},
	developerContainer: {
		textAlign: 'center',
	},
	controlRow: {
		height: '100%',
		width: '100%',
	},
	progress: {
		margin: theme.spacing.unit * 2,
	},
	socialButton: {
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		borderRadius: '50%',
		display: 'inline-block',
		height: '50px',
		width: '50px',
		margin: '20px',
		cursor: 'pointer',
	},
	title: {
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: '2rem',
		padding: '10px',
		animation: 'bounce 0.5s cubic-bezier(.5,0.05,1,.5)',
		'animation-direction': 'alternate',
		'animation-iteration-count': 'infinite',
	},
	'@keyframes bounce': {
		from: { transform: 'translate3d(0, 0, 0)' },
		to: { transform: 'translate3d(0, 5px, 0)' },
	},
	strike: {
		display: 'block',
		textAlign: 'center',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		'&>span': {
			position: 'relative',
			display: 'inline-block',
			'&:before, &:after': {
				content: '',
				position: 'absolute',
				top: '50%',
				width: '9999px',
				height: '1px',
				background: 'lightgrey',
			},
			'&:before': {
				right: '100%',
				marginRight: '15px',
			},
			'&:after': {
				left: '100%',
				marginLeft: '15px',
			},
		},
	},
});
