import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import MomentUtils from '@date-io/moment';
import { DateTimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import { ChevronLeft, ChevronRight, Menu } from '@material-ui/icons';
import {
	AppBar,
	Button,
	Divider,
	Drawer,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Toolbar,
	Tooltip,
	Typography,
	withStyles,
} from '@material-ui/core';

import CustomCheckbox from '../../Commons/CustomCheckbox';

import { getDecodedToken } from '../../../libs/token';

import { styles } from './style';

const UserHeader = inject('uistore')(
	observer(
		class Header extends Component {
			state = {
				open: true,
				decodedToken: {},
			};

			componentDidMount() {
				const { history } = this.props;
				const decodedToken = getDecodedToken();

				if (decodedToken) this.setState({ decodedToken });
				else history.replace('/');
			}

			handleDrawerOpen = () => {
				this.setState({ open: true });
			};

			handleDrawerClose = () => {
				this.setState({ open: false });
			};

			render() {
				const { classes, theme, children, history, location, uistore } = this.props;
				const { open, decodedToken } = this.state;
				const { _firstName, _title } = decodedToken;

				const {
					clientEmailCheckbox,
					clientNameCheckbox,
					clientAddressCheckbox,
					clientMobileCheckbox,
					userEmailCheckbox,
					userNameCheckbox,

					searchStartDate,
					searchEndDate,

					toggleClientEmailCheckbox,
					toggleClientNameCheckbox,
					toggleClientAddressCheckbox,
					toggleClientMobileCheckbox,
					toggleUserEmailCheckbox,
					toggleUserNameCheckbox,

					handleStartDateChange,
					handleEndDateChange,
				} = uistore;

				return (
					<div className={classes.root}>
						<AppBar
							position="sticky"
							className={classNames({
								[classes.appBarShift]: open,
							})}>
							<Toolbar>
								<IconButton
									className={classes.menuButton}
									color="inherit"
									aria-label="Menu"
									onClick={open ? this.handleDrawerClose : this.handleDrawerOpen}>
									<Menu />
								</IconButton>
								<Typography variant="h6" color="inherit" className={classes.grow}>
									Appointment System
								</Typography>
								<Tooltip title={'Logout'}>
									<Button
										color="inherit"
										onClick={() => this.props.history.push('/login')}>
										Welcome, {_title ? _title : ''} {_firstName}
									</Button>
								</Tooltip>
							</Toolbar>
							<Drawer
								className={classes.drawer}
								variant="persistent"
								anchor="left"
								open={open}
								classes={{
									paper: classes.drawerPaper,
								}}>
								<div className={classes.drawerHeader}>
									<IconButton onClick={this.handleDrawerClose}>
										{theme.direction === 'ltr' ? (
											<ChevronLeft />
										) : (
											<ChevronRight />
										)}
									</IconButton>
								</div>
								<Divider />
								<List>
									<ListItem
										button
										onClick={() => history.push('/user/book_appointment')}>
										<ListItemText primary={'Book an appointment'} />
									</ListItem>
									<ListItem
										button
										onClick={() => history.push('/user/appointments')}>
										<ListItemText primary={'Edit an appointment'} />
									</ListItem>
								</List>
								<Divider />
								{location.pathname === '/appointments' && (
									<Grid container justify={'center'}>
										<Grid
											item
											xs={12}
											className={classes.searchParamsContainer}>
											<Typography variant={'body1'}>Search By:</Typography>
										</Grid>
										<Grid item xs={10}>
											<Divider />
										</Grid>
										<Grid item xs={10}>
											<CustomCheckbox
												checked={clientEmailCheckbox}
												label={'Client Email'}
												onChange={toggleClientEmailCheckbox}
											/>
										</Grid>
										<Grid item xs={10}>
											<CustomCheckbox
												checked={clientNameCheckbox}
												label={'Client Name'}
												onChange={toggleClientNameCheckbox}
											/>
										</Grid>
										<Grid item xs={10}>
											<CustomCheckbox
												checked={clientMobileCheckbox}
												label={'Client Mobile No'}
												onChange={toggleClientMobileCheckbox}
											/>
										</Grid>
										<Grid item xs={10}>
											<CustomCheckbox
												checked={clientAddressCheckbox}
												label={'Client Address'}
												onChange={toggleClientAddressCheckbox}
											/>
										</Grid>
										<Grid item xs={10}>
											<Divider />
										</Grid>
										<Grid item xs={10}>
											<CustomCheckbox
												checked={userEmailCheckbox}
												label={'Resource Person Email'}
												onChange={toggleUserEmailCheckbox}
											/>
										</Grid>
										<Grid item xs={10}>
											<CustomCheckbox
												checked={userNameCheckbox}
												label={'Resource Person Name'}
												onChange={toggleUserNameCheckbox}
											/>
										</Grid>
										<Grid item xs={10}>
											<Divider />
										</Grid>
										<Grid
											item
											xs={10}
											className={classes.searchParamsContainer}>
											<MuiPickersUtilsProvider utils={MomentUtils}>
												<DateTimePicker
													variant="standard"
													label="From"
													InputProps={{
														fullWidth: true,
													}}
													value={searchStartDate}
													onChange={handleStartDateChange}
													onError={console.log}
												/>
											</MuiPickersUtilsProvider>
										</Grid>
										<Grid
											item
											xs={10}
											className={classes.searchParamsContainer}>
											<MuiPickersUtilsProvider utils={MomentUtils}>
												<DateTimePicker
													variant="standard"
													InputProps={{
														fullWidth: true,
													}}
													label="To"
													value={searchEndDate}
													onChange={handleEndDateChange}
													onError={console.log}
												/>
											</MuiPickersUtilsProvider>
										</Grid>
										<Grid item xs={12}>
											<Divider />
										</Grid>
									</Grid>
								)}
							</Drawer>
						</AppBar>
						<main
							className={classNames(classes.content, {
								[classes.contentShift]: open,
							})}>
							{children}
						</main>
					</div>
				);
			}
		},
	),
);

export default withStyles(styles, { withTheme: true })(UserHeader);
