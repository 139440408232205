import React from 'react';
import KhaltiCheckout from 'khalti-checkout-web';
import { observer, inject } from 'mobx-react';

import KhaltiPaymentButton from './KhaltiPaymentButton';
import EsewaPaymentButton from './EsewaPaymentButton';
import { KhaltiPaymentPreferences, KhaltiPublicKey } from '../constants/config';
import { verify } from '../api/api';

class PaymentSelection extends React.Component {
	handleKhaltiClick = (appointment_id) => {
		const { userappointment } = this.props;
		const { selectedServiceUser } = userappointment;
		const { user } = selectedServiceUser;

		const config = {
			publicKey: KhaltiPublicKey,
			productIdentity: '1234567890',
			productName: 'Drogon',
			productUrl: 'http://gameofthrones.com/buy/Dragons',
			eventHandler: {
				onSuccess: (payload) => {
					// hit merchant api for initiating verfication
					const { token, amount } = payload;

					verify.verifyKhalti({ token, amount, appointment_id }).then((res) => {
						if (res && res.message) {
							alert(res.message);

							if (res.message === 'Payment Successful!')
								this.props.history.push('/client/appointments');
						}

						console.log(res);
					});
				},
				// onError handler is optional
				onError: (error) => {
					// handle errors
					console.log(error);

					alert('Payment Failed');
					this.props.history.push('/client/appointments');
				},
			},
			// one can set the order of payment options and also the payment options based on the order and items in the array
			paymentPreference: KhaltiPaymentPreferences,
		};

		const checkout = new KhaltiCheckout(config);

		checkout.show({ amount: +user.price * 100 });
	};

	handleEsewaClick = (appointment_id) => {
		const { selectedServiceUser } = this.props.userappointment;
		const { user } = selectedServiceUser;

		var path = 'https://uat.esewa.com.np/epay/main';
		var params = {
			amt: +user.price,
			psc: 0,
			pdc: 0,
			txAmt: 0,
			tAmt: +user.price,
			pid: 'ee2c3ca1-696b-4cc5-a6be-2c40d929d453',
			scd: 'EPAYTEST',
			su: `${window.location.origin}/app/#/esewa_payment_success/${appointment_id}`,
			fu: `${window.location.origin}/app/#/esewa_payment_failure/${appointment_id}`,
			// su: `http://localhost:3000/#/esewa_payment_success/${id}`,
			// fu: `http://localhost:3000/#/esewa_payment_failure/${id}`
		};

		const form = document.createElement('form');

		form.setAttribute('method', 'POST');
		form.setAttribute('action', path);

		for (let key in params) {
			const hiddenField = document.createElement('input');

			hiddenField.setAttribute('type', 'hidden');
			hiddenField.setAttribute('name', key);
			hiddenField.setAttribute('value', params[key]);

			form.appendChild(hiddenField);
		}

		document.body.appendChild(form);
		form.submit();
	};

	render() {
		const { formSubmit } = this.props;

		return (
			<div>
				<div>
					<h2 style={{ textAlign: 'center' }}>
						Pay{' '}
						<b style={{ color: 'greenyellow' }}>
							Rs. {this.props.userappointment.selectedServiceUser.user.price}
						</b>{' '}
						through
					</h2>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-around',
						alignItems: 'center',
					}}>
					<KhaltiPaymentButton handleClick={() => formSubmit(this.handleKhaltiClick)} />
					<div>
						<span>or</span>
					</div>
					<EsewaPaymentButton handleClick={() => formSubmit(this.handleEsewaClick)} />
				</div>
			</div>
		);
	}
}

export default inject(
	'publicstore',
	'userappointment',
	'uistore',
	'clients',
)(observer(PaymentSelection));
