import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { GoogleLogout } from 'react-google-login';
import {
	Grid,
	AppBar,
	IconButton,
	Popover,
	Toolbar,
	Typography,
	withStyles,
	Button,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Drawer,
	Divider,
} from '@material-ui/core';
import {
	Menu,
	ChevronLeft,
	ChevronRight,
	AccountCircle,
	Book,
	ListAlt,
	Link,
} from '@material-ui/icons';
import { getDecodedToken } from '../../../libs/token';
import { GOOGLE_CLIENT_ID } from '../../../constants/config';

import { Image } from '../../Commons/Image';
const drawerWidth = 240;

const styles = (theme) => ({
	thematicText: {
		color: theme.palette.primary.contrastText,
	},
	root: {
		flexGrow: 1,
	},
	grow: {
		flexGrow: 1,
	},
	menuButton: {
		marginLeft: -12,
		marginRight: 20,
	},
	appBar: {
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	content: {
		flexGrow: 1,
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: 0,
		height: 'calc(100vh - 64px)',
	},
	contentShift: {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: drawerWidth,
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerHeader: {
		display: 'flex',
		alignItems: 'center',
		padding: '0 8px',
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	},
	searchParamsContainer: {
		padding: '20px',
	},
});

const ClientHeader = (props) => {
	const [anchorEl, setAnchorEl] = useState();
	const [drawerOpen, setDrawerOpen] = useState();
	const [token, setToken] = useState({});

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDrawerOpen = () => setDrawerOpen(true);
	const handleDrawerClose = () => setDrawerOpen(false);

	const open = Boolean(anchorEl);
	const { children, classes, history, theme } = props;

	const returnUserImageAndPopover = () => {
		const { _firstName, _lastName, _email } = token;

		return (
			<Grid item xs={6} style={{ textAlign: 'right' }}>
				{returnClickableUserImage()}
				<Popover
					id="simple-popper"
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}>
					<Grid
						container
						justify="center"
						alignItems="center"
						style={{ width: 'auto', height: 'auto' }}>
						<Grid
							item
							xs={4}
							style={{ padding: '5px', cursor: 'pointer' }}
							onClick={() => {
								props.history.push('/client/me');
								handleClose();
							}}>
							{returnBigUserImage()}
						</Grid>
						<Grid
							item
							xs={8}
							style={{ padding: '10px', cursor: 'pointer' }}
							onClick={() => {
								props.history.push('/client/me');
								handleClose();
							}}>
							<Typography
								variant="body1"
								style={{
									display: 'block',
									wordWrap: 'break-word',
									fontWeight: 'bold',
								}}>{`${_firstName} ${_lastName}`}</Typography>
							<Typography
								variant="body2"
								style={{
									display: 'block',
									wordWrap: 'break-word',
								}}>
								{_email}
							</Typography>
						</Grid>
						<Grid item xs={12} style={{ textAlign: 'center' }}>
							{returnLogoutButton()}
						</Grid>
					</Grid>
				</Popover>
			</Grid>
		);
	};

	const returnLogoutButton = () => {
		if (token) {
			if (token._type) {
				if (+token._type === 1) {
					return (
						<GoogleLogout
							clientId={GOOGLE_CLIENT_ID}
							render={(renderProps) => (
								<Button
									variant="contained"
									color="secondary"
									style={{ width: '100%' }}
									onClick={renderProps.onClick}>
									Logout!
								</Button>
							)}
							onLogoutSuccess={() => {
								localStorage.removeItem('token');
								history.replace('/');
							}}
						/>
					);
				} else if (+token._type === 2) {
					return (
						<Button
							variant="contained"
							color="secondary"
							style={{ width: '100%' }}
							onClick={() => {
								if (window && window.FB && window.FB.logout) window.FB.logout();

								localStorage.removeItem('token');
								history.replace('/');
							}}>
							Logout!
						</Button>
					);
				} else return <DefaultLogout />;
			} else {
				return <DefaultLogout />;
			}
		} else {
			return <DefaultLogout />;
		}
	};

	const DefaultLogout = () => (
		<Button
			variant="contained"
			color="secondary"
			style={{ width: '100%' }}
			onClick={() => {
				localStorage.removeItem('token');
				history.replace('/');
			}}>
			Logout!
		</Button>
	);

	const returnClickableUserImage = () => {
		if (token && token.image)
			return (
				<Image
					cursor
					width="50px"
					height="50px"
					onClick={(e) => setAnchorEl(e.currentTarget)}
					src={token.image}
				/>
			);
		else
			return (
				<AccountCircle
					style={{ width: '50px', height: '50px', cursor: 'pointer' }}
					onClick={(e) => setAnchorEl(e.currentTarget)}
				/>
			);
	};

	const returnBigUserImage = () => {
		if (token && token.image)
			return <Image width="100px" height="100px" src={token.image} cursor />;
		else
			return <AccountCircle style={{ width: '100px', height: '100px', cursor: 'pointer' }} />;
	};

	useEffect(() => {
		const token = getDecodedToken();

		if (token) {
			setToken(token);
		} else history.replace('/');
	}, []);

	return (
		<div>
			<AppBar
				position="sticky"
				className={classNames({
					[classes.appBarShift]: drawerOpen,
				})}>
				<Toolbar>
					<Grid container alignItems="center">
						<Grid item xs={6}>
							<IconButton
								color="inherit"
								aria-label="Menu"
								onClick={() =>
									drawerOpen ? handleDrawerClose() : handleDrawerOpen()
								}>
								<Menu />
							</IconButton>
							<Typography
								className={classes.thematicText}
								style={{ display: 'inline' }}
								variant="h6">
								Appointment System
							</Typography>
						</Grid>
						{returnUserImageAndPopover()}
					</Grid>
				</Toolbar>
				<Drawer
					className={classes.drawer}
					variant="persistent"
					anchor="left"
					open={drawerOpen}
					classes={{
						paper: classes.drawerPaper,
					}}>
					<div className={classes.drawerHeader}>
						<IconButton onClick={handleDrawerClose}>
							{theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
						</IconButton>
					</div>
					<Divider />
					<List>
						<ListItem button onClick={() => history.push('/client/me')}>
							<ListItemIcon>
								<AccountCircle />
							</ListItemIcon>
							<ListItemText secondary={'My Profile'} />
						</ListItem>
						<ListItem button onClick={() => history.push('/client/appointments')}>
							<ListItemIcon>
								<ListAlt />
							</ListItemIcon>
							<ListItemText secondary={'My Appointments'} />
						</ListItem>
						<ListItem button onClick={() => history.push('/client/my_linked_clients')}>
							<ListItemIcon>
								<Link />
							</ListItemIcon>
							<ListItemText secondary={'My Linked Clients'} />
						</ListItem>
					</List>
					<Divider />
					<List>
						<ListItem button onClick={() => history.push('/client/book_appointment')}>
							<ListItemIcon>
								<Book />
							</ListItemIcon>
							<ListItemText secondary={'Book an appointment'} />
						</ListItem>
					</List>
					<Divider />
				</Drawer>
			</AppBar>
			<main
				className={classNames(classes.content, {
					[classes.contentShift]: drawerOpen,
				})}>
				{children}
			</main>
		</div>
	);
};

export default withStyles(styles, { withTheme: true })(ClientHeader);
