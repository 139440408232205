import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import CommonOrganizationHeader from '../../Commons/OrganizationHeader';

const UserOrganizationHeader = inject(
	'uistore',
	'appointments',
)(
	observer(
		class UserOrganizationHeader extends Component {
			handleOrganizationChange = (e) => {
				const { uistore, includeService, appointments } = this.props;
				const {
					fetchServicesBySiteId,
					setSelectedSite,
					setSelectedOrganization,
					resetSite,
					resetService,
					sites,
					organizations,
					setServices,
					setFirstLoad,
				} = uistore;
				const { masterReset } = appointments;
				const selectedOrganization = organizations.filter(
					(item) => +item.id === +e.target.value,
				)[0];

				masterReset();
				setSelectedOrganization(selectedOrganization);

				const orgSites = sites.filter((site) => +site.organization_id === +e.target.value);
				if (orgSites.length > 0) {
					setSelectedSite(orgSites[0]);

					if (includeService)
						fetchServicesBySiteId(orgSites[0].id).then(() => {
							setFirstLoad(true);
						});
				} else {
					setServices([]);
					resetSite();
					resetService();
				}
			};

			handleSiteChange = (e) => {
				const { uistore, includeService } = this.props;
				const { setSelectedSite, sites, fetchServicesBySiteId, setFirstLoad } = uistore;
				const selectedSite = sites.filter((item) => +item.id === +e.target.value)[0];
				setSelectedSite(selectedSite);

				if (includeService)
					fetchServicesBySiteId(selectedSite.id).then(() => {
						setFirstLoad(true);
					});
			};

			handleServiceChange = (e) => {
				const { uistore } = this.props;
				const { setSelectedService, services, setFirstLoad } = uistore;
				const selectedService = services.filter((item) => +item.id === +e.target.value)[0];

				setSelectedService(selectedService);
				setFirstLoad(true);
			};

			render() {
				const { notEditable, includeService, uistore } = this.props;

				return (
					<CommonOrganizationHeader
						uistore={uistore}
						notEditable={notEditable}
						includeService={includeService}
						handleOrganizationChange={this.handleOrganizationChange}
						handleSiteChange={this.handleSiteChange}
						handleServiceChange={this.handleServiceChange}
					/>
				);
			}
		},
	),
);

export default UserOrganizationHeader;
