import React from 'react';

import esewaLoading from '../loading_esewa-new.gif';

export default class EsewaPaymentSuccess extends React.Component {
	componentDidMount() {
		const { match, location } = this.props;

		if (match && match.params && match.params.appointment_id && location.search) {
			const { appointment_id } = match.params;

			const urlParams = new URLSearchParams(location.search);

			const amt = urlParams.get('amt');
			const oid = urlParams.get('oid');
			const refId = urlParams.get('refId');

			if (amt && oid && refId) {
				const path = 'https://uat.esewa.com.np/epay/transrec';
				const params = {
					amt,
					rid: refId,
					pid: oid,
					scd: 'EPAYTEST',
				};

				const form = document.createElement('form');

				form.setAttribute('method', 'POST');
				form.setAttribute('action', path);

				for (let key in params) {
					const hiddenField = document.createElement('input');

					hiddenField.setAttribute('type', 'hidden');
					hiddenField.setAttribute('name', key);
					hiddenField.setAttribute('value', params[key]);

					form.appendChild(hiddenField);
				}

				document.body.appendChild(form);

				form.submit();
			}
		}
	}

	render() {
		return (
			<div
				style={{
					height: '100vh',
					width: '100vw',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}>
				<img src={esewaLoading} />
			</div>
		);
	}
}
