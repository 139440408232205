import React from 'react';
import {
	Button,
	FormControl,
	FormHelperText,
	Grid,
	TextField,
	IconButton,
	Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { AccountCircle, Clear } from '@material-ui/icons';
import Dropzone from 'react-dropzone';

import OutlinedSelect from '../../Commons/OutlinedSelect';

import { style } from './style';
import { observer, inject } from 'mobx-react';
import { autorun } from 'mobx';
import { ORIGIN } from '../../../constants/config';

class ClientDetailForm extends React.Component {
	dispose;

	componentDidMount() {
		this.dispose = autorun(() => {
			if (this.props.client) {
				this.props.clients.setNewClient(this.props.client);
			} else {
				this.props.clients.resetNewClient();
			}
		});
	}

	componentWillUnmount() {
		if (this.dispose) this.dispose();
	}

	handleChange = (e, property) => {
		this.props.clients.setNewClientProperty(property, e.target.value);
	};

	formSubmit = () => {
		const { formSubmit, clients } = this.props;
		const { newClient } = clients;

		const {
			first_name,
			second_name,
			last_name,
			age,
			mobile,
			password,
			email,
			gender,
			address,
			avatar,
			linkView,
			filename,
		} = newClient;

		let payload = {
			address,
			first_name,
			second_name,
			last_name,
			email,
			mobile,
			age,
			gender,
		};

		if (password && password !== '') {
			payload.password = password;
		}

		if (linkView) {
			payload.filename = filename;
		}

		formSubmit(payload, !linkView && avatar);
	};

	renderGender = () => {
		return [
			<option key={0} value={0}>
				Male
			</option>,
			<option key={1} value={1}>
				Female
			</option>,
			<option key={2} value={2}>
				Other
			</option>,
		];
	};

	onDrop = ([avatar]) => {
		this.props.clients.setNewClientProperty('avatar', avatar);
	};

	render() {
		const { clients, editable, discard, classes, hasActions, ignorePassword } = this.props;
		const { newClient, newClientError } = clients;

		const {
			first_name,
			second_name,
			last_name,
			age,
			mobile,
			password,
			confirm_password,
			email,
			gender,
			address,
			avatar,
			filename,
			linkView,
		} = newClient;

		return (
			<Grid
				container
				alignItems={'center'}
				justify={'center'}
				className={classes.mainContainer}>
				<Grid item md={10} xs={12} className={classes.secondaryContainer}>
					<Grid container justify={'center'}>
						<Grid
							item
							xs={6}
							style={{ justifyContent: 'center', padding: '20px', display: 'flex' }}>
							{editable ? (
								<Dropzone onDrop={this.onDrop} multiple={false} accept="image/*">
									{({ getRootProps, getInputProps }) => (
										<section>
											<div {...getRootProps()}>
												<input {...getInputProps()} />
												{!avatar ? (
													<Typography variant={'body1'}>
														Drag 'n' drop some files here, or click to
														select files
													</Typography>
												) : (
													<div>
														<Typography variant={'h4'}>
															Profile Picture
															<IconButton
																onClick={() => {
																	this.setState({ avatar: null });
																}}>
																<Clear />
															</IconButton>
														</Typography>
														<div>
															{
																<img
																	style={{
																		height: '200px',
																		width: '200px',
																		display: 'inline',
																		objectFit: 'cover',
																		borderRadius: '50%',
																		border: 'thin solid grey',
																	}}
																	src={
																		linkView
																			? `${ORIGIN}/uploads/client/${filename}`
																			: URL.createObjectURL(
																					avatar,
																			  )
																	}
																/>
															}
														</div>
													</div>
												)}
											</div>
										</section>
									)}
								</Dropzone>
							) : filename ? (
								<img
									style={{
										height: '200px',
										width: '200px',
										display: 'inline',
										objectFit: 'cover',
										borderRadius: '50%',
										border: 'thin solid grey',
									}}
									src={`${ORIGIN}/uploads/client/${filename}`}
								/>
							) : (
								<AccountCircle
									style={{
										height: '200px',
										width: '200px',
									}}
								/>
							)}
						</Grid>
					</Grid>
					<Grid
						container
						alignItems={'center'}
						justify={'center'}
						spacing={24}
						style={{ marginBottom: '20px' }}>
						<Grid item xs={4}>
							<FormControl fullWidth>
								<TextField
									aria-describedby="component-error-text"
									error={editable && !!newClientError.first_name}
									inputProps={{
										readOnly: !editable,
									}}
									disabled={!editable}
									value={first_name}
									fullWidth
									onChange={(e) => this.handleChange(e, 'first_name')}
									variant={'outlined'}
									label={'First Name'}
								/>
								<FormHelperText>{newClientError.first_name}</FormHelperText>
							</FormControl>
						</Grid>
						<Grid item xs={4}>
							<TextField
								inputProps={{
									readOnly: !editable,
								}}
								disabled={!editable}
								fullWidth
								value={second_name}
								onChange={(e) => this.handleChange(e, 'second_name')}
								variant={'outlined'}
								label={'Second Name'}
							/>
							<FormHelperText />
						</Grid>
						<Grid item xs={4}>
							<TextField
								error={editable && !!newClientError.last_name}
								inputProps={{
									readOnly: !editable,
								}}
								fullWidth
								disabled={!editable}
								value={last_name}
								onChange={(e) => this.handleChange(e, 'last_name')}
								variant={'outlined'}
								label={'Last Name'}
							/>
							<FormHelperText>{newClientError.last_name}</FormHelperText>
						</Grid>

						<Grid item xs={6}>
							<TextField
								inputProps={{
									readOnly: !editable,
								}}
								fullWidth
								disabled={!editable}
								value={email}
								onChange={(e) => this.handleChange(e, 'email')}
								variant={'outlined'}
								label={'Email'}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								inputProps={{
									readOnly: !editable,
									form: {
										autocomplete: 'off',
									},
								}}
								value={mobile}
								disabled={!editable}
								fullWidth
								onChange={(e) => this.handleChange(e, 'mobile')}
								variant={'outlined'}
								name={'mobile'}
								label={'Mobile No.'}
							/>
						</Grid>

						{!ignorePassword && editable ? (
							<>
								<Grid item xs={6}>
									<TextField
										inputProps={{
											readOnly: !editable,
											autoComplete: 'new-password',
										}}
										value={password}
										disabled={!editable}
										fullWidth
										variant={'outlined'}
										onChange={(e) => this.handleChange(e, 'password')}
										type={'password'}
										label={'Password'}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										inputProps={{
											readOnly: !editable,
										}}
										fullWidth
										disabled={!editable}
										value={confirm_password}
										onChange={(e) => this.handleChange(e, 'confirm_password')}
										variant={'outlined'}
										type={'password'}
										label={'Confirm Password'}
									/>
								</Grid>
							</>
						) : null}

						<Grid item xs={6}>
							<TextField
								inputProps={{
									type: 'number',
									readOnly: !editable,
								}}
								fullWidth
								disabled={!editable}
								value={age}
								onChange={(e) => this.handleChange(e, 'age')}
								variant={'outlined'}
								label={'Age'}
							/>
						</Grid>
						<Grid item xs={6}>
							<OutlinedSelect
								inputProps={{
									readOnly: !editable,
								}}
								disabled={!editable}
								handleChange={(e) => this.handleChange(e, 'gender')}
								value={gender}
								returnOptions={this.renderGender}
								labelText={'Gender'}
							/>
						</Grid>

						<Grid item xs={12}>
							<TextField
								inputProps={{
									readOnly: !editable,
								}}
								fullWidth
								disabled={!editable}
								value={address}
								onChange={(e) => this.handleChange(e, 'address')}
								variant={'outlined'}
								label={'Address'}
							/>
						</Grid>

						{editable && hasActions ? (
							<>
								<Grid item xs={6}>
									<Button
										className={classes.button}
										variant={'contained'}
										color={'primary'}
										onClick={this.formSubmit}>
										Save
									</Button>
								</Grid>
								<Grid item xs={6}>
									<Button
										className={classes.button}
										variant={'contained'}
										color={'secondary'}
										onClick={discard}>
										Discard
									</Button>
								</Grid>
							</>
						) : null}
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

export default withStyles(style)(inject('clients')(observer(ClientDetailForm)));
