import { action, decorate, observable } from 'mobx';

class ServiceStore {
	data = [];
	api;

	constructor(api) {
		this.api = api;
	}

	fetch = (site_id) => {
		return this.api
			.fetch(site_id)
			.then((res) => {
				if (res.status === 200 && !res.error) {
					this.setData(res.services);
					return Promise.resolve(res);
				}
			})
			.catch((e) => {
				console.log(e);
			});
	};

	setData = (data) => {
		this.data = data;
	};

	create = (payload) => {
		return this.api.post(payload).then((res) => {
			this.data.push(res);
		});
	};

	remove = (payload) => {
		return this.api.del(payload).then((res) => {
			this.data = this.data.filter((item) => item.id !== payload.id);
		});
	};

	update = (payload) => {
		return this.api.put(payload).then((res) => {
			if (res === 1) {
				this.data = this.data.filter((item) => item.id !== payload.id);
				this.data.push({ ...payload });
			}
		});
	};

	getByID = (id) => {
		return this.data.filter((item) => +item.id === +id)[0];
	};

	masterReset = () => {
		this.data = [];
	};
}

export default decorate(ServiceStore, {
	data: observable,
	setData: action,
	fetch: action,
	create: action,
	remove: action,
	update: action,
	masterReset: action,
});
