import React from 'react';

import esewaLoading from '../loading_esewa-new.gif';

export default class EsewaPaymentFailure extends React.Component {
	componentDidMount() {
		this.props.history.push('/client/appointments');
	}

	render() {
		return (
			<div
				style={{
					height: '100vh',
					width: '100vw',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}>
				<img src={esewaLoading} />
			</div>
		);
	}
}
