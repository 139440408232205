import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { autorun } from 'mobx';
import KhaltiCheckout from 'khalti-checkout-web';
import qs from 'qs';

import { Edit } from '@material-ui/icons';
import {
	CircularProgress,
	Grid,
	IconButton,
	TableRow,
	Tooltip,
	Typography,
	withStyles,
} from '@material-ui/core';

import { getDecodedToken } from '../../../libs/token';
import { verify } from '../../../api/api';
import {
	BASE_URL,
	esewaBASE_URL,
	KhaltiPaymentPreferences,
	KhaltiPublicKey,
} from '../../../constants/config';

import MaterialUITable from '../../Commons/CustomTable';

import { CustomTableCell } from '../../Commons/CustomTable/CustomTableCell';
import ClientOrganizationHeader from '../ClientOrganizationHeader';

import { style } from './style';

import KhaltiPaymentButton from '../../KhaltiPaymentButton';
import EsewaPaymentButton from '../../EsewaPaymentButton';

const Index = inject(
	'appointments',
	'uistore',
	'publicstore',
)(
	observer(
		class ClientAppointments extends Component {
			dispose;
			timeout;

			state = {
				loading: true,
				query: '',
			};

			setLoading = (loading) => {
				this.setState({
					loading,
				});
			};

			async componentDidMount() {
				const { uistore, appointments, history, publicstore, location } = this.props;
				const queryParams = qs.parse(this.props.location.search, {
					ignoreQueryPrefix: true,
				});

				uistore.masterReset();
				appointments.masterReset();

				this.setLoading(true);

				this.decodedToken = getDecodedToken();
				if (this.decodedToken) {
					publicstore.fetchOrganizations(queryParams.organization_id).then((org_res) => {
						if (
							org_res &&
							+org_res.status === 200 &&
							org_res.organizations &&
							org_res.organizations.length
						) {
							const defaultOrganization = org_res.organizations.find(
								(org) => +org.id === +queryParams.organization_id,
							);

							publicstore
								.fetchSitesByOrganizationId({
									organization_id: defaultOrganization
										? queryParams.organization_id
										: org_res.organizations[0].id,
									defaultSiteId: queryParams.site_id,
								})
								.then((site_res) => {
									if (
										site_res &&
										+site_res.status === 200 &&
										site_res.sites &&
										site_res.sites.length
									) {
										const defaultSite = site_res.sites.find(
											(site) => +site.id === +queryParams.site_id,
										);

										publicstore
											.fetchServicesBySiteId({
												site_id: defaultSite
													? queryParams.site_id
													: site_res.sites[0].id,
												defaultServiceId: queryParams.service_id,
											})
											.then((service_res) => {
												this.dispose = autorun(() => {
													this.setLoading(true);

													if (
														this.props.uistore.selectedService.id !==
															-1 &&
														this.props.uistore.selectedService &&
														this.props.uistore.selectedService.id
													)
														publicstore
															.fetchMyAppointmentsByServiceId(
																this.props.uistore.selectedService
																	.id,
															)
															.then(() => {
																this.setLoading(false);
															});
													else if (
														this.props.uistore.services.length === 1
													) {
														appointments.masterReset();
														this.setLoading(false);
													} else if (
														this.props.uistore.selectedSite &&
														this.props.uistore.selectedSite.id
													)
														publicstore
															.fetchMyAppointmentsBySiteId(
																this.props.uistore.selectedSite.id,
															)
															.then(() => {
																this.setLoading(false);
															});
													else this.setLoading(false);
												});
											});
									} else {
										this.setLoading(false);
									}
								});
						} else {
							this.setLoading(false);
						}
					});
				} else {
					history.replace('/');
				}
			}

			componentWillUnmount() {
				if (this.dispose) this.dispose();
			}

			handleEditClick = (item) => {
				const { history, appointments } = this.props;
				const { setSelected } = appointments;

				setSelected(item);
				history.push('/client/edit_appointment');
			};

			handleDeleteClick = ({ id, client, user, start_time }) => {
				const { appointments } = this.props;

				if (
					window.confirm(
						`Are you sure you want to delete ${client.first_name}'s appointment with ${
							user.title && typeof user.title === 'undefined' ? user.title : ''
						} ${user.first_name} on ${new Date(start_time).toLocaleString()}?`,
					)
				) {
					this.setState({
						loading: true,
					});
					appointments.remove(id).then(() => {
						this.setState({
							loading: false,
						});
					});
				}
			};

			returnTableHeaders = () => {
				let arr = [];

				if (this.props.uistore.selectedService.id === -1)
					arr.push(<CustomTableCell key={9}>Service Name</CustomTableCell>);

				arr = [
					...arr,
					<CustomTableCell key={0}>Appointment Code</CustomTableCell>,
					<CustomTableCell key={12}>Appointment For</CustomTableCell>,
					<CustomTableCell key={5}>Appointment With</CustomTableCell>,
					<CustomTableCell key={6}>Date</CustomTableCell>,
					<CustomTableCell key={7}>Time</CustomTableCell>,
					<CustomTableCell key={11}>Status</CustomTableCell>,
					<CustomTableCell key={8}>Actions</CustomTableCell>,
					<CustomTableCell key={10} style={{ textAlign: 'center' }}>
						Payment
					</CustomTableCell>,
				];

				return arr;
			};

			returnUserName = (user) => {
				if (user) {
					const { first_name, second_name, last_name, title } = user;

					return `${title && typeof title !== 'undefined' ? title : ''} ${first_name} ${
						second_name && typeof second_name !== 'undefined' ? second_name : ''
					} ${last_name}`;
				} else {
					return '--';
				}
			};

			handleEsewaPayClick = (appointment) => {
				const { user, id } = appointment;

				var path = `${esewaBASE_URL}/epay/main`;
				var params = {
					amt: +user.price,
					psc: 0,
					pdc: 0,
					txAmt: 0,
					tAmt: +user.price,
					pid: 'ee2c3ca1-696b-4cc5-a6be-2c40d929d453',
					scd: 'EPAYTEST',
					su: `${window.location.origin}/app/#/esewa_payment_success/${id}`,
					fu: `${window.location.origin}/app/#/esewa_payment_failure/${id}`,
					// su: `http://localhost:3000/#/esewa_payment_success/${id}`,
					// fu: `http://localhost:3000/#/esewa_payment_failure/${id}`
				};

				const form = document.createElement('form');

				form.setAttribute('method', 'POST');
				form.setAttribute('action', path);

				for (let key in params) {
					const hiddenField = document.createElement('input');

					hiddenField.setAttribute('type', 'hidden');
					hiddenField.setAttribute('name', key);
					hiddenField.setAttribute('value', params[key]);

					form.appendChild(hiddenField);
				}

				document.body.appendChild(form);
				form.submit();
			};

			handleKhaltiPayClick = (appointment) => {
				const { user, id } = appointment;

				const config = {
					publicKey: KhaltiPublicKey,
					productIdentity: '1234567890',
					productName: 'Drogon',
					productUrl: 'http://gameofthrones.com/buy/Dragons',
					eventHandler: {
						onSuccess: (payload) => {
							// hit merchant api for initiating verfication
							console.log(payload);

							const { token, amount } = payload;

							verify
								.verifyKhalti({ token, amount, appointment_id: id })
								.then((res) => {
									if (res && res.message) {
										alert(res.message);

										if (res.message === 'Payment Successful!')
											this.props.publicstore
												.fetchMyAppointmentsBySiteId(
													this.props.uistore.selectedSite.id,
												)
												.then(() => {
													this.setLoading(false);
												});
									}

									console.log(res);
								});
						},
						// onError handler is optional
						onError(error) {
							// handle errors
							console.log(error);
						},
					},
					// one can set the order of payment options and also the payment options based on the order and items in the array
					paymentPreference: KhaltiPaymentPreferences,
				};

				const checkout = new KhaltiCheckout(config);

				checkout.show({ amount: +user.price * 100 });
			};

			returnTableRows = (data) => {
				const { classes, uistore } = this.props;
				return data.map((item, index) => {
					const {
						appointment_code,
						user,
						client,
						start_time,
						end_time,
						service,
						status,
						id,
					} = item;

					return (
						<TableRow className={classes.row} key={index}>
							{uistore.selectedService.id === -1 && (
								<CustomTableCell>
									{service ? service.service_name : ''}
								</CustomTableCell>
							)}
							<CustomTableCell>{appointment_code}</CustomTableCell>
							<CustomTableCell>{this.returnUserName(client)}</CustomTableCell>
							<CustomTableCell>{this.returnUserName(user)}</CustomTableCell>
							<CustomTableCell>{new Date(start_time).toDateString()}</CustomTableCell>
							<CustomTableCell>
								{new Date(start_time).toLocaleTimeString([], {
									hour: '2-digit',
									minute: '2-digit',
								})}{' '}
								-{' '}
								{new Date(end_time).toLocaleTimeString([], {
									hour: '2-digit',
									minute: '2-digit',
								})}
							</CustomTableCell>
							<CustomTableCell>{status.description}</CustomTableCell>
							<CustomTableCell
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
								}}>
								<Tooltip title={'Edit'}>
									<IconButton onClick={() => this.handleEditClick(item)}>
										<Edit />
									</IconButton>
								</Tooltip>
								{/*<Tooltip title={"Delete"}><IconButton onClick={() => this.handleDeleteClick(item)}><Delete/></IconButton></Tooltip>*/}
							</CustomTableCell>
							<CustomTableCell style={{ textAlign: 'center' }}>
								{status.active &&
								status.description !== 'Paid' &&
								user &&
								user.price &&
								user.price !== '' &&
								user.price !== '0' ? (
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'space-around',
										}}>
										<KhaltiPaymentButton
											item={item}
											handleClick={this.handleKhaltiPayClick}
										/>
										<div>
											<span>or</span>
										</div>
										<EsewaPaymentButton
											item={item}
											handleClick={this.handleEsewaPayClick}
										/>
									</div>
								) : (
									<span> -- </span>
								)}
							</CustomTableCell>
						</TableRow>
					);
				});
			};

			render() {
				const { classes, appointments } = this.props;
				const { data } = appointments;
				const { loading } = this.state;

				if (loading)
					return (
						<Grid className={classes.fullContainer} container>
							<Grid item xs={12} className={classes.loadingContainer}>
								<Typography variant={'h4'}>Processing . . .</Typography>
								<CircularProgress />
							</Grid>
						</Grid>
					);
				else
					return (
						<Grid container className={classes.mainContainer}>
							<Grid item xs={12} className={classes.paperContainer}>
								<ClientOrganizationHeader includeService />
								<Grid
									container
									alignItems={'center'}
									className={classes.titleContainer}>
									<Grid item xs={12}>
										<Typography variant={'h5'} className={classes.title}>
											<b>My Appointments:</b>
										</Typography>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									{data.length > 0 ? (
										<MaterialUITable
											rows={this.returnTableRows(data)}
											headers={this.returnTableHeaders()}
										/>
									) : (
										'No Appointments found!'
									)}
								</Grid>
							</Grid>
						</Grid>
					);
			}
		},
	),
);

export default withStyles(style)(Index);
