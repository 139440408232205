import { emphasize } from '@material-ui/core/styles/colorManipulator';

export const style = (theme) => ({
	mainContainer: {
		padding: '20px',
		textAlign: 'center',
		height: 'calc( 100vh - 64px )',
		overflow: 'auto',
		maxHeight: '100%',
		width: 'calc( 100vw - 242px )',
	},
	loadingContainer: {
		height: '100%',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
	},
	paddedContainer: {
		padding: '20px',
	},
	titleContainer: {
		textAlign: 'center',
	},
	infoContainer: {
		border: '1px solid lightgrey',
	},
	detailContainer: {
		wordWrap: 'break-word',
	},
	buttonContainer: {
		margin: 20,
	},
	root: {
		flexGrow: 1,
		height: 250,
	},
	input: {
		display: 'flex',
		padding: 0,
	},
	valueContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1,
		alignItems: 'center',
		overflow: 'hidden',
	},
	chip: {
		margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
	},
	chipFocused: {
		backgroundColor: emphasize(
			theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
			0.08,
		),
	},
	noOptionsMessage: {
		padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
	},
	singleValue: {
		fontSize: 16,
	},
	placeholder: {
		position: 'absolute',
		left: 2,
		fontSize: 16,
	},
	paper: {
		position: 'absolute',
		zIndex: 1,
		marginTop: theme.spacing.unit,
		left: 0,
		right: 0,
	},
	divider: {
		height: theme.spacing.unit * 2,
	},
});
