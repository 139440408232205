export const style = (theme) => ({
	mainContainer: {
		padding: 20,
	},
	noteContainer: {
		padding: 30,
		border: '1px solid lightgrey',
		borderRadius: '5px',
		marginBottom: '20px',
		marginTop: '20px',
		width: '100%',
	},
});
