export const style = (theme) => ({
	titleContainer: {
		border: 'solid 1px lightgrey',
		padding: '20px',
		minHeight: '148px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
});
