import React from 'react';
import PropTypes from 'prop-types';
import {
	Paper,
	Table,
	TableBody,
	TableFooter,
	TableHead,
	TableRow,
	withStyles,
	Zoom,
} from '@material-ui/core';
import { observer } from 'mobx-react/index';

import { styles } from './style';

const MaterialUITablePaginated = observer(
	class MaterialUITablePaginated extends React.Component {
		render() {
			const { classes, headers, rows, renderFooter } = this.props;

			return (
				<Paper className={classes.root} elevation={5}>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>{headers}</TableRow>
						</TableHead>
						<Zoom in>
							<TableBody>{rows}</TableBody>
						</Zoom>
						<TableFooter>{renderFooter()}</TableFooter>
					</Table>
				</Paper>
			);
		}
	},
);

MaterialUITablePaginated.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MaterialUITablePaginated);
