import React from 'react';

export const Image = (props) => {
	const { onClick, src, height, width, cursor } = props;

	return (
		<img
			style={{
				width,
				height,
				borderRadius: '50%',
				borderWidth: '1px',
				borderColor: 'black',
				borderStyle: 'solid',
				cursor: cursor ? 'pointer' : 'default',
			}}
			onClick={onClick}
			src={src}
			alt={'profile'}
		/>
	);
};
