export const toLocaleStringOptions = {
	year: 'numeric',
	month: 'numeric',
	day: 'numeric',
	hour: 'numeric',
	minute: 'numeric',
};

export const filterOperationTimesByWeekDay = (OperationTime, date) =>
	OperationTime.filter((item) => item.week_day === date.getDay());

export const findAppointmentsByStartTime = (appointments, start) =>
	appointments.find(
		(item) =>
			new Date(item.start_time).toLocaleString('en-US', toLocaleStringOptions) ===
			start.toLocaleString('en-US', toLocaleStringOptions),
	);

export const addSeconds = function (date, seconds) {
	const newDate = new Date(date);

	newDate.setSeconds(newDate.getSeconds() + seconds);

	return newDate;
};

export const addDays = function (date, days) {
	const newDate = new Date(date);

	newDate.setDate(newDate.getDate() + days);

	return newDate;
};

export const getStartOfDay = function (date) {
	const newDate = new Date(date);

	newDate.setHours(0, 0, 0);

	return newDate;
};

export const getEndOfDay = function (date) {
	const newDate = new Date(date);

	newDate.setHours(23, 59, 59);

	return newDate;
};
