import React from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Stepper,
	Step,
	StepLabel,
} from '@material-ui/core';
import { observer, inject } from 'mobx-react';

import AppointmentClientSelection from '../AppointmentClientSelection';
import PaymentSelection from '../../PaymentSelection';

const steps = ['Who is this appointment for?', 'Make payments now or pay later!'];

export default inject('clients')(
	observer(
		class ClientAppointmentConfirmation extends React.Component {
			state = {
				activeStep: 0,
			};

			setActiveStep = (activeStep) => {
				this.setState({
					activeStep,
				});
			};

			render() {
				const {
					handleClose,
					open,
					formSubmit,
					selectedServiceUser,
					history,
					clients,
				} = this.props;
				const { activeStep } = this.state;

				return (
					<Dialog
						maxWidth={'lg'}
						fullWidth
						onClose={handleClose}
						aria-labelledby="customized-dialog-title"
						open={open}>
						<DialogTitle id="customized-dialog-title" onClose={handleClose}>
							Appointment Confirmation
						</DialogTitle>
						<DialogContent>
							{activeStep === 0 ? (
								<AppointmentClientSelection formSubmit={formSubmit} />
							) : null}
							{activeStep === 1 && selectedServiceUser && selectedServiceUser.id ? (
								<PaymentSelection history={history} formSubmit={formSubmit} />
							) : null}
							<Stepper activeStep={activeStep} alternativeLabel>
								{selectedServiceUser && selectedServiceUser.id ? (
									steps.map((label) => (
										<Step key={label}>
											<StepLabel>{label}</StepLabel>
										</Step>
									))
								) : (
									<Step key={steps[0]}>
										<StepLabel>{steps[0]}</StepLabel>
									</Step>
								)}
							</Stepper>
						</DialogContent>
						<DialogActions>
							{activeStep > 0 ? (
								<Button
									color="primary"
									variant={'outlined'}
									onClick={() => {
										this.setActiveStep(activeStep - 1);
									}}>
									Back
								</Button>
							) : null}
							<Button
								color="primary"
								variant={'outlined'}
								onClick={() => {
									if (
										activeStep === steps.length - 1 ||
										!selectedServiceUser ||
										!selectedServiceUser.id
									) {
										formSubmit();
									} else {
										if (clients.appointmentFor === 'someone_linked') {
											this.setActiveStep(activeStep + 1);
										} else {
											if (clients.validateNewClient()) {
												this.setActiveStep(activeStep + 1);
											}
										}
									}
								}}>
								{selectedServiceUser && selectedServiceUser.id
									? activeStep === steps.length - 1
										? 'Pay Later'
										: 'Next'
									: 'Done'}
							</Button>
							<Button color="primary" variant={'outlined'} onClick={handleClose}>
								Discard
							</Button>
						</DialogActions>
					</Dialog>
				);
			}
		},
	),
);
