import React, { useState } from 'react';
import classNames from 'classnames';
import { Button, Grid, InputAdornment, TextField, withStyles } from '@material-ui/core';
import {
	AccountCircle,
	DoneOutline,
	Visibility,
	VisibilityOff,
	VpnKeyOutlined,
} from '@material-ui/icons';

import { submitOnEnterPress } from '../../../constants/functions';
import { styles } from './style';

const Login = (props) => {
	const [passwordShown, setPasswordShown] = useState(false);
	const { classes, email, password, submitClick, handleChange } = props;

	return (
		<Grid
			container
			alignItems={'center'}
			justify={'center'}
			className={classes.loginFormContainer}>
			<Grid item xs={10}>
				<TextField
					InputLabelProps={{ shrink: true }}
					onChange={handleChange}
					onKeyDown={(e) => submitOnEnterPress(e, submitClick)}
					className={classNames(classes.margin, classes.textField)}
					value={email}
					required
					variant="outlined"
					name={'email'}
					label="Email"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<AccountCircle />
							</InputAdornment>
						),
					}}
				/>
			</Grid>
			<Grid item xs={10}>
				<TextField
					onChange={handleChange}
					onKeyDown={(e) => submitOnEnterPress(e, submitClick)}
					InputLabelProps={{ shrink: true }}
					className={classNames(classes.margin, classes.textField)}
					type={passwordShown ? 'text' : 'password'}
					variant="outlined"
					value={password}
					required
					name={'password'}
					label="Password"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<VpnKeyOutlined />
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment
								position="end"
								className={classes.password}
								onClick={() => setPasswordShown(!passwordShown)}>
								{passwordShown ? <VisibilityOff /> : <Visibility />}
							</InputAdornment>
						),
					}}
				/>
			</Grid>
			<Grid item xs={10}>
				<Button
					onClick={submitClick}
					variant="contained"
					color="primary"
					className={classes.button}>
					<DoneOutline className={classes.leftIcon} />
					Login
				</Button>
			</Grid>
		</Grid>
	);
};

export default withStyles(styles)(Login);
