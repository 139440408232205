import React, { PureComponent } from 'react';

import { auth } from '../../../api/api';
import { version } from '../../../../package.json';

import './Login.css';
import LoginCore from '../../Commons/Login';
import { styles } from './style';

import { Grid, withStyles } from '@material-ui/core';

class UserLogin extends PureComponent {
	state = {
		email: '',
		password: '',
	};

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	submitClick = () => {
		const { email, password } = this.state;
		const { history } = this.props;

		if (email === '' || password === '') {
			alert('Some required fields are empty!');
		} else {
			let payload = { email, password };

			auth.userLogin(payload)
				.then((res) => {
					if (res.token) {
						localStorage.setItem('token', res.token);
						history.push('/user');
					} else if (res.error) {
						alert(res.error);
					}
				})
				.catch((e) => {
					console.log(e);

					if (e.error) {
						if (e.error.details && e.error.details.length > 0)
							alert(e.error.details[0].message);
						else if (e.error.message) alert(e.error.message);
						else alert('ERROR');
					} else {
						alert('ERROR');
					}
				});
		}
	};

	render() {
		const { classes } = this.props;
		const { email, password } = this.state;

		return (
			<Grid
				container
				alignItems={'center'}
				justify={'center'}
				className={classes.mainContainer}>
				<Grid item xs={12} md={4} className={classes.paperContainer}>
					<Grid container alignItems={'center'} justify={'center'}>
						<Grid item xs={12} className={classes.title}>
							Appointment System
						</Grid>
					</Grid>
					<Grid
						container
						alignItems={'center'}
						justify={'center'}
						className={classes.secondaryContainer}>
						<Grid item xs={12}>
							<LoginCore
								email={email}
								password={password}
								handleChange={this.handleChange}
								submitClick={this.submitClick}
							/>
							<Grid item xs={12} className={classes.versionContainer}>
								<em>v. {version}</em>
							</Grid>
							<Grid item xs={12} className={classes.developerContainer}>
								Developed by{' '}
								<em>
									<b>Real Time Solutions Pvt. Ltd.</b>
								</em>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

export default withStyles(styles)(UserLogin);
