import { TableCell, withStyles } from '@material-ui/core';

export const CustomTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.grey[200],
		color: theme.palette.common.black,
		fontWeight: 'bold',
		fontSize: 14,
		position: 'sticky',
		top: 0,
		zIndex: 10,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);
