import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, CircularProgress, Grid, Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import OutlinedSelect from '../../Commons/OutlinedSelect';
import ClientOrganizationHeader from '../ClientOrganizationHeader';
import CalendarComponent from '../../Commons/Calendar';

import {
	returnAppointmentSourceOptions,
	returnResourcePersonOptions,
	returnServiceOptions,
} from '../../../constants/functions';

import { getDecodedToken } from '../../../libs/token';

import {
	addDays,
	addSeconds,
	filterOperationTimesByWeekDay,
	findAppointmentsByStartTime,
	getStartOfDay,
	getEndOfDay,
	toLocaleStringOptions,
} from '../../../utils';

import { style } from './style';

const ClientEditAppointment = inject(
	'appointments',
	'appointmentlogs',
	'userappointment',
	'services',
	'serviceusers',
	'uistore',
	'publicstore',
)(
	observer(
		class EditAppointment extends React.Component {
			dispose;
			decodedToken = {};

			state = {
				appointment_sources: [],
				appointments: [],
				loading: false,
			};

			async componentDidMount() {
				const { history } = this.props;

				this.decodedToken = getDecodedToken();

				if (this.decodedToken) {
					if (Object.keys(this.props.appointments.selected).length === 0) {
						this.props.history.push('/client/appointments');
					} else {
						try {
							const {
								appointments,
								userappointment,
								uistore,
								publicstore,
							} = this.props;
							const { setParam } = userappointment;
							const { selectedSite } = uistore;

							const appointment_source_res = await publicstore.fetchAppointmentSources();

							if (
								appointment_source_res &&
								appointment_source_res.appointment_sources
							) {
								const { appointment_sources } = appointment_source_res;

								this.setState({
									appointment_sources,
								});

								if (appointment_sources.length > 0) {
									const selectedAppointmentSource = appointment_sources.filter(
										(filter_item) =>
											+filter_item.id ===
											+appointments.selected.appointment_source_id,
									);

									if (selectedAppointmentSource.length > 0)
										userappointment.setParam(
											'selectedAppointmentSource',
											selectedAppointmentSource[0].id,
										);
								}
							}

							await publicstore.fetchServicesBySiteId({ site_id: selectedSite.id });
							const selectedService = uistore.selectedService;
							setParam('selectedService', selectedService);

							const res = await publicstore.fetchServiceUsersByServiceId(
								selectedService.id,
							);

							let selectedServiceUser = { id: 0 };

							if (appointments.selected.user) {
								selectedServiceUser = res.users.find(
									(filter_item) =>
										+filter_item.user_id === +appointments.selected.user.id,
								);
							}

							setParam('selectedServiceUser', selectedServiceUser);
							setParam('selectedDate', new Date(appointments.selected.start_time));

							this.checkAppointmentsAndPushIntervals();
						} catch (e) {
							console.log(e);
						}
					}
				} else {
					history.replace('/');
				}
			}

			componentWillUnmount() {
				if (this.dispose) this.dispose();

				const {
					appointments,
					appointmentlogs,
					userappointment,
					services,
					serviceusers,
					uistore,
				} = this.props;

				appointments.masterReset();
				appointmentlogs.masterReset();
				userappointment.masterReset();
				services.masterReset();
				serviceusers.masterReset();
				uistore.masterReset();
			}

			assignInitialUser = (service) => {
				const { publicstore, userappointment } = this.props;
				const { setParam } = userappointment;

				publicstore.fetchServiceUsersByServiceId(service.id).then((serviceusers_res) => {
					if (serviceusers_res.users && serviceusers_res.users.length > 0)
						setParam('selectedServiceUser', serviceusers_res.users[0]);

					this.checkAppointmentsAndPushIntervals();
				});
			};

			checkAppointmentsAndPushIntervals = async () => {
				this.setState({
					loading: true,
				});

				const { appointments, userappointment, publicstore } = this.props;
				const { setParam, selectedService, selectedServiceUser } = userappointment;
				const { Operationtime } =
					selectedServiceUser.id === 0 ? selectedService : selectedServiceUser;
				const { selectedDate } = userappointment;

				try {
					await publicstore.fetchServiceUsersByServiceId(selectedService.id);
				} catch (e) {
					console.log(e);
				}

				let optime;

				if (
					selectedDate.toLocaleDateString() ===
					new Date(appointments.selected.start_time).toLocaleDateString()
				) {
					optime = Operationtime.filter(
						(item) =>
							item.week_day === new Date(appointments.selected.start_time).getDay(),
					);
				} else {
					optime = Operationtime.filter(
						(item) => item.week_day === new Date(selectedDate).getDay(),
					);
				}

				setParam('selectedOperationTimeObj', optime);

				try {
					const appointment_res =
						selectedServiceUser.id === 0
							? await publicstore.fetchUserlessAppointmentsByServiceId(
									selectedService.id,
									getStartOfDay(new Date(selectedDate)).toISOString(),
									getEndOfDay(new Date(selectedDate)).toISOString(),
							  )
							: await publicstore.fetchAppointmentsByUserId(
									selectedServiceUser.user_id,
									getStartOfDay(new Date(selectedDate)).toISOString(),
									getEndOfDay(new Date(selectedDate)).toISOString(),
							  );

					if (appointment_res && appointment_res.appointments) {
						this.setState({
							appointments: appointment_res.appointments,
						});

						if (
							selectedDate.toLocaleDateString() ===
							this.props.appointments.selected.start_time
						) {
							let date = new Date();

							if (optime.length === 0) {
								let iterations = 0;
								while (optime.length === 0) {
									if (iterations >= 7) break;

									date = addDays(date, 1);

									optime = filterOperationTimesByWeekDay(Operationtime, date);
									optime.sort((a, b) => {
										if (a.start_time < b.start_time) return -1;
										else if (a.start_time > b.start_time) return 1;
										else return 0;
									});

									iterations++;
								}
							}

							if (optime.length !== 0) {
								setParam('selectedDate', date);
								this.parseIntervalsAndPush(
									optime,
									selectedService.service_period,
									appointment_res.appointments,
								);
							} else
								this.setState({
									loading: false,
								});
						} else
							this.parseIntervalsAndPush(
								optime,
								selectedService.service_period,
								appointment_res.appointments,
							);
					}
				} catch (e) {
					console.log(e);
				}
			};

			checkIfUnavailable = (start_time, available) => {
				const { classes, appointments } = this.props;

				const availableClassnames = classNames(classes.opTime, classes.available);
				const unavailableClassnames = classNames(classes.opTime, classes.unavailable);

				if (
					new Date(appointments.selected.start_time).toLocaleString(
						'en-US',
						toLocaleStringOptions,
					) === start_time.toLocaleString('en-US', toLocaleStringOptions)
				)
					return availableClassnames;
				else if (available) {
					return availableClassnames;
				} else {
					return unavailableClassnames;
				}
			};

			parseIntervalsAndPush = (optimeArr, duration, appointments) => {
				const { userappointment } = this.props;
				const { selectedDate, pushToIntervals, resetIntervals, setParam } = userappointment;

				resetIntervals();

				typeof optimeArr !== 'undefined' && optimeArr.length > 0
					? optimeArr.forEach(({ start_time, end_time }) => {
							let start = new Date(start_time);
							let end = new Date(end_time);

							start.setFullYear(selectedDate.getFullYear());
							start.setMonth(selectedDate.getMonth());
							start.setDate(selectedDate.getDate());

							end.setFullYear(selectedDate.getFullYear());
							end.setMonth(selectedDate.getMonth());
							end.setDate(selectedDate.getDate());

							if (
								selectedDate.toLocaleDateString() ===
								new Date(
									this.props.appointments.selected.start_time,
								).toLocaleDateString()
							) {
								if (
									this.props.appointments.selected &&
									typeof this.props.appointments.selected !== 'undefined'
								)
									setParam('selectedInterval', {
										start_time: new Date(
											this.props.appointments.selected.start_time,
										),
										end_time: addSeconds(
											new Date(this.props.appointments.selected.start_time),
											duration,
										),
										available: true,
									});
							}

							while (start < end) {
								if (
									appointments &&
									appointments.length > 0 &&
									typeof appointments !== 'undefined'
								) {
									let existingAppointment = findAppointmentsByStartTime(
										appointments,
										start,
									);

									if (typeof existingAppointment === 'undefined') {
										pushToIntervals({
											start_time: start,
											end_time: addSeconds(start, duration),
											available: true,
										});
									} else {
										pushToIntervals({
											start_time: start,
											end_time: addSeconds(start, duration),
											available:
												selectedDate.toLocaleDateString() ===
												new Date(
													this.props.appointments.selected.start_time,
												).toLocaleDateString()
													? new Date(
															this.props.appointments.selected.start_time,
													  ).toLocaleString(
															'en-US',
															toLocaleStringOptions,
													  ) ===
													  new Date(start).toLocaleString(
															'en-US',
															toLocaleStringOptions,
													  )
													: false,
										});
									}
								} else {
									pushToIntervals({
										start_time: start,
										end_time: addSeconds(start, duration),
										available: true,
									});
								}

								start = addSeconds(start, duration);
							}

							this.setState({
								loading: false,
							});
					  })
					: this.setState({
							loading: false,
					  });
			};

			handleSelectedDateChange = (date) => {
				const dateClone = new Date(date);
				const { userappointment, uistore, appointments } = this.props;
				const {
					setParam,
					selectedServiceUser,
					changeIntervalsToSelectedDate,
					selectedService,
				} = userappointment;
				const { selectedSite } = uistore;

				setParam('selectedInterval', {
					start_time: new Date(appointments.selected.start_time),
					end_time: addSeconds(
						new Date(appointments.selected.start_time),
						selectedService.service_period,
					),
					available: true,
				});
				changeIntervalsToSelectedDate(this.state.appointments);

				setParam('selectedDate', dateClone);
				const { Operationtime } =
					selectedServiceUser.id === 0 ? selectedService : selectedServiceUser;
				const OperationTimeObj = Operationtime.filter(
					(item) => item.week_day === dateClone.getDay(),
				);

				setParam('selectedOperationTimeObj', OperationTimeObj);
				this.checkAppointmentsAndPushIntervals(selectedSite.id);
			};

			shouldDisableDate = (date) => {
				const { Operationtime } =
					this.props.userappointment.selectedServiceUser.id === 0
						? this.props.userappointment.selectedService
						: this.props.userappointment.selectedServiceUser;
				const dateClone = new Date(date);

				let operationTimeObj = Operationtime.filter(
					(item) => item.week_day === dateClone.getDay(),
				);

				return !operationTimeObj.length > 0;
			};

			handleServiceChange = (e) => {
				const { userappointment, services } = this.props;
				const { setParam } = userappointment;
				const selectedService = services.getByID(e.target.value);

				setParam('selectedService', selectedService);
				this.assignInitialUser(selectedService);
			};

			handleServiceUserChange = (e) => {
				const { serviceusers, userappointment, uistore } = this.props;
				const { selectedSite } = uistore;
				const { setParam } = userappointment;
				let selectedServiceUser = serviceusers.getByID(e.target.value);

				if (!selectedServiceUser) {
					selectedServiceUser = { id: 0 };
				}

				setParam('selectedServiceUser', selectedServiceUser);
				this.checkAppointmentsAndPushIntervals(selectedSite.id);
			};

			returnGender = (gender) => {
				switch (gender) {
					case 0:
						return 'Male';
					case 1:
						return 'Female';
					default:
						return 'Other';
				}
			};

			returnOperationTimes = (intervals, selectedInterval) => {
				const { userappointment, classes } = this.props;
				const { setParam } = userappointment;
				const selectedClassnames = classNames(classes.opTime, classes.selected);

				return intervals.map((item, index) => {
					const { start_time, end_time, available } = item;
					let itemIsSelected;

					if (Object.keys(selectedInterval).length > 0)
						itemIsSelected =
							selectedInterval.start_time.toLocaleString(
								'en-US',
								toLocaleStringOptions,
							) === start_time.toLocaleString('en-US', toLocaleStringOptions) &&
							selectedInterval.end_time.toLocaleString(
								'en-US',
								toLocaleStringOptions,
							) === end_time.toLocaleString('en-US', toLocaleStringOptions);
					else itemIsSelected = false;

					return (
						<Grid
							key={`optime-${index}`}
							onClick={() => {
								if (available) setParam('selectedInterval', item);
							}}
							item
							md={3}
							xs={12}
							className={
								itemIsSelected
									? selectedClassnames
									: this.checkIfUnavailable(start_time, available)
							}>
							<Typography variant={'body2'} style={{ color: 'inherit' }}>
								{start_time.toLocaleTimeString([], {
									hour: '2-digit',
									minute: '2-digit',
								})}{' '}
								-{' '}
								{end_time.toLocaleTimeString([], {
									hour: '2-digit',
									minute: '2-digit',
								})}
							</Typography>
						</Grid>
					);
				});
			};

			formSubmit = async () => {
				const { userappointment, publicstore, history, uistore, appointments } = this.props;
				const { selected } = appointments;
				const { selectedSite } = uistore;
				const {
					selectedInterval,
					selectedService,
					selectedServiceUser,
					selectedAppointmentSource,
				} = userappointment;
				const { start_time, end_time } = selectedInterval;

				let payload = {
					start_time: start_time.toISOString(),
					end_time: end_time.toISOString(),
					service_id: selectedService.id,
					user_id: selectedServiceUser.id === 0 ? null : selectedServiceUser.user_id,
					appointment_source_id: selectedAppointmentSource,
					site_id: selectedSite.id,
				};

				this.setState({
					loading: true,
				});

				try {
					const res = await publicstore.updateAppointment(selected.id, payload);
					this.setState({
						loading: false,
					});

					if (res.status && !res.status.error) {
						history.push('/client/appointments');
					} else {
						console.log(res);
						alert('An error occurred!');
					}
				} catch (e) {
					console.log(e);
					alert(e);
					this.setState({
						loading: false,
					});
				}
			};

			render() {
				const {
					classes,
					history,
					appointments,
					services,
					serviceusers,
					userappointment,
				} = this.props;
				const {
					selectedAppointmentSource,
					selectedService,
					selectedServiceUser,
					selectedDate,
					selectedInterval,
					intervals,
				} = userappointment;
				const { selected } = appointments;
				const { loading } = this.state;

				if (Object.keys(selected).length === 0) {
					return <div>Redirecting...</div>;
				} else if (loading)
					return (
						<Grid className={classes.mainContainer} container>
							<Grid item xs={12} className={classes.loadingContainer}>
								<Typography variant={'h4'}>Processing . . .</Typography>
								<CircularProgress />
							</Grid>
						</Grid>
					);
				else
					return (
						<Grid container alignItems={'center'} justify={'center'}>
							<Grid item xs={12}>
								<ClientOrganizationHeader notEditable />
								<Grid container className={classes.row} justify={'center'}>
									<Grid item md={6} xs={12} className={classes.inputContainer}>
										<OutlinedSelect
											handleChange={this.handleServiceChange}
											value={selectedService.id}
											name={'service'}
											returnOptions={() =>
												returnServiceOptions(services.data)
											}
											labelText={'Service'}
										/>
									</Grid>
									<Grid item md={6} xs={12} className={classes.inputContainer}>
										<OutlinedSelect
											notNative
											handleChange={this.handleServiceUserChange}
											value={selectedServiceUser.id}
											name={'resource_person'}
											returnOptions={() =>
												returnResourcePersonOptions(serviceusers.data)
											}
											labelText={'Resource Person'}
										/>
									</Grid>
									<Grid item xs={12} md={6} className={classes.inputContainer}>
										<OutlinedSelect
											handleChange={(e) => {
												const { setParam } = this.props.userappointment;
												setParam(
													'selectedAppointmentSource',
													+e.target.value,
												);
											}}
											name={'appointment_source'}
											value={selectedAppointmentSource}
											returnOptions={() =>
												returnAppointmentSourceOptions(
													this.state.appointment_sources,
												)
											}
											labelText={'Appointment Source'}
										/>
									</Grid>
								</Grid>
								{this.state.loading ? (
									<Grid container justify={'center'}>
										<Grid item xs={12} className={classes.row}>
											<CircularProgress />
											<Typography variant={'body1'}>Loading . . .</Typography>
										</Grid>
									</Grid>
								) : intervals.length === 0 ? (
									<Grid container justify={'center'}>
										<Grid item xs={4} className={classes.row}>
											<Typography>
												{selectedServiceUser &&
												selectedServiceUser.user &&
												selectedServiceUser.user.first_name
													? `${selectedServiceUser.user.first_name}'s working hours have not been configured!`
													: 'No Working Hours!'}
											</Typography>
										</Grid>
									</Grid>
								) : (
									<Grid container justify={'center'}>
										<Grid item md={'auto'} xs={12} className={classes.row}>
											<Grid item sm={'auto'}>
												<CalendarComponent
													value={selectedDate}
													disablePast
													shouldDisableDate={this.shouldDisableDate}
													handleDateChange={this.handleSelectedDateChange}
												/>
											</Grid>
										</Grid>
										<Grid item xs={12} className={classes.row}>
											<Grid container>
												{this.returnOperationTimes(
													intervals,
													selectedInterval,
												)}
											</Grid>
										</Grid>
									</Grid>
								)}
								<Grid
									container
									alignItems={'center'}
									justify={'center'}
									className={classes.row}>
									<Grid item md={3} xs={12}>
										<Button
											color="primary"
											onClick={this.formSubmit}
											variant={'contained'}
											className={classes.button}>
											Save
										</Button>
									</Grid>
									<Grid item md={3} xs={12}>
										<Button
											onClick={() => history.push('/client/appointments')}
											color="secondary"
											variant={'contained'}
											className={classes.button}>
											Cancel
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					);
			}
		},
	),
);

export default withStyles(style)(ClientEditAppointment);
