import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import OutlinedSelect from '../OutlinedSelect';
import {
	returnOrganizationOptions,
	returnServiceOptions,
	returnSiteOptions,
} from '../../../constants/functions';
import { toJS } from 'mobx';
import { style } from './style';

class CommonOrganizationHeader extends Component {
	render() {
		const {
			classes,
			uistore,
			notEditable,
			includeService,
			handleOrganizationChange,
			handleSiteChange,
			handleServiceChange,
		} = this.props;
		const {
			selectedSite,
			selectedOrganization,
			selectedService,
			sites,
			organizations,
			services,
		} = uistore;

		return (
			<Grid container alignItems={'center'} justify={'center'}>
				<Grid item xs={12} md={includeService ? 4 : 6} className={classes.titleContainer}>
					{organizations && organizations.length > 1 && !notEditable ? (
						<OutlinedSelect
							handleChange={handleOrganizationChange}
							name={'organization'}
							value={selectedOrganization ? selectedOrganization.id : 0}
							returnOptions={() => returnOrganizationOptions(toJS(organizations))}
							labelText={'Organization'}
						/>
					) : (
						<Typography variant={'h4'}>
							{selectedOrganization && selectedOrganization.name}
						</Typography>
					)}
				</Grid>
				<Grid item xs={12} md={includeService ? 4 : 6} className={classes.titleContainer}>
					{sites && sites.length > 1 && !notEditable ? (
						<OutlinedSelect
							handleChange={handleSiteChange}
							name={'site'}
							value={selectedSite ? selectedSite.id : 0}
							returnOptions={() => returnSiteOptions(toJS(sites))}
							labelText={'Site'}
						/>
					) : (
						<Typography variant={'h4'}>
							{selectedSite && selectedSite.site_name}
						</Typography>
					)}
				</Grid>
				{includeService && (
					<Grid
						item
						xs={12}
						md={includeService ? 4 : 6}
						className={classes.titleContainer}>
						{services && services.length > 1 && !notEditable ? (
							<OutlinedSelect
								handleChange={handleServiceChange}
								name={'service'}
								value={selectedService ? selectedService.id : 0}
								returnOptions={() => returnServiceOptions(toJS(services))}
								labelText={'Service'}
							/>
						) : services.length === 1 ? (
							<Typography variant={'h4'}>
								{selectedService && selectedService.service_name}
							</Typography>
						) : (
							<Typography variant={'h4'}>No Service Available</Typography>
						)}
					</Grid>
				)}
			</Grid>
		);
	}
}

export default withStyles(style)(observer(CommonOrganizationHeader));
