import React from 'react';
import { Checkbox, FormControlLabel, withStyles } from '@material-ui/core';

import { style } from './style';

class CustomCheckbox extends React.Component {
	render() {
		const { classes, checked, onChange, label } = this.props;

		return (
			<FormControlLabel
				control={
					<Checkbox
						checked={checked}
						onChange={onChange}
						classes={{
							root: classes.checkboxRoot,
							checked: classes.checked,
						}}
					/>
				}
				label={label}
			/>
		);
	}
}

export default withStyles(style)(CustomCheckbox);
