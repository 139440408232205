import React, { Component } from 'react';

import { auth } from '../../../api/api';

import ClientDetailForm from '../ClientDetailForm';

class ClientRegister extends Component {
	formSubmit = (payload) => {
		const { history } = this.props;

		auth.clientRegister(payload).then((res) => {
			if (res.status === 200) history.push('/');
		});
	};

	discard = () => {
		this.props.history.push('/login');
	};

	render() {
		return (
			<div style={{ height: '100vh' }}>
				<ClientDetailForm
					hasActions
					editable
					discard={this.discard}
					formSubmit={this.formSubmit}
				/>
			</div>
		);
	}
}

export default ClientRegister;
