import { action, decorate, observable } from 'mobx';

class AppointmentLogStore {
	data = [];
	api;

	constructor(api) {
		this.api = api;
	}

	fetchByAppointmentId = (appointment_id) => {
		return this.api.fetchByAppointmentId(appointment_id).then((res) => {
			if (res.status === 200)
				this.data = res.appointmentlogs.sort((a, b) => {
					if (a.log_date < b.log_date) return 1;
					else if (a.log_date > b.log_date) return -1;
					else return 0;
				});

			return Promise.resolve(res);
		});
	};

	create = (payload, token) => {
		return this.api.post(payload).then((res) => {
			const { _id, _email } = token;

			res.appointmentlog.user = {
				id: _id,
				email: _email,
			};

			if (res.status === 200) {
				this.data = [...this.data, res.appointmentlog].sort((a, b) => {
					if (a.log_date < b.log_date) return 1;
					else if (a.log_date > b.log_date) return -1;
					else return 0;
				});
			}

			return Promise.resolve(res);
		});
	};

	remove = (id) => {
		return this.api.del(id).then((res) => {
			if (res.status === 200) this.data = this.data.filter((item) => item.id !== id);
			else console.log(res);
		});
	};

	update = (payload, id) => {
		return this.api.put(payload, id).then((res) => {
			if (res.status === 200) {
				let obj = this.data.filter((item) => +item.id === +id)[0];

				Object.keys(payload).forEach((key) => {
					obj[key] = payload[key];
				});
			}

			return Promise.resolve(res);
		});
	};

	masterReset = () => {
		this.data = [];
	};
}

export default decorate(AppointmentLogStore, {
	data: observable,
	selected: observable,
	fetchByAppointmentId: action,
	create: action,
	remove: action,
	update: action,
	setSelected: action,
	fetchBySiteId: action,
	masterReset: action,
});
