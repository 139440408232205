import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { autorun } from 'mobx';

import { Edit, Search } from '@material-ui/icons';
import {
	CircularProgress,
	Grid,
	IconButton,
	InputAdornment,
	TablePagination,
	TableRow,
	TextField,
	Tooltip,
	Typography,
	withStyles,
} from '@material-ui/core';

import { getDecodedToken } from '../../../libs/token';

import MaterialUITablePaginated from '../../Commons/Table/MaterialUITablePaginated';
import TablePaginationActionsWrapped from '../../Commons/Table/PaginatedTableActions';

import { CustomTableCell } from '../../Commons/CustomTable/CustomTableCell';
import UserOrganizationHeader from '../UserOrganizationHeader';

import { style } from './style';
import { site } from '../../../api/api';

const UserAppointments = inject(
	'appointments',
	'uistore',
)(
	observer(
		class Appointments extends Component {
			dispose;
			timeout;

			state = {
				loading: false,
				query: '',
			};

			async componentDidMount() {
				const { uistore, appointments, history } = this.props;

				uistore.masterReset();
				appointments.masterReset();

				const {
					setSites,
					setOrganizations,
					setSelectedSite,
					setSelectedOrganization,
					fetchServicesBySiteId,
				} = uistore;

				this.decodedToken = getDecodedToken();
				if (this.decodedToken) {
					const { _sites, _orgs } = this.decodedToken;

					let availableOrganizations = [];
					let availableSites = [];

					if (typeof _sites !== 'undefined' && _sites.length > 0) {
						_sites.forEach((site) => {
							const existingAvailableOrganization = availableOrganizations.filter(
								(item) => +item.id === +site.organization_id,
							)[0];

							if (typeof existingAvailableOrganization === 'undefined')
								availableOrganizations.push(site.organization);

							availableSites.push(site);
						});
					}

					if (typeof _orgs !== 'undefined' && _orgs.length > 0) {
						availableOrganizations = [...availableOrganizations, ..._orgs];

						for (const organization of _orgs) {
							try {
								const res = await site.fetchByOrganizationId(organization.id);

								if (res && res.sites)
									availableSites = [...availableSites, ...res.sites];
							} catch (e) {
								console.log(e);
							}
						}
					}

					setSites(availableSites);
					setOrganizations(availableOrganizations);

					if (availableSites && availableSites[0]) {
						setSelectedSite(availableSites[0]);
						setSelectedOrganization(availableSites[0].organization);

						fetchServicesBySiteId(availableSites[0].id).then(() => {
							this.dispose = autorun(() => {
								const {
									query,
									firstLoad,
									setFirstLoad,
									clientEmailCheckbox,
									clientNameCheckbox,
									clientMobileCheckbox,
									clientAddressCheckbox,
									userEmailCheckbox,
									userNameCheckbox,
									searchStartDate,
									searchEndDate,
									selectedService,
									selectedSite,
								} = uistore;

								const { page, rowsPerPage } = appointments;

								if (uistore.services.length > 0) {
									let obj = {
										query,
										start_date: searchStartDate,
										end_date: searchEndDate,
										service_id: selectedService.id,
										site_id: selectedSite.id,
										page,
										limit: rowsPerPage,
									};

									obj.client = `${clientEmailCheckbox ? 'email,' : ''}${
										clientNameCheckbox ? 'name,' : ''
									}${clientMobileCheckbox ? 'mobile,' : ''}${
										clientAddressCheckbox ? 'address,' : ''
									}`;
									obj.user = `${userEmailCheckbox ? 'email,' : ''}${
										userNameCheckbox ? 'name,' : ''
									}`;

									if (firstLoad) {
										appointments.callSearch(obj);
										setFirstLoad(false);
									} else if (query !== '') appointments.callSearch(obj);
								} else {
									appointments.resetData();
								}
							});
						});
					}
				} else {
					history.replace('/');
				}
			}

			componentWillUnmount() {
				if (this.dispose) this.dispose();
			}

			handleEditClick = (item) => {
				const { history, appointments } = this.props;
				const { setSelected } = appointments;

				setSelected(item);
				history.push('/user/edit_appointment');
			};

			handleDeleteClick = ({ id, client, user, start_time }) => {
				const { appointments } = this.props;

				if (
					window.confirm(
						`Are you sure you want to delete ${client.first_name}'s appointment with ${
							user.title && typeof user.title === 'undefined' ? user.title : ''
						} ${user.first_name} on ${new Date(start_time).toLocaleString()}?`,
					)
				) {
					this.setState({
						loading: true,
					});
					appointments.remove(id).then(() => {
						this.setState({
							loading: false,
						});
					});
				}
			};

			returnTableHeaders = () => {
				let arr = [];

				if (this.props.uistore.selectedService.id === -1)
					arr.push(<CustomTableCell key={9}>Service Name</CustomTableCell>);

				arr = [
					...arr,
					<CustomTableCell key={0}>Appointment Code</CustomTableCell>,
					<CustomTableCell key={1}>Client Email</CustomTableCell>,
					<CustomTableCell key={2}>Client Name</CustomTableCell>,
					<CustomTableCell key={3}>Client Mobile No</CustomTableCell>,
					<CustomTableCell key={4}>Client Address</CustomTableCell>,
					<CustomTableCell key={5}>Appointment With</CustomTableCell>,
					<CustomTableCell key={6}>Date</CustomTableCell>,
					<CustomTableCell key={7}>Time</CustomTableCell>,
					<CustomTableCell key={8}>Actions</CustomTableCell>,
				];

				return arr;
			};

			returnClientName = ({ first_name, second_name, last_name }) => {
				return `${first_name} ${
					second_name && typeof second_name !== 'undefined' ? second_name : ''
				} ${last_name}`;
			};

			returnUserName = (user) => {
				if (user) {
					const { first_name, second_name, last_name, title } = user;

					return `${title && typeof title !== 'undefined' ? title : ''} ${
						first_name && first_name
					} ${second_name && typeof second_name !== 'undefined' ? second_name : ''} ${
						last_name && last_name
					}`;
				} else {
					return '--';
				}
			};

			returnTableRows = (data) => {
				const { classes, uistore } = this.props;
				return data.map((item, index) => {
					const { appointment_code, client, user, start_time, end_time, service } = item;

					return (
						<TableRow className={classes.row} key={index}>
							{uistore.selectedService.id === -1 && (
								<CustomTableCell>
									{service ? service.service_name : ''}
								</CustomTableCell>
							)}
							<CustomTableCell>{appointment_code}</CustomTableCell>
							<CustomTableCell>
								{client
									? client.email != null && client.email !== ''
										? client.email
										: '--'
									: ''}
							</CustomTableCell>
							<CustomTableCell>{this.returnClientName(client)}</CustomTableCell>
							<CustomTableCell>{client ? client.mobile : ''}</CustomTableCell>
							<CustomTableCell>{client ? client.address : ''}</CustomTableCell>
							<CustomTableCell>{this.returnUserName(user)}</CustomTableCell>
							<CustomTableCell>{new Date(start_time).toDateString()}</CustomTableCell>
							<CustomTableCell>
								{new Date(start_time).toLocaleTimeString([], {
									hour: '2-digit',
									minute: '2-digit',
								})}{' '}
								-{' '}
								{new Date(end_time).toLocaleTimeString([], {
									hour: '2-digit',
									minute: '2-digit',
								})}
							</CustomTableCell>
							<CustomTableCell>
								<Tooltip title={'Edit'}>
									<IconButton onClick={() => this.handleEditClick(item)}>
										<Edit />
									</IconButton>
								</Tooltip>
								{/*<Tooltip title={"Delete"}><IconButton onClick={() => this.handleDeleteClick(item)}><Delete/></IconButton></Tooltip>*/}
							</CustomTableCell>
						</TableRow>
					);
				});
			};

			renderFooter = () => {
				const {
					rowsPerPage,
					page,
					handlePageChange,
					handleRowsPerPageChange,
					count,
				} = this.props.appointments;

				return (
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[6, 18, 30]}
							colSpan={9}
							count={count}
							rowsPerPage={rowsPerPage}
							page={page}
							SelectProps={{
								native: true,
							}}
							onChangePage={(e, page) => {
								handlePageChange(e, page);
								this.props.uistore.setFirstLoad(true);
							}}
							onChangeRowsPerPage={(e) => {
								handleRowsPerPageChange(e);
								this.props.uistore.setFirstLoad(true);
							}}
							ActionsComponent={TablePaginationActionsWrapped}
						/>
					</TableRow>
				);
			};

			render() {
				const { classes, appointments, uistore } = this.props;
				const { handleQueryChange } = uistore;
				const {
					data,
					page,
					rowsPerPage,
					count,
					handlePageChange,
					handleRowsPerPageChange,
				} = appointments;
				const { loading } = this.state;

				if (loading)
					return (
						<Grid className={classes.fullContainer} container>
							<Grid item xs={12} className={classes.loadingContainer}>
								<Typography variant={'h4'}>Processing . . .</Typography>
								<CircularProgress />
							</Grid>
						</Grid>
					);
				else
					return (
						<Grid container className={classes.mainContainer}>
							<Grid item xs={12} className={classes.paperContainer}>
								<UserOrganizationHeader includeService />
								<Grid
									container
									alignItems={'center'}
									className={classes.titleContainer}>
									<Grid item xs={4}>
										<Typography variant={'h6'} className={classes.title}>
											Registered Appointments:
										</Typography>
									</Grid>
									<Grid item xs={8} className={classes.rightAlignedContainer}>
										<TextField
											variant={'outlined'}
											className={classes.textField}
											label={'Search'}
											onChange={(e) => {
												this.props.appointments.handlePageChange(e, 0);
												const value = e.target.value;

												if (this.timeout != null)
													clearTimeout(this.timeout);

												this.timeout = setTimeout(() => {
													handleQueryChange(value);
												}, 800);
											}}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<Search />
													</InputAdornment>
												),
											}}
										/>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									{data.length > 0 ? (
										<MaterialUITablePaginated
											rows={this.returnTableRows(data)}
											page={page}
											handlePageChange={(e, page) => {
												handlePageChange(e, page);
												appointments.setFirstLoad(true);
											}}
											rowsPerPage={rowsPerPage}
											handleRowsPerPageChange={(e) => {
												handleRowsPerPageChange(e);
												this.props.uistore.setFirstLoad(true);
											}}
											count={count}
											renderFooter={this.renderFooter}
											headers={this.returnTableHeaders()}
										/>
									) : (
										'No UserAppointments found!'
									)}
								</Grid>
							</Grid>
						</Grid>
					);
			}
		},
	),
);

export default withStyles(style)(UserAppointments);
