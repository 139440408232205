import { del, get, post, put, sendUnauthenticatedRequest, putWithFile, postWithFile } from './http';
import { BASE_URL } from '../constants/config';

export const auth = {
	facebookAdminLogin: (payload) =>
		sendUnauthenticatedRequest(`${BASE_URL}/facebook_admin_login`, payload),
	userLogin: (payload) => sendUnauthenticatedRequest(`${BASE_URL}/auth/user/login`, payload),
	clientLogin: (payload) => sendUnauthenticatedRequest(`${BASE_URL}/auth/client/login`, payload),
	clientRegister: (payload) =>
		sendUnauthenticatedRequest(`${BASE_URL}/auth/client/register`, payload),
	clientLoginFacebook: (payload) =>
		sendUnauthenticatedRequest(`${BASE_URL}/auth/client/facebook/login`, payload),
	clientLoginGoogle: (payload) =>
		sendUnauthenticatedRequest(`${BASE_URL}/auth/client/google/login`, payload),
};

export const appointment = {
	fetchByUserId: (user_id, start_date, end_date) =>
		get(
			`${BASE_URL}/appointment/user/${user_id}?start_date=${start_date}&end_date=${end_date}`,
		),
	fetchUserlessAppointmentsByServiceId: (service_id, start_date, end_date) =>
		get(
			`${BASE_URL}/appointment/service_userless/${service_id}?start_date=${start_date}&end_date=${end_date}`,
		),
	fetchBySiteId: (site_id) => get(`${BASE_URL}/appointment/site/${site_id}`),
	fetchByServiceId: (service_id) => get(`${BASE_URL}/appointment/service/${service_id}`),
	search: ({ site_id, service_id, query, client, user, start_date, end_date, page, limit }) =>
		get(
			`${BASE_URL}/appointment/search?page=${+page + 1}&limit=${limit}&site_id=${site_id}${
				service_id && service_id > 0 ? `&service_id=${service_id}` : ''
			}&start_date=${new Date(start_date).toISOString()}&end_date=${new Date(
				end_date,
			).toISOString()}${query && query !== '' ? `&query=${query}` : ''}${
				client && client !== '' ? `&client=${client}` : ''
			}${user && user !== '' ? `&user=${user}` : ''}`,
		),
	put: (payload, id) => put(`${BASE_URL}/appointment/${id}`, payload),
	post: (payload) => post(`${BASE_URL}/appointment`, payload),
	del: (appointment_id) => del(`${BASE_URL}/appointment/${appointment_id}`),
};

export const appointmentsource = {
	fetch: () => get(`${BASE_URL}/appointmentsource`),
};

export const appointmentlog = {
	fetchByAppointmentId: (appointment_id) => get(`${BASE_URL}/appointmentlog/${appointment_id}`),
	put: (payload, id) => put(`${BASE_URL}/appointmentlog/${id}`, payload),
	post: (payload) => post(`${BASE_URL}/appointmentlog`, payload),
	del: (appointment_id) => del(`${BASE_URL}/appointmentlog/${appointment_id}`),
};

export const client = {
	fetch: () => get(`${BASE_URL}/client`),
	search: (query) => get(`${BASE_URL}/client/search?query=${query}`),
	post: (payload) => post(`${BASE_URL}/client`, payload),
	del: () => del(`${BASE_URL}/client`),
};

export const organization = {
	fetch: () => get(`${BASE_URL}/organization`),
};

export const pub = {
	fetchMyClientDetails: () => get(`${BASE_URL}/public/me`),
	fetchMyLinkedClients: () => get(`${BASE_URL}/public/my_linked_clients`),
	fetchCalendarBySiteId: (site_id) => get(`${BASE_URL}/public/site/calendar/${site_id}`),
	fetchCalendarByUserId: (user_id) => get(`${BASE_URL}/public/user/calendar/${user_id}`),
	fetchOrganizations: () => get(`${BASE_URL}/public/org`),
	fetchSitesByOrganizationId: (org_id) => get(`${BASE_URL}/public/site/${org_id}`),
	fetchServicesBySiteId: (site_id) => get(`${BASE_URL}/public/service/${site_id}`),
	fetchStatusesBySiteId: (site_id) => get(`${BASE_URL}/public/status/${site_id}`),
	fetchAppointmentSources: () => get(`${BASE_URL}/public/appointment_source`),
	fetchServiceUsersByServiceId: (service_id) =>
		get(`${BASE_URL}/public/service_user/${service_id}`),
	fetchAppointmentsByUserId: (user_id, start_date, end_date) =>
		get(
			`${BASE_URL}/public/appointment/user/${user_id}?start_date=${start_date}&end_date=${end_date}`,
		),
	fetchUserlessAppointmentsByServiceId: (service_id, start_date, end_date) =>
		get(
			`${BASE_URL}/public/appointment/userless/${service_id}?start_date=${start_date}&end_date=${end_date}`,
		),
	fetchMyAppointmentsByServiceId: (service_id) =>
		get(`${BASE_URL}/public/appointment/service/${service_id}`),
	fetchMyAppointmentsBySiteId: (site_id) => get(`${BASE_URL}/public/appointment/site/${site_id}`),
	postAppointment: (payload) => post(`${BASE_URL}/public/appointment`, payload),
	updateAppointment: (id, payload) => put(`${BASE_URL}/public/appointment/${id}`, payload),
	updateMyClientDetails: (payload, file) => putWithFile(`${BASE_URL}/public/me`, payload, file),
	deleteAppointment: (id) => del(`${BASE_URL}/public/appointment/${id}`),
};

export const service = {
	fetch: (site_id) => get(`${BASE_URL}/service/${site_id}`),
};

export const serviceuser = {
	fetch: (service_id) => get(`${BASE_URL}/serviceuser/${service_id}`),
};

export const site = {
	fetch: () => get(`${BASE_URL}/site`),
	fetchByOrganizationId: (id) => get(`${BASE_URL}/site/org/${id}`),
};

export const status = {
	fetch: (site_id) => get(`${BASE_URL}/status/${site_id}`),
};

export const verify = {
	verifyKhalti: (payload) => post(`${BASE_URL}/verify/khalti`, payload),
};
