import React from 'react';
import { Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { style } from './style';

class OperationTime extends React.Component {
	interval;

	state = {
		now: new Date(),
	};

	componentDidMount() {
		this.interval = setInterval(() => {
			this.setState({
				now: new Date(),
			});
		}, 30000);
	}

	componentWillUnmount() {
		if (this.interval) clearInterval(this.interval);
	}

	checkIfPastOrUnavailable = (start_time, available) => {
		const { now } = this.state;

		if (available) {
			return now < start_time;
		} else {
			return false;
		}
	};

	returnOperationTimes = () => {
		const { formSubmit, setParam, intervals, selectedInterval, classes } = this.props;
		const selectedClassnames = classNames(classes.opTime, classes.selected);

		const availableClassnames = classNames(classes.opTime, classes.available);
		const unavailableClassnames = classNames(classes.opTime, classes.unavailable);

		return intervals.map((item, index) => {
			const { start_time, end_time, available } = item;
			const notPastOrUnavailable = this.checkIfPastOrUnavailable(start_time, available);

			return (
				<Tooltip
					title={`${
						notPastOrUnavailable
							? 'Click to register appointment'
							: 'Appointment unavailable'
					}`}
					key={`optime-${index}`}>
					<Grid
						onClick={() => {
							if (new Date() < start_time && available) {
								setParam('selectedInterval', item);
								setTimeout(() => formSubmit && formSubmit(), 400);
							}
						}}
						item
						md={3}
						xs={12}
						className={
							selectedInterval === item
								? selectedClassnames
								: notPastOrUnavailable
								? availableClassnames
								: unavailableClassnames
						}>
						<Typography variant={'body2'} style={{ color: 'inherit' }}>
							{start_time.toLocaleTimeString([], {
								hour: '2-digit',
								minute: '2-digit',
							})}{' '}
							-{' '}
							{end_time.toLocaleTimeString([], {
								hour: '2-digit',
								minute: '2-digit',
							})}
						</Typography>
					</Grid>
				</Tooltip>
			);
		});
	};

	render() {
		const { classes } = this.props;
		return (
			<Grid container alignItems={'center'} className={classes.row}>
				{this.returnOperationTimes()}
			</Grid>
		);
	}
}

export default withStyles(style)(observer(OperationTime));
