import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { ErrorOutlined } from '@material-ui/icons';
import { Link } from 'react-router-dom';

import { Redirect, Route, Router } from './reactRouter';
import { loggedIn } from './libs/auth';

import AdminLogin from './components/AdminLogin';
import ClientLogin from './components/Client/ClientLogin';
import UserLogin from './components/User/UserLogin';
import ClientRegister from './components/Client/ClientRegister';

import UserAppointments from './components/User/UserAppointments';
import UserClientSelect from './components/User/UserClientSelect';
import UserEditAppointment from './components/User/UserEditAppointment';
import UserAppointmentBooking from './components/User/UserAppointmentBooking';
import UserHeader from './components/User/UserHeader';

import ClientAppointmentBooking from './components/Client/ClientAppointmentBooking';
import ClientAppointments from './components/Client/ClientAppointments';
import ClientHeader from './components/Client/ClientHeader';
import ClientEditAppointment from './components/Client/ClientEditAppointment';
import ClientProfile from './components/Client/ClientProfile';
import ClientChildren from './components/Client/ClientChildren';

import EsewaPaymentSuccess from './components/EsewaPaymentSuccess';
import EsewaPaymentFailure from './components/EsewaPaymentFailure';

const style = {
	mainContainer: {
		width: '100vw',
		height: '100vh',
		textAlign: 'center',
	},
};

// const Loading = withStyles(style)((props) => {
//     return (
//         <Grid className={props.classes.mainContainer} container justify={"center"} alignItems={"center"}
//               alignContent={"center"}>
//             <Grid item xs={12}>
//                 <div>
//                     <CircularProgress/>
//                 </div>
//                 <div>
//                     Loading . . .
//                 </div>
//             </Grid>
//         </Grid>
//     );
// });

const requireAuth = () => {
	if (!loggedIn()) {
		returnRedirectToLogin();
	}
};

const NoMatch = withStyles(style)(({ location, classes }) => (
	<Grid
		className={classes.mainContainer}
		container
		justify={'center'}
		alignItems={'center'}
		alignContent={'center'}>
		<Grid item xs={12}>
			<div>
				<ErrorOutlined style={{ color: 'red', width: '500px', height: '500px' }} />
			</div>
			<div>
				<h3>
					No match for <code>{location.pathname}</code>
				</h3>
			</div>
			<Link to={'/'}>Go to Home</Link>
		</Grid>
	</Grid>
));

const returnRedirectToLogin = () => {
	return <Redirect to="/login/client" />;
};

const Routes = () => (
	<Router>
		<Route
			path={'/'}
			exact
			component={returnRedirectToLogin}
			indexRedirectTo={'login/client'}
		/>
		<Route path={'/login'} exact component={returnRedirectToLogin} />

		<Route path={'/login/client'} component={ClientLogin} />
		<Route path={'/register'} component={ClientRegister} />

		<Route path={'/login/user'} component={UserLogin} />
		<Route path={'/admin/login'} component={AdminLogin} />

		<Route path={'/client'} component={ClientHeader} indexRedirectTo="/book_appointment">
			<Route path={'/client/book_appointment'} component={ClientAppointmentBooking} />
			<Route path={'/client/appointments'} component={ClientAppointments} />
			<Route path="/client/edit_appointment" component={ClientEditAppointment} />
			<Route path="/client/me" component={ClientProfile} />
			<Route path="/client/my_linked_clients" component={ClientChildren} />
		</Route>

		<Route
			path="/user"
			component={UserHeader}
			onEnter={requireAuth}
			indexRedirectTo="/book_appointment">
			{/* <Route path='/user/home' component={HomePage}/> */}
			<Route path="/user/book_appointment" component={UserAppointmentBooking} />
			<Route path="/user/client_information" component={UserClientSelect} />
			<Route path="/user/appointments" component={UserAppointments} />
			<Route path="/user/edit_appointment" component={UserEditAppointment} />
		</Route>

		<Route path={'/esewa_payment_success/:appointment_id'} component={EsewaPaymentSuccess} />
		<Route path={'/esewa_payment_failure/:appointment_id'} component={EsewaPaymentFailure} />

		<Route component={NoMatch} />
	</Router>
);

export default Routes;
