const loginHeader = {
	Accept: 'application/json',
	'Content-Type': 'application/json',
};

async function handleErrors(response) {
	response = response.clone();

	if (!response.ok) {
		const json = await response.json();

		if (json.error) {
			if (json.error === 'Invalid token') {
				delete localStorage.token;
				window.location.reload();
			}
			throw Error(json.error);
		}
	}

	return response;
}

const sendRequest = (url, method, body) => {
	const commonHeaders = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		Authorization: `bearer${localStorage.token}`,
	};

	let options = {
		method: method,
		headers: commonHeaders,
	};

	if (body) {
		options.body = JSON.stringify(body);
	}

	return fetch(url, options)
		.then(handleErrors)
		.then((res) => {
			return res.json();
		})
		.catch((err) => {
			console.log(err);
			return Promise.reject(err);
		});
};

export const sendUnauthenticatedRequest = (url, payload) => {
	return fetch(url, {
		method: 'POST',
		body: JSON.stringify(payload),
		headers: loginHeader,
	})
		.then(handleErrors)
		.then((res) => res.json())
		.catch((err) => console.log(err));
};

const callApiWithFile = (endpoint, method, body, file) => {
	const fullUrl = endpoint;
	const authToken = window.localStorage.token;
	const commonOpts = {
		method,
		headers: {
			// Accept: 'application/json',
			// 'Content-Type': 'application/json',
			Authorization: `bearer${authToken}`,
		},
	};

	let opts;

	const data = new FormData();

	if (body) {
		Object.keys(body).forEach((key) => {
			data.append(key, body[key]);
		});
	}

	if (file) {
		data.append('avatar', file);
	}

	opts = body ? Object.assign(commonOpts, { body: data }) : commonOpts;

	return fetch(fullUrl, opts)
		.then((response) => response.json().then((json) => ({ json, response })))
		.then(({ json, response }) => {
			if (!response.ok) {
				const { status } = response;
				const error = {
					...json,
					status,
				};

				// if (status === 401) {
				// 	delete window.localStorage.token;
				// 	window.location.reload();
				// }

				return Promise.reject(error);
			} else {
				return json;
			}
		});
	// .then(
	// response => (response),
	// error => ({ error: {
	// status: error.status,
	// message: error.message || 'Something bad happened',
	// } })
	// );
};

export const get = (url) => sendRequest(url, 'GET');
export const post = (url, payload) => sendRequest(url, 'POST', payload);
export const put = (url, payload) => sendRequest(url, 'PUT', payload);
export const del = (url) => sendRequest(url, 'DELETE');

export const postWithFile = (url, payload, file) => callApiWithFile(url, 'POST', payload, file);
export const putWithFile = (url, payload, file) => callApiWithFile(url, 'PUT', payload, file);
