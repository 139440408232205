import { action, decorate, observable } from 'mobx';
import { pub } from '../api/api';
import { ORIGIN } from '../constants/config';

class ClientStore {
	api;
	clientList = [];
	appointmentFor = 'me';

	newClient = {
		first_name: '',
		second_name: '',
		last_name: '',
		age: '',
		mobile: '',
		password: '',
		confirm_password: '',
		email: '',
		gender: 0,
		address: '',
		avatar: null,
	};

	newClientError = {
		first_name: null,
		second_name: null,
		last_name: null,
		age: null,
		mobile: null,
		password: null,
		confirm_password: null,
		email: null,
		gender: null,
		address: null,
	};

	linkedClients = [];

	constructor(api) {
		this.api = api;
	}

	changeSelectedForLinkedClientsList = (id, value) => {
		this.linkedClients = this.linkedClients.map((client) => {
			const { selected, ...others } = client;

			return {
				...others,
				selected: +client.id === +id ? value : false,
			};
		});
	};

	fetchClientList = () => {
		return this.api.fetch().then((res) => {
			this.clientList = res.clients;

			return Promise.resolve(res);
		});
	};

	fetchLinkedClientsList = () => {
		return pub.fetchMyLinkedClients().then((res) => {
			this.linkedClients = res.clients.map((item) => ({ ...item, selected: false }));

			return Promise.resolve(res);
		});
	};

	selectParticular;

	resetNewClient = () => {
		this.newClient = {
			first_name: '',
			second_name: '',
			last_name: '',
			age: '',
			mobile: '',
			password: '',
			confirm_password: '',
			email: '',
			gender: 0,
			address: '',
			avatar: null,
		};
	};

	resetNewClientError = () => {
		this.newClientError = {
			first_name: null,
			second_name: null,
			last_name: null,
			age: null,
			mobile: null,
			password: null,
			confirm_password: null,
			email: null,
			gender: null,
			address: null,
		};
	};

	setNewClient = (client) => {
		if (client.filename && client.filename !== 'null') {
			client.avatar = `${ORIGIN}/uploads/client/${client.filename}`;
			client.linkView = true;
		}

		if (client.address === null) {
			client.address = '';
		}

		if (client.second_name === null) {
			client.second_name = '';
		}

		if (client.gender === null) {
			client.gender = 2;
		}

		this.newClient = client;
	};

	setNewClientProperty = (property, value) => {
		this.newClient[property] = value;

		if (property === 'avatar') {
			this.newClient['linkView'] = false;
		}

		if (property === 'first_name' || property === 'last_name')
			this.validateNewClientProperty(property);
	};

	setAppointmentFor = (appointmentFor) => {
		this.appointmentFor = appointmentFor;
	};

	create = (payload) => {
		return this.api.post(payload).then((res) => {
			return Promise.resolve(res);
		});
	};

	search = (query) => {
		return this.api.search(query).then((res) => {
			this.clientList = res.clients;

			return Promise.resolve(res);
		});
	};

	getClientById = (id) => {
		return this.clientList.filter((item) => +item.id === +id)[0];
	};

	masterReset = () => {
		this.clientList = [];
	};

	validateNewClient = () => {
		let valid = true;

		const { first_name, second_name, last_name, email, mobile, address, age } = this.newClient;

		if (first_name === '') {
			valid = false;

			this.newClientError.first_name = 'This field is required';
		}

		if (last_name === '') {
			valid = false;

			this.newClientError.last_name = 'This field is required';
		}

		return valid;
	};

	validateNewClientProperty = (property) => {
		let valid = true;

		if (this.newClient[property] === '') {
			valid = false;

			this.newClientError[property] = 'This field is required';
		} else {
			this.newClientError[property] = null;
		}

		return valid;
	};
}

export default decorate(ClientStore, {
	clientList: observable,
	linkedClients: observable,
	appointmentFor: observable,
	newClient: observable,
	fetchClientList: action,
	fetchLinkedClientsList: action,
	changeSelectedForLinkedClientsList: action,
	setAppointmentFor: action,
	resetNewClient: action,
	setNewClient: action,
	setNewClientProperty: action,
	masterReset: action,
});
