import React, { Component } from 'react';
import { FormControl, Input, InputLabel, Select, Typography, withStyles } from '@material-ui/core';

const styles = {
	fullWidthControl: {
		width: '100%',
	},
	input: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		color: 'black',
	},
};

class OutlinedSelect extends Component {
	render() {
		const {
			disabled,
			labelText,
			classes,
			value,
			returnOptions,
			handleChange,
			name,
			notNative,
			inputProps,
		} = this.props;

		if (returnOptions().length === 0)
			return <Typography variant={'h4'}>No {labelText} Available</Typography>;
		else
			return (
				<FormControl variant="outlined" className={classes.fullWidthControl}>
					<InputLabel>{labelText}</InputLabel>
					<Select
						classes={{
							selectMenu: classes.input,
						}}
						disabled={disabled}
						inputProps={inputProps}
						onChange={handleChange}
						value={value}
						className={classes.input}
						native={!notNative}
						input={<Input name={name} />}>
						{returnOptions()}
					</Select>
				</FormControl>
			);
	}
}

export default withStyles(styles)(OutlinedSelect);
