import jwtDecode from 'jwt-decode';

export const checkTokenValid = () => {
	try {
		let decoded = jwtDecode(localStorage.token);

		if (decoded.exp < Date.now() / 1000) {
			localStorage.removeItem('token');
			return false;
		} else return true;
	} catch (e) {
		return false;
	}
};

export const getDecodedToken = () => {
	try {
		let decoded = jwtDecode(localStorage.token);

		if (decoded.exp < Date.now() / 1000) {
			localStorage.removeItem('token');
			return false;
		} else return decoded;
	} catch (e) {
		return false;
	}
};
