import React from 'react';
import { observer, inject } from 'mobx-react';
import { TableRow, Checkbox } from '@material-ui/core';

import MaterialUITable from '../../Commons/CustomTable';
import { CustomTableCell } from '../../Commons/CustomTable/CustomTableCell';

export default inject('clients')(
	observer(
		class ClientChildren extends React.Component {
			componentDidMount() {
				this.props.clients.fetchLinkedClientsList();
			}

			returnTableHeaders = () => {
				let arr = [];

				arr = [
					<CustomTableCell key={0}>Name</CustomTableCell>,
					<CustomTableCell key={1}>Email</CustomTableCell>,
					<CustomTableCell key={2}>Mobile</CustomTableCell>,
					<CustomTableCell key={3}>Gender</CustomTableCell>,
					<CustomTableCell key={4}>Age</CustomTableCell>,
					<CustomTableCell key={5}>Address</CustomTableCell>,
				];

				if (this.props.selectable) arr.unshift(<CustomTableCell />);

				return arr;
			};

			returnTableRows = (data) => {
				return data.map((item, index) => {
					const {
						id,
						selected,
						first_name,
						second_name,
						last_name,
						email,
						address,
						gender,
						mobile,
						age,
					} = item;

					return (
						<TableRow key={index}>
							{this.props.selectable && (
								<CustomTableCell>
									<Checkbox
										checked={selected}
										onChange={(e) => {
											this.props.clients.changeSelectedForLinkedClientsList(
												id,
												e.target.checked,
											);
										}}
									/>
								</CustomTableCell>
							)}
							<CustomTableCell>
								{first_name}
								{second_name ? ` ${second_name}` : null} {last_name}
							</CustomTableCell>
							<CustomTableCell>{email || '--'}</CustomTableCell>
							<CustomTableCell>{mobile || '--'}</CustomTableCell>
							<CustomTableCell>
								{gender === 0 ? 'Male' : gender === 1 ? 'Female' : '--'}
							</CustomTableCell>
							<CustomTableCell>{age || '--'}</CustomTableCell>
							<CustomTableCell>{address || '--'}</CustomTableCell>
						</TableRow>
					);
				});
			};

			render() {
				const { clients } = this.props;
				const { linkedClients } = clients;

				return (
					<MaterialUITable
						rows={this.returnTableRows(linkedClients)}
						headers={this.returnTableHeaders()}
					/>
				);
			}
		},
	),
);
