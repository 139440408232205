import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import CommonOrganizationHeader from '../../Commons/OrganizationHeader';

const ClientOrganizationHeader = inject(
	'uistore',
	'appointments',
	'publicstore',
)(
	observer(
		class ClientOrganizationHeader extends Component {
			handleOrganizationChange = (e) => {
				const organization_id = e.target.value;
				const { publicstore, uistore, includeService, appointments } = this.props;
				const { masterReset } = appointments;

				const {
					setSelectedSite,
					setSelectedOrganization,
					resetSite,
					resetService,
					organizations,
					setServices,
					setFirstLoad,
				} = uistore;

				const { fetchServicesBySiteId, fetchSitesByOrganizationId } = publicstore;
				const selectedOrganization = organizations.filter(
					(item) => +item.id === +organization_id,
				)[0];

				masterReset();
				setSelectedOrganization(selectedOrganization);

				fetchSitesByOrganizationId({ organization_id }).then((res) => {
					if (res.sites && res.sites.length > 0) {
						const orgSites = res.sites;

						setSelectedSite(orgSites[0]);

						if (includeService)
							fetchServicesBySiteId({ site_id: orgSites[0].id }).then(() => {
								setFirstLoad(true);
							});
					} else {
						setServices([]);
						resetSite();
						resetService();
					}
				});
			};

			handleSiteChange = (e) => {
				const { publicstore, uistore, includeService } = this.props;
				const { setSelectedSite, sites, setFirstLoad } = uistore;
				const { fetchServicesBySiteId } = publicstore;
				const selectedSite = sites.filter((item) => +item.id === +e.target.value)[0];

				setSelectedSite(selectedSite);

				if (includeService)
					fetchServicesBySiteId({ site_id: selectedSite.id }).then(() => {
						setFirstLoad(true);
					});
			};

			handleServiceChange = (e) => {
				const { uistore } = this.props;
				const { setSelectedService, services, setFirstLoad } = uistore;
				const selectedService = services.filter((item) => +item.id === +e.target.value)[0];

				setSelectedService(selectedService);
				setFirstLoad(true);
			};

			render() {
				const { notEditable, includeService, uistore } = this.props;

				return (
					<CommonOrganizationHeader
						uistore={uistore}
						notEditable={notEditable}
						includeService={includeService}
						handleOrganizationChange={this.handleOrganizationChange}
						handleSiteChange={this.handleSiteChange}
						handleServiceChange={this.handleServiceChange}
					/>
				);
			}
		},
	),
);

export default ClientOrganizationHeader;
