import React, { Component } from 'react';
import { Delete, Done } from '@material-ui/icons';
import {
	Grid,
	IconButton,
	InputAdornment,
	TextField,
	Tooltip,
	withStyles,
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';

import { getDecodedToken } from '../../../libs/token';

import { style } from './style';

const AppointmentLog = inject('appointmentlogs')(
	observer(
		class AppointmentLog extends Component {
			state = {
				message: '',
			};

			async componentDidMount() {
				const { appointmentlogs, selected } = this.props;

				try {
					await appointmentlogs.fetchByAppointmentId(selected.id);
				} catch (e) {
					console.log(e);
				}
			}

			componentWillUnmount() {
				this.props.appointmentlogs.masterReset();
			}

			handleAppointmentLogCreate = async () => {
				const { appointmentlogs, selected, user_id, history } = this.props;
				const { message } = this.state;
				const { id, start_time, end_time, status_id } = selected;

				const token = getDecodedToken();

				if (token) {
					let payload = {
						appointment_id: id,
						user_id,
						log_date: new Date().toISOString(),
						message,
						previous_start_time: start_time,
						previous_end_time: end_time,
						previous_status_id: status_id,
					};

					try {
						const res = await appointmentlogs.create(payload);
						if (res.status === 200) {
							this.setState({
								message: '',
							});
						}
					} catch (e) {
						alert(e);
						console.log(e);
					}
				} else {
					history.replace('/');
				}
			};

			renderAppointmentLogs = (notes) => {
				const { classes, appointmentlogs } = this.props;
				return notes.map((item, index) => {
					return (
						<Grid key={`note-${index}`} item xs={12} className={classes.noteContainer}>
							<div style={{ display: 'flex', flexGrow: 1 }}>
								<span style={{ flexGrow: 1, alignItems: 'flex-start' }}>
									<b>{item.user && item.user.email}:</b>
								</span>
								<span
									style={{
										flexGrow: 1,
										alignItems: 'flex-end',
										textAlign: 'right',
									}}>
									{new Date(item.log_date).toLocaleString()}
								</span>
							</div>
							<div style={{ display: 'flex', flexGrow: 1 }}>{item.message}</div>
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									flexGrow: 1,
								}}>
								<Tooltip title={'Delete'}>
									<IconButton
										onClick={() => {
											if (
												window.confirm(
													'Are you sure you want to delete this message?',
												)
											)
												appointmentlogs.remove(item.id).then(() => {
													alert('Message Deleted Successfully');
												});
										}}>
										<Delete color={'secondary'} />
									</IconButton>
								</Tooltip>
							</div>
						</Grid>
					);
				});
			};

			render() {
				const { classes, appointmentlogs } = this.props;
				const { message } = this.state;

				return (
					<Grid container className={classes.mainContainer}>
						<Grid item xs={12}>
							<TextField
								multiline
								fullWidth
								onChange={(e) =>
									this.setState({
										message: e.target.value,
									})
								}
								rows={4}
								value={message}
								label={'Note'}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<Tooltip title={'Post note'}>
												<IconButton
													onClick={this.handleAppointmentLogCreate}>
													<Done />
												</IconButton>
											</Tooltip>
										</InputAdornment>
									),
								}}
								variant={'outlined'}
							/>
						</Grid>
						<Grid item xs={12}>
							{this.renderAppointmentLogs(appointmentlogs.data)}
						</Grid>
					</Grid>
				);
			}
		},
	),
);

export default withStyles(style)(AppointmentLog);
