import React from 'react';
import PropTypes from 'prop-types';
import {
	HashRouter as NRouter,
	Redirect as NRedirect,
	Route as NRoute,
	Switch as NSwitch,
} from 'react-router-dom';

const returnChildrenOrChild = (path, location, children) => {
	if (typeof children !== 'undefined') {
		if (children.length) {
			return children.map((child) => {
				if (!!child.props.children && !!child.props.children.length) {
					return React.cloneElement(child, {
						key: path + child.props.path,
						children: child.props.children.map((gchild) =>
							React.cloneElement(gchild, {
								path: child.props.path + gchild.props.path,
							}),
						),
					});
				}
				return child;
			});
		} else {
			return children;
		}
	}
};

export const Route = ({ location, path, component, onEnter, indexRedirectTo, children, exact }) => {
	let Component = component;

	let { pathname } = location;

	if (!!onEnter && !!onEnter()) return <NRoute exact={exact} render={() => onEnter()} />;

	if (indexRedirectTo && pathname === path)
		return (
			<NRoute
				exact={exact}
				render={() => (
					<NRedirect
						to={pathname === '/' ? indexRedirectTo : pathname + indexRedirectTo}
					/>
				)}
			/>
		);

	if (!component)
		return (
			<NSwitch>
				{React.cloneElement(children, {
					path: children.props ? path + children.props.path : path,
					location,
				})}
			</NSwitch>
		);

	return (
		<NRoute
			exact={exact}
			path={path}
			render={(props) => (
				<Component {...props}>
					{children && (
						<NSwitch>{returnChildrenOrChild(path, location, children)}</NSwitch>
					)}
				</Component>
			)}
		/>
	);
};

Route.propTypes = {
	component: PropTypes.func,
	onEnter: PropTypes.func,
	indexRedirectTo: PropTypes.string,
};

export const Redirect = NRedirect;
export const Router = ({ children }) => (
	<NRouter>
		<NSwitch>{children}</NSwitch>
	</NRouter>
);
