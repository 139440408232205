export const style = (theme) => ({
	opTime: {
		padding: '20px',
		border: '1px solid grey',
		color: 'white',
	},
	available: {
		background: 'white',
		color: 'black',
		cursor: 'pointer',
		transition: 'all 0.5s linear',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			color: 'white',
		},
	},
	unavailable: {
		background: 'lightgrey',
		border: '1px solid grey',
		cursor: 'not-allowed',
	},
	selected: {
		border: '1px dashed white',
		background: theme.palette.primary.main,
		color: 'white',
		cursor: 'pointer',
	},
	row: {
		padding: '20px',
		textAlign: 'center',
	},
});
