import { action, decorate, observable } from 'mobx';

class AppointmentStore {
	data = [];
	allData = [];
	selected = {};
	api;

	page = 0;
	rowsPerPage = 6;
	count = 0;

	constructor(api) {
		this.api = api;
	}

	setData = (data) => {
		this.data = data;
	};

	setSelected = (obj) => {
		this.selected = obj;
	};

	resetData = () => {
		this.data = [];
		this.allData = [];
		this.selected = {};
	};

	fetchByUserId = (user_id, start_date, end_date) => {
		return this.api.fetchByUserId(user_id, start_date, end_date).then((res) => {
			return Promise.resolve(res);
		});
	};

	fetchUserlessAppointmentsByServiceId = (service_id, start_date, end_date) => {
		return this.api
			.fetchUserlessAppointmentsByServiceId(service_id, start_date, end_date)
			.then((res) => {
				return Promise.resolve(res);
			});
	};

	fetchBySiteId = (site_id) => {
		return this.api.fetchBySiteId(site_id).then((res) => {
			if (res.appointments)
				this.data = res.appointments.sort((a, b) => {
					if (a.start_time < b.start_time) return 1;
					else if (a.start_time > b.start_time) return -1;
					else return 0;
				});

			this.allData = this.data;
			return Promise.resolve(res);
		});
	};

	fetchByServiceId = (service_id) => {
		return this.api.fetchByServiceId(service_id).then((res) => {
			if (res.appointments)
				this.data = res.appointments.sort((a, b) => {
					if (a.start_time < b.start_time) return 1;
					else if (a.start_time > b.start_time) return -1;
					else return 0;
				});

			this.allData = this.data;
			return Promise.resolve(res);
		});
	};

	filterData = (param) => {
		if (param === '') this.data = this.allData;
		else
			this.data = this.allData.filter(
				(item) =>
					item.client.mobile.includes(param) ||
					item.client.first_name.includes(param) ||
					(item.client.second_name === null
						? false
						: item.client.second_name.includes(param)) ||
					item.client.last_name.includes(param) ||
					item.service.service_name.includes(param) ||
					item.user.first_name.includes(param) ||
					(item.user.second_name === null
						? false
						: item.user.second_name.includes(param)) ||
					item.user.last_name.includes(param) ||
					item.appointment_code.toString().includes(param),
			);
	};

	create = (payload, service, service_user, client, token) => {
		return this.api.post(payload).then((res) => {
			const { _id, _email, _firstName, _secondName, _lastName } = token;

			let obj = {
				...res.appointment,
				service,
				client,
				createdby: {
					id: _id,
					email: _email,
					first_name: _firstName,
					second_name: _secondName,
					last_name: _lastName,
				},
			};

			if (service_user.id !== 0) {
				obj.user = service_user;
			}

			if (+res.status === 200) this.data = [...this.data, obj];
			return Promise.resolve(res);
		});
	};

	remove = (id) => {
		return this.api.del(id).then((res) => {
			if (res.status === 200) this.data = this.data.filter((item) => item.id !== id);
			else console.log(res);
		});
	};

	update = (payload, id) => {
		return this.api.put(payload, id).then((res) => {
			if (res.status === 200) {
				let obj = this.data.filter((item) => item.id === id)[0];
				Object.keys(payload).forEach((key) => {
					obj[key] = payload[key];
				});
			}

			return Promise.resolve(res);
		});
	};

	callSearch = ({
		client,
		user,
		service_id,
		site_id,
		query,
		start_date,
		end_date,
		page,
		limit,
	}) => {
		if (+service_id === 0) {
			this.allData = [];
			this.data = [];
			this.count = 0;
			this.page = 0;
		} else {
			this.api
				.search({
					query,
					client,
					user,
					site_id,
					service_id,
					start_date,
					end_date,
					page,
					limit,
				})
				.then((res) => {
					if (res && res.appointments && !res.error) {
						this.allData = res.appointments;
						this.data = res.appointments;
						this.count = res.count;
					} else console.log(res);
				});
		}
	};

	handlePageChange = (event, page) => {
		this.page = page;
	};

	handleRowsPerPageChange = (event) => {
		this.rowsPerPage = event.target.value;
	};

	masterReset = () => {
		this.data = [];
		this.allData = [];
		this.selected = {};
	};
}

export default decorate(AppointmentStore, {
	data: observable,
	selected: observable,
	page: observable,
	rowsPerPage: observable,
	count: observable,
	fetch: action,
	create: action,
	remove: action,
	update: action,
	setSelected: action,
	setData: action,
	filterData: action,
	fetchBySiteId: action,
	handleRowsPerPageChange: action,
	handlePageChange: action,
	masterReset: action,
});
