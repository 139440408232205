import React, { Component } from 'react';
import Select from 'react-select';
import { inject, observer } from 'mobx-react';
import {
	Button,
	CircularProgress,
	Grid,
	MenuItem,
	Paper,
	TextField,
	Typography,
	withStyles,
} from '@material-ui/core';
import classNames from 'classnames';

import { getDecodedToken } from '../../../libs/token';

import OutlinedSelect from '../../Commons/OutlinedSelect';
import OrganizationHeader from '../UserOrganizationHeader';

import { style } from './style';

function NoOptionsMessage(props) {
	return (
		<Typography
			color="textSecondary"
			className={props.selectProps.classes.noOptionsMessage}
			{...props.innerProps}>
			{props.children}
		</Typography>
	);
}

function inputComponent({ inputRef, ...props }) {
	return <div ref={inputRef} {...props} />;
}

function Control(props) {
	return (
		<TextField
			fullWidth
			InputProps={{
				inputComponent,
				inputProps: {
					className: props.selectProps.classes.input,
					inputRef: props.innerRef,
					children: props.children,
					...props.innerProps,
				},
			}}
			{...props.selectProps.textFieldProps}
		/>
	);
}

function Option(props) {
	return (
		<MenuItem
			buttonRef={props.innerRef}
			selected={props.isFocused}
			component="div"
			style={{
				fontWeight: props.isSelected ? 500 : 400,
			}}
			{...props.innerProps}>
			{props.children}
		</MenuItem>
	);
}

function Placeholder(props) {
	return (
		<Typography
			color="textSecondary"
			className={props.selectProps.classes.placeholder}
			{...props.innerProps}>
			{props.children}
		</Typography>
	);
}

function SingleValue(props) {
	return (
		<Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
			{props.children}
		</Typography>
	);
}

function ValueContainer(props) {
	return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props) {
	return (
		<Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
			{props.children}
		</Paper>
	);
}

const components = {
	Control,
	Menu,
	NoOptionsMessage,
	Option,
	Placeholder,
	SingleValue,
	ValueContainer,
};

const UserClientSelect = inject(
	'clients',
	'appointments',
	'userappointment',
	'uistore',
)(
	observer(
		class Client extends Component {
			timeout = null;

			state = {
				first_name: '',
				second_name: '',
				last_name: '',
				gender: 0,
				client: null,
				clientSearchText: '',
				age: '',
				email: '',
				mobile: '',
				isNew: true,
				address: '',
				loading: false,
				selectOpen: false,
			};

			componentDidMount() {
				const { clients, userappointment, history } = this.props;
				const { selectedInterval, selectedService, selectedServiceUser } = userappointment;
				if (
					Object.keys(selectedInterval).length === 0 ||
					Object.keys(selectedService).length === 0 ||
					Object.keys(selectedServiceUser).length === 0
				)
					history.push('/user/book_appointment');
				else clients.fetchClientList();
			}

			componentWillUnmount() {
				const { clients, appointments, userappointment, uistore } = this.props;

				userappointment.masterReset();
				clients.masterReset();
				appointments.masterReset();
				uistore.masterReset();
			}

			handleAutocompleteChange = (name) => (value) => {
				this.setState({
					[name]: value,
					mobile: value == null ? '' : this.state.mobile,
					isNew: value == null,
				});
			};

			handleChange = (e) => {
				if (e.target.name === 'client') {
					if (e.target.value == null) {
						this.setState({
							[e.target.name]: e.target.value,
							clientSearchText: '',
							isNew: true,
						});
					} else {
						this.setState({
							[e.target.name]: e.target.value,
							isNew: false,
						});
					}
				} else {
					if (e.target.name === 'mobile') {
						this.setState({
							clientSearchText: e.target.value,
							selectOpen: true,
						});
					}

					this.setState({
						[e.target.name]: e.target.value,
					});
				}
			};

			renderGender = () => {
				return [
					<option key={0} value={0}>
						Male
					</option>,
					<option key={1} value={1}>
						Female
					</option>,
					<option label={'Other'} key={2} value={2} />,
				];
			};

			renderClientOptions = (list) => {
				let clientOptions = list.map((item, index) => (
					<option
						label={`${item.first_name} ${item.second_name ? item.second_name : ''} ${
							item.last_name
						}`}
						key={`client-${index}`}
						value={item.id}
					/>
				));

				return [
					<option label={'New UserClientSelect'} key={-1} value={null} />,
					...clientOptions,
				];
			};

			getRandomIntInclusive = (min, max) => {
				min = Math.ceil(min);
				max = Math.floor(max);
				return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive
			};

			formSubmit = async () => {
				const { clients, appointments, userappointment, history, uistore } = this.props;
				const {
					selectedInterval,
					selectedService,
					selectedServiceUser,
					selectedStatus,
					selectedAppointmentSource,
				} = userappointment;
				const { start_time, end_time } = selectedInterval;
				const {
					first_name,
					second_name,
					last_name,
					gender,
					email,
					mobile,
					isNew,
					client,
					age,
					address,
				} = this.state;

				const token = getDecodedToken();

				if (token) {
					const { _id } = token;

					if (selectedStatus === 0) alert('Status is required!');
					else if (selectedAppointmentSource === 0)
						alert('Appointment Source is required!');
					else {
						const appointment_payload = {
							date_created: new Date().toISOString(),
							created_by: _id,
							start_time: start_time.toISOString(),
							end_time: end_time.toISOString(),
							site_id: uistore.selectedSite.id,
							status_id: selectedStatus,
							appointment_source_id: selectedAppointmentSource,
							appointment_code: this.getRandomIntInclusive(0, 200000),
							service_id: selectedService.id,
						};

						if (selectedServiceUser.id !== 0) {
							appointment_payload.user_id = selectedServiceUser.user_id;
						}

						if (isNew) {
							let client_payload = {
								first_name,
								last_name,
								gender,
								mobile,
								age,
								address,
							};

							if (second_name !== '') client_payload.second_name = second_name;

							if (email === '') client_payload.email = null;
							else client_payload.email = email;

							this.setState({
								loading: true,
							});

							try {
								const client_res = await clients.create(client_payload);

								if (client_res && client_res.client) {
									appointment_payload.client_id = client_res.client.id;

									try {
										const appointment_res = await appointments.create(
											appointment_payload,
											selectedService,
											selectedServiceUser,
											client_res.client,
											token,
										);

										this.setState({
											loading: false,
										});

										if (appointment_res && !appointment_res.error) {
											history.push('/user/book_appointment');
										} else {
											console.log(appointment_res);
										}
									} catch (e) {
										this.setState({
											loading: false,
										});

										alert(e);
										console.log(e);
									}
								} else {
									this.setState({
										loading: false,
									});
								}
							} catch (e) {
								if (e && e.message) {
									alert(e.message);
								} else {
									alert('Something went wrong. Please try again!');
								}

								console.log(e);

								this.setState({
									loading: false,
								});
							}
						} else {
							appointment_payload.client_id = client.id;

							this.setState({
								loading: true,
							});

							try {
								const appointment_res = await appointments.create(
									appointment_payload,
									selectedService,
									selectedServiceUser,
									clients.getClientById(client.id),
									token,
								);
								this.setState({
									loading: false,
								});

								if (appointment_res && !appointment_res.error) {
									history.push('/user/book_appointment');
								} else {
									alert('An error occurred! Check console for details');
									console.log(appointment_res);
								}
							} catch (e) {
								this.setState({
									loading: false,
								});

								console.log(e);

								if (e && e.message) {
									alert(e.message);
								} else {
									alert('Something went wrong. Please try again!');
								}
							}
						}
					}
				} else {
					history.replace('/');
				}
			};

			returnGender = (gender) => {
				switch (gender) {
					case 0:
						return 'Male';
					case 1:
						return 'Female';
					default:
						return 'Other';
				}
			};

			renderDetails = (details) => {
				const { classes } = this.props;

				return (
					<Grid container>
						<Grid
							item
							xs={12}
							className={classNames(classes.titleContainer, classes.paddedContainer)}>
							<Typography variant={'h5'}>Client Information</Typography>
						</Grid>

						<Grid item xs={12} md={12} lg={4}>
							<Grid
								container
								justify={'center'}
								className={classNames(
									classes.paddedContainer,
									classes.infoContainer,
								)}>
								<Grid item xs={12} md={12} lg={4}>
									<Typography variant={'body1'}>Email: </Typography>
								</Grid>
								<Grid
									item
									xs={12}
									md={12}
									lg={8}
									className={classes.detailContainer}>
									<Typography variant={'body2'}>{details.email}</Typography>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={12} md={12} lg={4}>
							<Grid
								container
								justify={'center'}
								className={classNames(
									classes.paddedContainer,
									classes.infoContainer,
								)}>
								<Grid
									item
									xs={12}
									md={12}
									lg={6}
									className={classes.detailContainer}>
									<Typography variant={'body1'}>Mobile No. </Typography>
								</Grid>

								<Grid
									item
									xs={12}
									md={12}
									lg={6}
									className={classes.detailContainer}>
									<Typography variant={'body2'}>{details.mobile}</Typography>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={12} md={12} lg={4}>
							<Grid
								container
								justify={'center'}
								className={classNames(
									classes.paddedContainer,
									classes.infoContainer,
								)}>
								<Grid item xs={12} md={12} lg={6}>
									<Typography variant={'body1'}>Gender: </Typography>
								</Grid>

								<Grid item xs={12} md={12} lg={6}>
									<Typography variant={'body2'}>
										{this.returnGender(details.gender)}
									</Typography>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={12} md={12} lg={4}>
							<Grid
								container
								justify={'center'}
								className={classNames(
									classes.paddedContainer,
									classes.infoContainer,
								)}>
								<Grid
									item
									xs={12}
									md={12}
									lg={4}
									className={classes.detailContainer}>
									<Typography variant={'body1'}>First Name: </Typography>
								</Grid>
								<Grid
									item
									xs={12}
									md={12}
									lg={8}
									className={classes.detailContainer}>
									<Typography variant={'body2'}>{details.first_name}</Typography>
								</Grid>
							</Grid>
						</Grid>

						{details.second_name !== null &&
							typeof details.second_name !== 'undefined' && (
								<Grid item xs={12} md={12} lg={4}>
									<Grid
										container
										justify={'center'}
										className={classNames(
											classes.paddedContainer,
											classes.infoContainer,
										)}>
										<Grid
											item
											xs={12}
											md={12}
											lg={6}
											className={classes.detailContainer}>
											<Typography variant={'body1'}>Second Name: </Typography>
										</Grid>
										<Grid
											item
											xs={12}
											md={12}
											lg={6}
											className={classes.detailContainer}>
											<Typography variant={'body2'}>
												{details.second_name}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							)}

						<Grid item xs={12} md={12} lg={4}>
							<Grid
								container
								justify={'center'}
								className={classNames(
									classes.paddedContainer,
									classes.infoContainer,
								)}>
								<Grid
									item
									xs={12}
									md={12}
									lg={6}
									className={classes.detailContainer}>
									<Typography variant={'body1'}>Last Name: </Typography>
								</Grid>
								<Grid
									item
									xs={12}
									md={12}
									lg={6}
									className={classes.detailContainer}>
									<Typography variant={'body2'}>{details.last_name}</Typography>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={12} md={12} lg={4}>
							<Grid
								container
								justify={'center'}
								className={classNames(
									classes.paddedContainer,
									classes.infoContainer,
								)}>
								<Grid item xs={12} md={12} lg={4}>
									<Typography variant={'body1'}>Age: </Typography>
								</Grid>
								<Grid item xs={12} md={12} lg={8}>
									<Typography variant={'body2'}>{details.age}</Typography>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={12} md={12} lg={4}>
							<Grid
								container
								justify={'center'}
								className={classNames(
									classes.paddedContainer,
									classes.infoContainer,
								)}>
								<Grid item xs={12} md={12} lg={4}>
									<Typography variant={'body1'}>Address: </Typography>
								</Grid>
								<Grid item xs={12} md={12} lg={8}>
									<Typography variant={'body2'}>{details.address}</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				);
			};

			renderForm = () => {
				const { classes } = this.props;
				const {
					first_name,
					second_name,
					last_name,
					gender,
					email,
					mobile,
					age,
					address,
				} = this.state;

				return (
					<Grid container justify={'center'} alignItems={'center'}>
						<Grid
							item
							xs={12}
							className={classNames(classes.titleContainer, classes.paddedContainer)}>
							<Typography variant={'h5'}>Enter New Client Information</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							md={4}
							className={classNames(classes.inputContainer, classes.paddedContainer)}>
							<TextField
								variant={'outlined'}
								name={'first_name'}
								value={first_name}
								onChange={this.handleChange}
								label={'First Name'}
								fullWidth
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={4}
							className={classNames(classes.inputContainer, classes.paddedContainer)}>
							<TextField
								variant={'outlined'}
								name={'second_name'}
								value={second_name}
								onChange={this.handleChange}
								label={'Second Name'}
								fullWidth
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={4}
							className={classNames(classes.inputContainer, classes.paddedContainer)}>
							<TextField
								variant={'outlined'}
								name={'last_name'}
								value={last_name}
								onChange={this.handleChange}
								label={'Last Name'}
								fullWidth
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={4}
							className={classNames(classes.inputContainer, classes.paddedContainer)}>
							<TextField
								variant={'outlined'}
								name={'email'}
								value={email}
								onChange={this.handleChange}
								label={'Email'}
								fullWidth
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={4}
							className={classNames(classes.inputContainer, classes.paddedContainer)}>
							<TextField
								variant={'outlined'}
								name={'mobile'}
								value={mobile}
								onChange={this.handleChange}
								label={'Mobile No.'}
								fullWidth
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={4}
							className={classNames(classes.inputContainer, classes.paddedContainer)}>
							<OutlinedSelect
								handleChange={this.handleChange}
								name={'gender'}
								value={gender}
								returnOptions={this.renderGender}
								labelText={'Gender'}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={4}
							className={classNames(classes.inputContainer, classes.paddedContainer)}>
							<TextField
								variant={'outlined'}
								name={'age'}
								value={age}
								onChange={this.handleChange}
								label={'Age'}
								fullWidth
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={4}
							className={classNames(classes.inputContainer, classes.paddedContainer)}>
							<TextField
								variant={'outlined'}
								name={'address'}
								value={address}
								onChange={this.handleChange}
								label={'Address'}
								fullWidth
							/>
						</Grid>
					</Grid>
				);
			};

			openSelect = () => {
				this.setState({
					selectOpen: true,
				});
			};

			closeSelect = () => {
				this.setState({
					selectOpen: false,
				});
			};

			render() {
				const { classes, clients, history, theme } = this.props;
				const { client, isNew, loading } = this.state;
				let clientDetails;

				if (client != null)
					clientDetails = clients.clientList.filter((item) => +item.id === +client.id)[0];

				const selectStyles = {
					input: (base) => ({
						...base,
						color: theme.palette.text.primary,
						'& input': {
							font: 'inherit',
						},
					}),
				};

				if (loading)
					return (
						<Grid className={classes.mainContainer} container>
							<Grid item xs={12} className={classes.loadingContainer}>
								<Typography variant={'h4'}>Processing . . .</Typography>
								<CircularProgress />
							</Grid>
						</Grid>
					);
				else
					return (
						<Grid container justify={'center'} alignItems={'center'}>
							<Grid item xs={12}>
								<OrganizationHeader notEditable />

								{isNew ? this.renderForm() : this.renderDetails(clientDetails)}

								<Grid container justify={'center'} alignItems={'center'}>
									<Grid item xs={12} className={classes.titleContainer}>
										<Typography variant={'h6'}>Or</Typography>
									</Grid>
									<Grid item xs={12}>
										<Select
											classes={classes}
											styles={selectStyles}
											options={clients.clientList.map((item) => ({
												...item,
												value: item.id,
												label: `${item.first_name} ${item.last_name}${
													item.email != null && item.email !== ''
														? `( ${item.email} )`
														: ''
												}, ${item.mobile}${
													item.address ? `, ${item.address}` : ''
												}`,
											}))}
											components={components}
											value={this.state.client}
											inputValue={this.state.clientSearchText}
											menuIsOpen={this.state.selectOpen}
											onMenuClose={this.closeSelect}
											onMenuOpen={this.openSelect}
											name={'client'}
											onChange={this.handleAutocompleteChange('client')}
											onInputChange={(str, action) => {
												this.setState({
													clientSearchText: str,
												});
											}}
											placeholder="Search for a Client"
											isClearable
										/>
									</Grid>
								</Grid>

								<Grid
									container
									justify={'center'}
									alignItems={'center'}
									style={{ paddingTop: '40px' }}>
									<Grid item xs={'auto'} className={classes.buttonContainer}>
										<Button
											color={'primary'}
											variant={'contained'}
											onClick={this.formSubmit}>
											Submit
										</Button>
									</Grid>
									<Grid item xs={'auto'} className={classes.buttonContainer}>
										<Button
											color={'secondary'}
											variant={'contained'}
											onClick={() => history.push('/user/book_appointment')}>
											Discard
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					);
			}
		},
	),
);

export default withStyles(style, { withTheme: true })(UserClientSelect);
