import EsewaLogo from '../esewa-logo.png';
import React from 'react';

export default class EsewaPaymentButton extends React.Component {
	render() {
		const { handleClick, item } = this.props;

		return (
			<div
				onClick={() => {
					handleClick(item);
				}}
				style={{ cursor: 'pointer' }}>
				<img src={EsewaLogo} width="80px" />
			</div>
		);
	}
}
