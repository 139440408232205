import { action, decorate, observable } from 'mobx';

class ServiceUserStore {
	data = [];
	api;

	constructor(api) {
		this.api = api;
	}

	fetch = (service_id) => {
		return this.api.fetch(service_id).then((res) => {
			if (res.status === 200 && !res.error) {
				this.setData(res.serviceuser);
				return Promise.resolve(res);
			}
		});
	};

	setData = (data) => {
		this.data = data;
	};

	resetData = () => {
		this.data = [];
	};

	getByID = (id) => {
		return this.data.filter((item) => +item.id === +id)[0];
	};

	getByUserID = (id) => {
		return this.data.filter((item) => +item.user_id === +id)[0];
	};

	masterReset = () => {
		this.data = [];
	};
}

export default decorate(ServiceUserStore, {
	data: observable,
	fetch: action,
	setData: action,
	resetData: action,
	masterReset: action,
});
