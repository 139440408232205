import { green } from '@material-ui/core/colors';

export const style = {
	checkboxRoot: {
		color: green[600],
		'&$checked': {
			color: green[500],
		},
	},
	checked: {},
};
