import React, { Component } from 'react';
import { Paper } from '@material-ui/core';
import { BasePicker, Calendar, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { observer } from 'mobx-react';

const addMonths = function (date, months) {
	const newDate = new Date(date);

	newDate.setMonth(newDate.getMonth() + months);

	return newDate;
};

export default observer(
	class CalendarComponent extends Component {
		render() {
			const { value, handleDateChange, shouldDisableDate, disablePast } = this.props;

			return (
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<BasePicker value={value} onChange={handleDateChange}>
						{({
							date,
							handleAccept,
							handleChange,
							handleClear,
							handleDismiss,
							handleSetTodayDate,
							handleTextFieldChange,
						}) => (
							<div>
								<div className="picker">
									<Paper style={{ overflow: 'hidden', width: '100%' }}>
										<Calendar
											date={date}
											maxDate={addMonths(new Date(), 1)}
											onChange={(date) => {
												handleChange(date);
												handleDateChange(date);
											}}
											disablePast={disablePast}
											shouldDisableDate={shouldDisableDate}
										/>
									</Paper>
								</div>
							</div>
						)}
					</BasePicker>
				</MuiPickersUtilsProvider>
			);
		}
	},
);
