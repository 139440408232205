import React from 'react';
import KhaltiLogo from '../khalti-logo.svg';

export default class KhaltiPaymentButton extends React.Component {
	render() {
		const { handleClick, item } = this.props;

		return (
			<div
				onClick={() => {
					handleClick(item);
				}}
				style={{ cursor: 'pointer' }}>
				<img src={KhaltiLogo} width="80px" />
			</div>
		);
	}
}
