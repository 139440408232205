import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import * as serviceWorker from './serviceWorker';
import Routes from './routes';
import './CssStyles/index.css';
import { appointment, appointmentlog, client, pub, service, serviceuser } from './api/api';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import 'typeface-molengo';

import AppointmentStore from './stores/AppointmentStore';
import AppointmentLogStore from './stores/AppointmentLogStore';
import ClientStore from './stores/ClientStore';
import PublicStore from './stores/PublicStore';
import ServiceStore from './stores/ServiceStore';
import ServiceUserStore from './stores/ServiceUserStore';
import UIStore from './stores/UIStore';
import UserAppointmentStore from './stores/UserAppointmentStore';

const theme = createMuiTheme({
	typography: {
		// Tell Material-UI what's the font-size on the html element is.
		htmlFontSize: 16,
		fontFamily: 'Molengo',
		useNextVariants: true,
	},
	palette: {
		primary: {
			main: '#3c8dbc',
		},
	},
});

const appointments = new AppointmentStore(appointment);
const appointmentlogs = new AppointmentLogStore(appointmentlog);
const clients = new ClientStore(client);
const services = new ServiceStore(service);
const serviceusers = new ServiceUserStore(serviceuser);
const userappointment = new UserAppointmentStore();
const uistore = new UIStore();

const publicstore = new PublicStore({
	api: pub,
	appointment_store: appointments,
	appointment_log_store: appointmentlogs,
	service_store: services,
	service_user_store: serviceusers,
	user_appointment_store: userappointment,
	uistore,
});

ReactDOM.render(
	<Provider
		appointments={appointments}
		appointmentlogs={appointmentlogs}
		clients={clients}
		publicstore={publicstore}
		services={services}
		serviceusers={serviceusers}
		uistore={uistore}
		userappointment={userappointment}>
		<MuiThemeProvider theme={theme}>
			<Routes />
		</MuiThemeProvider>
	</Provider>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
