class PublicStore {
	api;

	appointment_store;
	appointment_log_store;
	service_store;
	service_user_store;
	uistore;
	user_appointment_store;

	constructor({
		api,
		appointment_store,
		appointment_log_store,
		service_store,
		service_user_store,
		uistore,
		user_appointment_store,
	}) {
		this.api = api;
		this.appointment_store = appointment_store;
		this.appointment_log_store = appointment_log_store;
		this.service_store = service_store;
		this.service_user_store = service_user_store;
		this.uistore = uistore;
		this.user_appointment_store = user_appointment_store;
	}

	fetchOrganizations = (defaultOrganizationId) => {
		return this.api.fetchOrganizations().then((res) => {
			if (res && +res.status === 200 && res.organizations) {
				const { setOrganizations, setSelectedOrganization } = this.uistore;

				setOrganizations(res.organizations);

				const defaultOrganization = res.organizations.find(
					(org) => +org.id === +defaultOrganizationId,
				);

				if (defaultOrganization) {
					setSelectedOrganization(defaultOrganization);
					this.uistore.setSelectedOrganization(defaultOrganization);
				} else {
					if (res.organizations.length > 0) setSelectedOrganization(res.organizations[0]);
				}
			}

			return Promise.resolve(res);
		});
	};

	fetchSitesByOrganizationId = ({ organization_id, defaultSiteId }) => {
		return this.api.fetchSitesByOrganizationId(organization_id).then((res) => {
			if (res && +res.status === 200 && res.sites) {
				const { setSites, setSelectedSite } = this.uistore;

				setSites(res.sites);

				const defaultSite = res.sites.find((site) => +site.id === +defaultSiteId);

				if (defaultSite) {
					setSelectedSite(defaultSite);
					this.uistore.setSelectedSite(defaultSite);
				} else {
					if (res.sites.length > 0) setSelectedSite(res.sites[0]);
				}
			}

			return Promise.resolve(res);
		});
	};

	fetchServicesBySiteId = ({ site_id, defaultServiceId }) => {
		return this.api.fetchServicesBySiteId(site_id).then((res) => {
			if (res && +res.status === 200 && res.services) {
				const { setServices, setSelectedService } = this.uistore;

				setServices(res.services);
				this.service_store.setData(res.services);

				const defaultService = res.services.find(
					(service) => +service.id === +defaultServiceId,
				);

				if (defaultService) {
					setSelectedService(defaultService);
					this.uistore.setSelectedService(defaultService);
				} else {
					if (res.services.length > 0) {
						this.user_appointment_store.setParam('selectedService', res.services[0]);
						setSelectedService(res.services[0]);
					} else {
						setSelectedService({ id: -1, service_name: 'All Services' });
					}
				}
			}

			return Promise.resolve(res);
		});
	};

	fetchStatusesBySiteId = (site_id) => {
		return this.api.fetchStatusesBySiteId(site_id).then((res) => {
			if (res && +res.status === 200 && res.statuses) {
				if (res.statuses.length > 0) {
					this.user_appointment_store.setParam('selectedStatus', res.statuses[0].id);
				}
			}

			return Promise.resolve(res);
		});
	};

	fetchServiceUsersByServiceId = (service_id) => {
		return this.api.fetchServiceUsersByServiceId(service_id).then((res) => {
			if (res && +res.status === 200 && res.users) {
				this.service_user_store.setData(res.users);
			}

			return Promise.resolve(res);
		});
	};

	fetchAppointmentSources = () => {
		return this.api.fetchAppointmentSources().then((res) => {
			if (
				res &&
				+res.status === 200 &&
				res.appointment_sources &&
				res.appointment_sources.length > 0
			)
				this.user_appointment_store.setParam(
					'selectedAppointmentSource',
					res.appointment_sources[0].id,
				);

			return Promise.resolve(res);
		});
	};

	fetchAppointmentsByUserId = (user_id, start_date, end_date) => {
		return this.api.fetchAppointmentsByUserId(user_id, start_date, end_date);
	};

	fetchMyAppointmentsByServiceId = (service_id) => {
		return this.api.fetchMyAppointmentsByServiceId(service_id).then((res) => {
			this.appointment_store.setData(res.appointments);

			return Promise.resolve(res);
		});
	};

	fetchUserlessAppointmentsByServiceId = (service_id, start_date, end_date) => {
		return this.api.fetchUserlessAppointmentsByServiceId(service_id, start_date, end_date);
	};

	fetchMyAppointmentsBySiteId = (site_id) => {
		return this.api.fetchMyAppointmentsBySiteId(site_id).then((res) => {
			this.appointment_store.setData(res.appointments);

			return Promise.resolve(res);
		});
	};

	postAppointment = (payload) => {
		return this.api.postAppointment(payload);
	};

	updateAppointment = (id, payload) => {
		return this.api.updateAppointment(id, payload);
	};
}

export default PublicStore;
