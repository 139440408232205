import React from 'react';
import { CircularProgress, Grid, Typography, withStyles } from '@material-ui/core';
import { observer, inject } from 'mobx-react';
import { autorun } from 'mobx';
import moment from 'moment';

import OrganizationHeader from '../ClientOrganizationHeader';
import OutlinedSelect from '../../Commons/OutlinedSelect';
import {
	returnResourcePersonOptions,
	returnServiceOptions,
	returnAppointmentSourceOptions,
	sortByStartTime,
} from '../../../constants/functions';
import CalendarComponent from '../../Commons/Calendar';
import OperationTime from '../../Commons/OperationTime';
import { style } from './style';

import {
	addDays,
	addSeconds,
	filterOperationTimesByWeekDay,
	getStartOfDay,
	getEndOfDay,
} from '../../../utils';
import { pub } from '../../../api/api';
import ClientAppointmentConfirmation from '../ClientAppointmentConfirmation';
import { getDecodedToken } from '../../../libs/token';

class ClientAppointmentBooking extends React.Component {
	dispose;

	state = {
		loading: true,
		appointments: [],
		appointment_sources: [],
		siteHolidays: [],
		userHolidays: [],
		paymentDialogOpen: false,
	};

	setLoading = (loading) => {
		this.setState({
			loading,
		});
	};

	componentDidMount() {
		const { publicstore } = this.props;
		const { fetchOrganizations, fetchSitesByOrganizationId } = publicstore;

		fetchOrganizations().then(() => {
			fetchSitesByOrganizationId({
				organization_id: this.props.uistore.selectedOrganization.id,
			}).then(() => {
				this.dispose = autorun(() => {
					this.makeRequestsWithSiteId(this.props.uistore.selectedSite.id);
				});
			});
		});
	}

	componentWillUnmount() {
		if (this.dispose) this.dispose();
	}

	formSubmit = (callback) => {
		const { publicstore, userappointment, history, uistore, clients } = this.props;
		const { newClient } = clients;
		const {
			selectedInterval,
			selectedService,
			selectedServiceUser,
			selectedAppointmentSource,
		} = userappointment;
		const { start_time, end_time } = selectedInterval;

		const token = getDecodedToken();

		if (token) {
			if (Object.keys(selectedInterval).length === 0) {
				alert('Please select a Time Interval to proceed further!');
			} else if (selectedAppointmentSource === 0) alert('Appointment Source is required!');
			else {
				let appointment_payload;

				switch (clients.appointmentFor) {
					case 'me':
						appointment_payload = {
							date_created: new Date().toISOString(),
							created_by: null,
							start_time: start_time.toISOString(),
							end_time: end_time.toISOString(),
							site_id: uistore.selectedSite.id,
							appointment_source_id: selectedAppointmentSource,
							appointment_code: this.getRandomIntInclusive(0, 200000),
							service_id: selectedService.id,
							user_id:
								selectedServiceUser.id === 0 ? null : selectedServiceUser.user_id,
						};
						break;
					case 'someone_new':
						const {
							email,
							first_name,
							second_name,
							last_name,
							mobile,
							address,
							age,
							gender,
						} = newClient;

						const client = {
							email,
							first_name,
							second_name,
							last_name,
							mobile,
							address,
							age,
							gender,
						};

						appointment_payload = {
							date_created: new Date().toISOString(),
							created_by: null,
							start_time: start_time.toISOString(),
							end_time: end_time.toISOString(),
							site_id: uistore.selectedSite.id,
							appointment_source_id: selectedAppointmentSource,
							appointment_code: this.getRandomIntInclusive(0, 200000),
							service_id: selectedService.id,
							user_id:
								selectedServiceUser.id === 0 ? null : selectedServiceUser.user_id,
							client,
						};

						break;
					case 'someone_linked':
						appointment_payload = {
							date_created: new Date().toISOString(),
							created_by: null,
							start_time: start_time.toISOString(),
							end_time: end_time.toISOString(),
							site_id: uistore.selectedSite.id,
							appointment_source_id: selectedAppointmentSource,
							appointment_code: this.getRandomIntInclusive(0, 200000),
							service_id: selectedService.id,
							user_id:
								selectedServiceUser.id === 0 ? null : selectedServiceUser.user_id,
							client_id: clients.linkedClients.find((client) => client.selected).id,
						};
						break;
				}

				publicstore
					.postAppointment(appointment_payload)
					.then((res) => {
						console.log(res);
						if (res && +res.status === 200 && res.appointment && res.appointment.id) {
							if (callback) {
								callback(res.appointment.id);
							} else {
								alert('Appointment Created!');

								this.props.history.push(
									`/client/appointments?organization_id=${this.props.uistore.selectedOrganization.id}&site_id=${appointment_payload.site_id}&service_id=${appointment_payload.service_id}`,
								);
							}
						}
					})
					.catch((e) => {
						console.log(e);
						if (e && e.message) {
							alert(e.message);
						} else {
							alert('Something went wrong! Please try again!');
						}
					});
			}
		} else {
			history.replace('/login');
		}
	};

	makeRequestsWithSiteId = (site_id) => {
		this.setLoading(true);

		const { serviceusers, userappointment, publicstore } = this.props;

		userappointment.masterReset();
		serviceusers.masterReset();

		const { fetchServicesBySiteId, fetchAppointmentSources } = publicstore;

		pub.fetchCalendarBySiteId(site_id).then((res) => {
			if (res && res.status === 200 && res.calendar) {
				this.setState({
					siteHolidays: res.calendar,
				});
			}
		});

		fetchAppointmentSources().then((res) => {
			if (res && res.status === 200 && res.appointment_sources) {
				this.setState({
					appointment_sources: res.appointment_sources,
				});
			}
		});

		fetchServicesBySiteId({ site_id }).then((service_res) => {
			if (
				service_res &&
				+service_res.status === 200 &&
				service_res.services &&
				service_res.services.length > 0
			) {
				this.makeRequestsWithServiceId(service_res.services[0].id);
			} else {
				this.setLoading(false);
			}
		});
	};

	makeRequestsWithServiceId = (service_id) => {
		const { fetchServiceUsersByServiceId } = this.props.publicstore;

		fetchServiceUsersByServiceId(service_id).then((res) => {
			if (res && +res.status === 200 && res.users && res.users.length > 0) {
				this.makeRequestsWithServiceUser(
					this.props.userappointment.selectedServiceUser,
					new Date(),
				);
			} else {
				this.setLoading(false);
			}
		});
	};

	makeRequestsWithServiceUser = async (service_user, date) => {
		const service = this.props.userappointment.selectedService;

		const { userappointment, publicstore } = this.props;
		const { setParam } = userappointment;

		const { Operationtime } = service_user.id === 0 ? service : service_user;

		setParam('intervals', []);

		if (Operationtime.length === 0) {
			setParam('intervals', []);
			this.setLoading(false);
		} else {
			let operationTimeDate = Operationtime.filter((item) => item.week_day === date.getDay());
			operationTimeDate.sort(sortByStartTime);

			if (operationTimeDate.length === 0) {
				let iterations = 0;
				while (operationTimeDate.length === 0) {
					if (iterations >= 7) break;

					date = addDays(date, 1);

					operationTimeDate = filterOperationTimesByWeekDay(Operationtime, date);
					operationTimeDate.sort(sortByStartTime);

					iterations++;
				}
			}

			try {
				const appointment_res =
					service_user.id === 0
						? await publicstore.fetchUserlessAppointmentsByServiceId(
								service.id,
								getStartOfDay(date).toISOString(),
								getEndOfDay(date).toISOString(),
						  )
						: await publicstore.fetchAppointmentsByUserId(
								service_user.user_id,
								getStartOfDay(date).toISOString(),
								getEndOfDay(date).toISOString(),
						  );

				const { appointments } = appointment_res;

				this.setState(
					{
						appointments,
					},
					() => {
						if (operationTimeDate.length !== 0) {
							setParam('selectedDate', date);
							this.parseIntervalsAndPush(
								operationTimeDate,
								service.service_period,
								appointments,
							);
						} else this.setLoading(false);
					},
				);
			} catch (e) {
				console.log(e);

				this.setLoading(false);
			}
		}
	};

	returnTimeInMinutes = (date) => {
		return date.getHours() * 60 + date.getMinutes();
	};

	returnExistingAppointment = (appointments, start, end) => {
		let appointment;

		const startInMins = this.returnTimeInMinutes(start);
		const endInMins = this.returnTimeInMinutes(end);

		appointments.forEach((item) => {
			const appointmentStartInMins = this.returnTimeInMinutes(new Date(item.start_time));
			const appointmentEndInMins = this.returnTimeInMinutes(new Date(item.end_time));

			if (appointmentStartInMins <= startInMins && appointmentEndInMins >= endInMins) {
				appointment = item;
			}
		});

		return appointment;
	};

	parseIntervalsAndPush = (opTimeArr, duration, appointments = []) => {
		const { userappointment } = this.props;
		const { selectedDate, pushToIntervals, resetIntervals } = userappointment;

		resetIntervals();

		if (typeof opTimeArr !== 'undefined') {
			for (const { start_time, end_time } of opTimeArr) {
				let start = new Date(start_time);
				let end = new Date(end_time);
				let actualEnd;

				start.setFullYear(selectedDate.getFullYear());
				start.setMonth(selectedDate.getMonth());
				start.setDate(selectedDate.getDate());

				end.setFullYear(selectedDate.getFullYear());
				end.setMonth(selectedDate.getMonth());
				end.setDate(selectedDate.getDate());

				let selectedDateAppointments = appointments.filter(
					(item) =>
						new Date(item.start_time).toLocaleDateString() ===
						selectedDate.toLocaleDateString(),
				);

				while (start < end) {
					if (addSeconds(start, duration) < end) {
						actualEnd = addSeconds(start, duration);
					} else actualEnd = end;

					const isSiteHoliday = this.state.siteHolidays.find(
						(holiday) =>
							moment(start).isBetween(holiday.start_time, holiday.end_time) ||
							moment(actualEnd).isBetween(holiday.start_time, holiday.end_time),
					);
					const isUserHoliday = this.state.userHolidays.find(
						(holiday) =>
							moment(start).isBetween(holiday.start_time, holiday.end_time) ||
							moment(actualEnd).isBetween(holiday.start_time, holiday.end_time),
					);

					if (
						selectedDateAppointments.length > 0 &&
						typeof selectedDateAppointments !== 'undefined'
					) {
						let existingAppointment = this.returnExistingAppointment(
							selectedDateAppointments,
							start,
							actualEnd,
						);

						if (typeof existingAppointment === 'undefined') {
							pushToIntervals({
								start_time: start,
								end_time: actualEnd,
								available: !isSiteHoliday && !isUserHoliday,
							});
						} else {
							const differenceInSeconds =
								new Date(existingAppointment.end_time).getTime() -
								new Date(existingAppointment.start_time).getTime();
							if (+differenceInSeconds !== +duration) {
								actualEnd = new Date(existingAppointment.end_time);
							}

							pushToIntervals({
								start_time: start,
								end_time: actualEnd,
								available: false,
							});
						}
					} else {
						pushToIntervals({
							start_time: start,
							end_time: actualEnd,
							available: !isSiteHoliday && !isUserHoliday,
						});
					}
					start = actualEnd;
				}
			}

			this.setLoading(false);
		} else {
			this.setLoading(false);
		}
	};

	checkAppointmentsAndPushIntervals = async () => {
		this.setState({
			loading: true,
		});

		const { appointments, userappointment, publicstore } = this.props;
		const { setParam, selectedService, selectedServiceUser } = userappointment;
		const { Operationtime } =
			selectedServiceUser.id === 0 ? selectedService : selectedServiceUser;
		const { selectedDate } = userappointment;

		try {
			await publicstore.fetchServiceUsersByServiceId(selectedService.id);
		} catch (e) {
			console.log(e);
		}

		let optime;

		if (
			appointments.selected &&
			Object.keys(appointments.selected).length > 0 &&
			selectedDate.toLocaleDateString() ===
				new Date(appointments.selected.start_time).toLocaleDateString()
		) {
			optime = Operationtime.filter(
				(item) => item.week_day === new Date(appointments.selected.start_time).getDay(),
			);
		} else {
			optime = Operationtime.filter(
				(item) => item.week_day === new Date(selectedDate).getDay(),
			);
		}

		setParam('selectedOperationTimeObj', optime);

		try {
			const appointment_res =
				selectedServiceUser.id === 0
					? await publicstore.fetchUserlessAppointmentsByServiceId(
							selectedService.id,
							getStartOfDay(new Date(selectedDate)).toISOString(),
							getEndOfDay(new Date(selectedDate)).toISOString(),
					  )
					: await publicstore.fetchAppointmentsByUserId(
							selectedServiceUser.user_id,
							getStartOfDay(new Date(selectedDate)).toISOString(),
							getEndOfDay(new Date(selectedDate)).toISOString(),
					  );

			if (appointment_res && appointment_res.appointments) {
				this.setState({
					appointments: appointment_res.appointments,
				});

				let date = new Date();

				if (optime.length === 0) {
					let iterations = 0;
					while (optime.length === 0) {
						if (iterations >= 7) break;

						date = addDays(date, 1);

						optime = filterOperationTimesByWeekDay(Operationtime, date);
						optime.sort((a, b) => {
							if (a.start_time < b.start_time) return -1;
							else if (a.start_time > b.start_time) return 1;
							else return 0;
						});

						iterations++;
					}
				}

				if (optime.length !== 0) {
					setParam('selectedDate', date);
					this.parseIntervalsAndPush(
						optime,
						selectedService.service_period,
						appointment_res.appointments,
					);
				} else
					this.setState({
						loading: false,
					});
			}
		} catch (e) {
			console.log(e);
		}
	};

	shouldDisableDate = (date) => {
		const { Operationtime } =
			this.props.userappointment.selectedServiceUser.id === 0
				? this.props.userappointment.selectedService
				: this.props.userappointment.selectedServiceUser;
		const dateClone = new Date(date);
		let operationTimeObj = Operationtime.filter((item) => item.week_day === dateClone.getDay());
		return !operationTimeObj.length > 0;
	};

	handleSelectedDateChange = (date) => {
		this.setLoading(true);

		const dateClone = new Date(date);
		const { userappointment } = this.props;
		const {
			setParam,
			selectedService,
			selectedServiceUser,
			changeIntervalsToSelectedDate,
		} = userappointment;
		const { Operationtime } =
			selectedServiceUser.id === 0 ? selectedService : selectedServiceUser;
		const OperationTimeObj = Operationtime.filter(
			(item) => item.week_day === dateClone.getDay(),
		);

		setParam('intervals', []);
		setParam('selectedDate', dateClone);
		setParam('selectedOperationTimeObj', OperationTimeObj);
		setParam('selectedInterval', {});

		this.makeRequestsWithServiceUser(selectedServiceUser, dateClone);
		changeIntervalsToSelectedDate(this.state.appointments);
	};

	handleServiceChange = (e) => {
		this.setLoading(true);

		const { userappointment, services } = this.props;
		const { setParam } = userappointment;
		const selectedService = services.getByID(e.target.value);

		setParam('selectedService', selectedService);
		this.makeRequestsWithServiceId(e.target.value);
	};

	handleServiceUserChange = (e) => {
		this.setLoading(true);

		const { serviceusers, userappointment } = this.props;
		const { setParam, selectedDate } = userappointment;
		const selectedServiceUser =
			e.target.value == 0 ? { id: 0 } : serviceusers.getByID(e.target.value);

		setParam('selectedServiceUser', selectedServiceUser);

		if (selectedServiceUser && selectedServiceUser.user_id) {
			pub.fetchCalendarByUserId(selectedServiceUser.user_id).then((res) => {
				if (res && res.status === 200 && res.calendar) {
					this.setState(
						{
							userHolidays: res.calendar,
						},
						() => {
							this.checkAppointmentsAndPushIntervals(selectedDate);
						},
					);
				}
			});
		} else {
			this.setState(
				{
					userHolidays: [],
				},
				() => {
					this.checkAppointmentsAndPushIntervals(selectedDate);
				},
			);
		}
	};

	getRandomIntInclusive = (min, max) => {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive
	};

	render() {
		const { loading, paymentDialogOpen } = this.state;
		const { classes, services, serviceusers, userappointment } = this.props;
		const {
			selectedAppointmentSource,
			selectedService,
			selectedServiceUser,
			selectedDate,
			intervals,
			selectedInterval,
			setParam,
		} = userappointment;

		const serviceData = services.data;
		const serviceUserData = serviceusers.data;

		const siteHoliday = this.state.siteHolidays.find((h) =>
			moment(selectedDate).isBetween(h.start_time, h.end_time),
		);
		const userHoliday = this.state.userHolidays.find((h) =>
			moment(selectedDate).isBetween(h.start_time, h.end_time),
		);

		if (loading)
			return (
				<Grid container alignItems={'center'} justify={'center'} style={{ height: '100%' }}>
					<Grid
						item
						xs={12}
						style={{
							height: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}>
						<CircularProgress style={{ marginRight: '20px' }} /> <h2>LOADING . . .</h2>
					</Grid>
				</Grid>
			);
		else
			return (
				<Grid container alignItems={'center'} justify={'center'}>
					<Grid item xs={12}>
						<OrganizationHeader />
						<Grid
							container
							alignItems={'center'}
							justify={'center'}
							className={classes.row}>
							<Grid item xs={12} md={6} className={classes.inputContainer}>
								<OutlinedSelect
									handleChange={this.handleServiceChange}
									name={'service'}
									value={selectedService.id}
									returnOptions={() => returnServiceOptions(serviceData)}
									labelText={'Service'}
								/>
							</Grid>
							<Grid item xs={12} md={6} className={classes.inputContainer}>
								<OutlinedSelect
									notNative
									handleChange={this.handleServiceUserChange}
									name={'resource_person'}
									value={selectedServiceUser.id}
									returnOptions={() =>
										returnResourcePersonOptions(serviceUserData)
									}
									labelText={'Resource Person'}
								/>
							</Grid>
							<Grid item xs={12} md={6} className={classes.inputContainer}>
								<OutlinedSelect
									handleChange={(e) => {
										const { setParam } = this.props.userappointment;
										setParam('selectedAppointmentSource', +e.target.value);
									}}
									name={'appointment_source'}
									value={selectedAppointmentSource}
									returnOptions={() =>
										returnAppointmentSourceOptions(
											this.state.appointment_sources,
										)
									}
									labelText={'Appointment Source'}
								/>
							</Grid>
						</Grid>
						{this.state.siteHolidays.length > 0 ||
						this.state.userHolidays.length > 0 ? (
							<Grid container justify={'center'}>
								{siteHoliday ? (
									<Grid item xs={12} className={classes.row}>
										Site is closed from{' '}
										<b>{new Date(siteHoliday.start_time).toLocaleString()}</b>{' '}
										to{' '}
										<b>{new Date(siteHoliday.start_time).toLocaleString()}</b>
									</Grid>
								) : (
									''
								)}
								{userHoliday ? (
									<Grid item xs={12} className={classes.row}>
										User is on leave from{' '}
										<b>{new Date(userHoliday.start_time).toLocaleString()}</b>{' '}
										to{' '}
										<b>{new Date(userHoliday.start_time).toLocaleString()}</b>
									</Grid>
								) : (
									''
								)}
							</Grid>
						) : (
							''
						)}
						{this.state.loading ? (
							<Grid container justify={'center'}>
								<Grid item xs={12} className={classes.row}>
									<CircularProgress />
									<Typography variant={'body1'}>Loading . . .</Typography>
								</Grid>
							</Grid>
						) : intervals.length === 0 ? (
							<Grid container justify={'center'}>
								<Grid item xs={4} className={classes.row}>
									<Typography>
										{selectedServiceUser &&
										selectedServiceUser.user &&
										selectedServiceUser.user.first_name
											? `${selectedServiceUser.user.first_name}'s working hours have not been configured for ${selectedService.service_name}!`
											: 'No Working Hours!'}
									</Typography>
								</Grid>
							</Grid>
						) : (
							<Grid
								container
								alignItems={'center'}
								justify={'center'}
								className={classes.row}>
								<Grid item sm={'auto'}>
									<CalendarComponent
										value={selectedDate}
										disablePast
										shouldDisableDate={this.shouldDisableDate}
										handleDateChange={this.handleSelectedDateChange}
									/>
								</Grid>
								<Grid item xs={12}>
									<OperationTime
										setParam={setParam}
										intervals={intervals}
										selectedInterval={selectedInterval}
										loading={loading}
										formSubmit={() =>
											this.setState({
												paymentDialogOpen: true,
											})
										}
									/>
								</Grid>
							</Grid>
						)}

						{paymentDialogOpen && (
							<ClientAppointmentConfirmation
								formSubmit={this.formSubmit}
								history={this.props.history}
								selectedServiceUser={selectedServiceUser}
								open={paymentDialogOpen}
								handleClose={() => {
									this.setState(
										{
											paymentDialogOpen: false,
										},
										() => {
											// window.location.reload();
										},
									);
								}}
							/>
						)}
					</Grid>
				</Grid>
			);
	}
}

export default withStyles(style)(
	inject(
		'services',
		'serviceusers',
		'userappointment',
		'appointments',
		'publicstore',
		'uistore',
		'clients',
	)(observer(ClientAppointmentBooking)),
);
