import React, { PureComponent } from 'react';

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';

import { auth } from '../api/api';

import { version } from '../../package.json';

import FacebookIcon from '../SocialMediaIcons/facebook.svg';
import GoogleIcon from '../SocialMediaIcons/google.svg';
// import './Login.css';

import { Button, CircularProgress, Grid, IconButton, Tooltip, withStyles } from '@material-ui/core';

import { styles } from './AdminLoginStyle';
import { PersonAdd } from '@material-ui/icons';
import { FACEBOOK_APP_ID } from '../constants/config';

class AdminLogin extends PureComponent {
	state = {
		loading: false,
	};

	componentDidMount() {
		// const { gapi } = window;
		//
		// if (gapi) {
		// 	gapi.load('auth2', function () {
		// 		// Retrieve the singleton for the GoogleAuth library and set up the client.
		// 		window.auth2 = gapi.auth2.init({
		// 			client_id:
		// 				'290531488924-ud7aop23voo60c6h60hel9uettfcson5.apps.googleusercontent.com',
		// 			cookiepolicy: 'single_host_origin',
		// 			// Request scopes in addition to 'profile' and 'email'
		// 			scope:
		// 				'profile email https://www.googleapis.com/auth/user.addresses.read https://www.googleapis.com/auth/user.phonenumbers.read https://www.googleapis.com/auth/user.birthday.read',
		// 		});
		//
		// 		gapi.client.load('people', 'v1');
		// 	});
		// }
	}

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	submitClick = () => {
		const { email, password } = this.state;
		const { history } = this.props;

		if (email === '' || password === '') {
			alert('Some required fields are empty!');
		} else {
			let payload = { email, password };

			auth.clientLogin(payload)
				.then((res) => {
					if (res.token) {
						localStorage.setItem('token', res.token);
						history.push('/client');
					} else if (res.error) {
						alert(res.error);
					}
				})
				.catch((e) => {
					console.log(e);

					if (e.error) {
						if (e.error.details && e.error.details.length > 0)
							alert(e.error.details[0].message);
						else if (e.error.message) alert(e.error.message);
						else alert('ERROR');
					} else {
						alert('ERROR');
					}
				});
		}
	};

	handleGoogleLogin = () => {
		this.setState({ loading: true });
	};

	handleGoogleLoginSuccess = async (success) => {
		// console.log('success.accessToken');
		// console.log(success.accessToken);

		const { history } = this.props;
		const response = await auth.clientLoginGoogle({
			access_token: success.Zi ? success.Zi.access_token : success.accessToken,
		});

		// console.log(response);

		if (response && response.status && +response.status === 200 && response.token) {
			localStorage.setItem('token', response.token);
			this.setState({ loading: false });
			history.push('/client/book_appointment');
		} else {
			this.setState({ loading: false });
		}
	};

	handleGoogleLoginFailure = (fail) => {
		this.setState({ loading: false });
		console.log(JSON.stringify({ message: 'fail', value: fail }));
	};

	handleFacebookLogin = async (res) => {
		try {
			this.setState({ loading: true });

			const { accessToken } = res;
			const { history } = this.props;
			const response = await auth.facebookAdminLogin({ access_token: accessToken });

			if (response.status && +response.status === 200 && response.token) {
				// localStorage.setItem('token', response.token);
				this.setState({ loading: false });
				// history.push('/client/book_appointment');
			} else {
				this.setState({ loading: false });
			}
		} catch (e) {
			this.setState({ loading: false });
			console.log(e);
		}
	};

	render() {
		const { classes, history } = this.props;
		const { loading, email, password } = this.state;

		if (loading) {
			return (
				<Grid
					container
					alignItems={'center'}
					justify={'center'}
					className={classes.mainContainer}>
					<Grid
						item
						xs={12}
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '100%',
							width: '100%',
						}}>
						<CircularProgress />
					</Grid>
				</Grid>
			);
		} else {
			return (
				<Grid
					container
					alignItems={'center'}
					justify={'center'}
					className={classes.mainContainer}>
					<Grid item xs={12} md={4} className={classes.paperContainer}>
						<Grid container alignItems={'center'} justify={'center'}>
							<Grid item xs={12} className={classes.title}>
								Appointment System Business Admin Login
							</Grid>
						</Grid>
						<Grid
							container
							alignItems={'center'}
							justify={'center'}
							className={classes.secondaryContainer}>
							<Grid item xs={12}>
								<Grid
									container
									alignItems={'center'}
									justify={'center'}
									className={classes.externalLoginContainer}>
									<Grid item xs={12}>
										<div>
											<FacebookLogin
												perm
												appId={FACEBOOK_APP_ID}
												scope="public_profile,email,user_gender,user_birthday,user_location,pages_read_engagement,pages_messaging"
												fields="first_name,middle_name,last_name,email,picture,gender,birthday,location"
												render={(renderProps) => (
													<Tooltip title={'Continue with Facebook'}>
														<IconButton onClick={renderProps.onClick}>
															<img
																src={FacebookIcon}
																alt={'FB-Icon'}
															/>
														</IconButton>
													</Tooltip>
												)}
												callback={this.handleFacebookLogin}
												onFailure={this.handleGoogleLoginFailure}
											/>

											{/*<GoogleLogin*/}
											{/*	clientId={GOOGLE_CLIENT_ID}*/}
											{/*	render={(renderProps) => (*/}
											{/*		<Tooltip title={'Continue with Google'}>*/}
											{/*			<IconButton*/}
											{/*				className={classes.socialButton}*/}
											{/*				onClick={() => {*/}
											{/*					this.handleGoogleLogin();*/}
											{/*					renderProps.onClick();*/}
											{/*				}}>*/}
											{/*				<img*/}
											{/*					src={GoogleIcon}*/}
											{/*					alt={'Google-Icon'}*/}
											{/*				/>*/}
											{/*			</IconButton>*/}
											{/*		</Tooltip>*/}
											{/*	)}*/}
											{/*	onFailure={this.handleGoogleLoginFailure}*/}
											{/*	onSuccess={this.handleGoogleLoginSuccess}*/}
											{/*	scope={*/}
											{/*		'profile email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/user.addresses.read https://www.googleapis.com/auth/user.phonenumbers.read https://www.googleapis.com/auth/user.birthday.read'*/}
											{/*	}*/}
											{/*	cookiePolicy={'single_host_origin'}*/}
											{/*/>*/}
										</div>
									</Grid>
								</Grid>
								<Grid item xs={12} className={classes.versionContainer}>
									<em>v. {version}</em>
								</Grid>
								<Grid item xs={12} className={classes.developerContainer}>
									Developed by{' '}
									<em>
										<b>Real Time Solutions Pvt. Ltd.</b>
									</em>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			);
		}
	}
}

export default withStyles(styles)(AdminLogin);
