export const style = (theme) => ({
	mainContainer: {
		margin: '20px',
		textAlign: 'center',
		width: 'calc(100% - 40px)',
	},
	fullContainer: {
		textAlign: 'center',
		height: '100%',
		overflow: 'auto',
		maxHeight: '100%',
		width: '100%',
	},
	loadingContainer: {
		height: '100%',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
	},
	paperContainer: {
		padding: '30px',
		border: '1px solid lightgrey',
		boxShadow: '5px 8px lightgrey',
		height: 'auto',
		width: 'auto',
	},
	titleContainer: {
		margin: '20px',
	},
	title: {
		textAlign: 'left',
	},
	row: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.background.default,
		},
	},
	rightAlignedContainer: {
		textAlign: 'left',
	},
	margin: {
		margin: theme.spacing.unit,
		marginRight: theme.spacing.unit * 2.5,
	},
	textField: {
		width: '98%',
	},
});
