import React, { Component } from 'react';
import { Paper, Table, TableBody, TableHead, TableRow, withStyles } from '@material-ui/core';
import { style } from './style';

class CustomTable extends Component {
	render() {
		const { classes, rows, headers } = this.props;
		return (
			<Paper className={classes.table}>
				<Table className={classes.table}>
					<TableHead>
						<TableRow>{headers}</TableRow>
					</TableHead>
					<TableBody>{rows}</TableBody>
				</Table>
			</Paper>
		);
	}
}

export default withStyles(style)(CustomTable);
