import React, { Component } from 'react';
import { Grid, Button } from '@material-ui/core';

import { pub } from '../../../api/api';

import ClientDetailForm from '../ClientDetailForm';
import { Edit } from '@material-ui/icons';

class ClientProfile extends Component {
	state = {
		edit: false,
		client: null,
	};

	componentDidMount() {
		pub.fetchMyClientDetails().then((res) => {
			if (res && res.client) {
				this.setState({
					client: res.client,
				});
			}
		});
	}

	formSubmit = (payload, file) => {
		pub.updateMyClientDetails(payload, file).then((res) => {
			if (res.status === 200) {
				this.setState({
					edit: false,
					client: res.client,
				});
			}
		});
	};

	render() {
		const { edit, client } = this.state;

		return (
			<>
				<Grid container justify={'flex-end'}>
					<Grid md={1} style={{ display: 'flex', padding: '20px' }} item>
						{!edit ? (
							<Button
								variant={'outlined'}
								color={'secondary'}
								onClick={() => this.setState({ edit: true })}>
								<Edit style={{ marginRight: '10px' }} />
								Edit
							</Button>
						) : null}
					</Grid>
				</Grid>
				<Grid container justify={'center'}>
					<Grid xs={12} item>
						<ClientDetailForm
							client={client}
							hasActions
							editable={edit}
							formSubmit={this.formSubmit}
							discard={() =>
								this.setState({
									edit: false,
									client,
								})
							}
						/>
					</Grid>
				</Grid>
			</>
		);
	}
}

export default ClientProfile;
