export const CustomTableCellStyle = (theme) => ({
	head: {
		background: theme.palette.grey[200],
		color: theme.palette.common.black,
		fontWeight: 'bold',
		fontSize: '20',
		position: 'sticky',
		top: '64px',
		zIndex: 99999,
		borderBottom: '2px lightgrey solid',
		borderTop: '1px lightgrey solid',
	},
	body: {
		fontSize: 14,
	},
});

export const styles = (theme) => ({
	root: {
		width: `calc(100% - ${2 * theme.spacing.unit})`,
		height: 'auto',
		overflow: 'auto',
	},
	table: {
		width: '100%',
	},
});

export const rowStyle = (theme) => ({
	row: {
		borderBottom: '2px lightgrey solid',
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.background.default,
		},
	},
	clickableHeader: {
		cursor: 'pointer',
	},
});
