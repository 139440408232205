export const style = (theme) => ({
	mainContainer: {
		padding: '20px',
		textAlign: 'center',
		height: 'calc( 100vh - 64px )',
		overflow: 'auto',
		maxHeight: '100%',
		width: 'calc( 100vw - 242px )',
	},
	loadingContainer: {
		height: '100%',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
	},
	row: {
		padding: '20px',
		textAlign: 'center',
	},
	opTime: {
		padding: '20px',
		border: '1px solid lightgrey',
		cursor: 'pointer',
	},
	available: {
		background: 'white',
		color: 'black',
		cursor: 'pointer',
	},
	unavailable: {
		background: 'lightgrey',
		border: '1px solid grey',
		cursor: 'not-allowed',
	},
	selected: {
		border: '1px dashed white',
		background: theme.palette.primary.main,
		color: 'white',
		cursor: 'pointer',
	},
	dataRow: {
		padding: 20,
		border: '0.5px solid lightgrey',
	},
	button: {
		width: '90%',
		margin: theme.spacing.unit,
	},
	creatorContainer: {
		textAlign: 'center',
		padding: '20px',
	},
	inputContainer: {
		padding: '20px',
	},
	detailContainer: {
		wordWrap: 'break-word',
	},
});
