import React, { useState, useEffect } from 'react';
import { Divider, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { observer, inject } from 'mobx-react';

import ClientDetailForm from '../ClientDetailForm';
import ClientChildren from '../ClientChildren';

import { pub } from '../../../api/api';

class AppointmentClientSelection extends React.Component {
	state = {
		client: null,
	};

	componentDidMount() {
		pub.fetchMyClientDetails().then((res) => {
			this.setState({ client: res.client });
		});
	}

	renderAppointmentFor = (appointmentFor) => {
		switch (appointmentFor) {
			case 'someone_linked':
				return <ClientChildren selectable />;
			default:
				return (
					<ClientDetailForm
						editable={appointmentFor === 'someone_new'}
						ignorePassword={true}
						client={appointmentFor === 'me' ? this.state.client : null}
					/>
				);
		}
	};

	render() {
		const { clients } = this.props;
		const { appointmentFor, setAppointmentFor } = clients;

		return (
			<div>
				<RadioGroup
					row
					aria-label="position"
					name="position"
					value={appointmentFor}
					style={{ display: 'flex', justifyContent: 'space-around' }}
					onChange={(e) => {
						setAppointmentFor(e.target.value);
					}}>
					<FormControlLabel
						value="me"
						control={<Radio color="primary" />}
						label="This appointment is for me"
						labelPlacement="top"
					/>
					<FormControlLabel
						value="someone_linked"
						control={<Radio color="primary" />}
						label="This appointment is for someone I've already added"
						labelPlacement="top"
					/>
					<FormControlLabel
						value="someone_new"
						control={<Radio color="primary" />}
						label="This appointment is for someone new"
						labelPlacement="top"
					/>
				</RadioGroup>
				{this.renderAppointmentFor(appointmentFor)}
			</div>
		);
	}
}

export default inject('clients')(observer(AppointmentClientSelection));
